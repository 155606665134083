import { FullAppState } from "./../../state";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getNewsFeedItemType, INewsFeedItem } from "../state/newsfeedState";
import { API } from "aws-amplify";
import { CLEAR_STORE } from "../../login/actions/login";

export const LOAD_NEWS_FEED = "LOAD_NEWS_FEED";
export const ADD_NEWSFEED_MESSAGE = "ADD_NEWS_FEED";
export const SET_NEWSFEED_LOADING = "SET_NEWSFEED_LOADING";

interface INewsFeedAction {
    type: typeof SET_NEWSFEED_LOADING;
}

interface ILoadNewsFeedAction {
    type: typeof LOAD_NEWS_FEED | typeof CLEAR_STORE;
    feed: INewsFeedItem[];
}

interface IAddMessageAction {
    type: typeof ADD_NEWSFEED_MESSAGE;
    data: INewsFeedItem;
}

export interface FeedObject {
    title: string;
    message: string;
    type: "info" | "warning" | "report";
    url?: string;
}

export interface NewsFeedTableItem {
    Uuid: string;
    PolicyNumber: string;
    DateTime: string;
    Item: FeedObject;
}

export type NewsFeedActionTypes = ILoadNewsFeedAction | IAddMessageAction | INewsFeedAction;

export function loadNewsFeed(): ThunkAction<
    Promise<void | ILoadNewsFeedAction>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch, getState) {
        try {
            const state = getState();
            const feeds = state.newsfeed.items;

            if (!state.newsfeed.loaded && !state.newsfeed.loading) {
                dispatch(setNewsFeedLoading());
            }

            const policyNumber = state.policy.policy?.PolicyNumber;
            if (!policyNumber) {
                return;
            }

            let fromDate = null;
            if (feeds.length > 0) {
                fromDate = feeds[0].time;
            }
            if (!getState().login.userObject) {
                console.log("No user object");
                return Promise.resolve();
            }
            const res = (await API.post("AppEndpoint", "/newsfeed/" + policyNumber, {
                body: {
                    type: "get-feed",
                    time: fromDate,
                },
            })) as NewsFeedTableItem[];

            dispatch(
                load(
                    res.map<INewsFeedItem>((item: NewsFeedTableItem) => {
                        const callToAction = (item.Item.url && (item.Item.url.indexOf('fairmile://app') >= 0)) ? item.Item.url.split("fairmile://app").pop() : item.Item.url;
                        return {
                            id: item.Uuid,
                            time: item.DateTime,
                            type: getNewsFeedItemType(item.Item.type),
                            title: item.Item.title,
                            message: item.Item.message,
                            callToAction: callToAction
                        };
                    })
                )
            );
        } catch (e) {
            console.log(e);
            throw e;
        }
    };
}


export function load(items: INewsFeedItem[]): NewsFeedActionTypes {
    return {
        type: LOAD_NEWS_FEED,
        feed: items,
    };
}

export function addMessage(data: INewsFeedItem): NewsFeedActionTypes {
    return {
        type: ADD_NEWSFEED_MESSAGE,
        data: data,
    };
}

export function setNewsFeedLoading(): NewsFeedActionTypes {
    return { type: SET_NEWSFEED_LOADING };
}
