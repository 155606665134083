import {
    getTermsAccepted,
    removeMileageRecord,
    setTermsAccepted,
} from "./../../../system/StorageManager";
import IAction from "../../actions";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
    SignIn,
    SignInStatus,
    ISignInResponse,
    ChangePassword,
} from "../../../aws/auth";
import { FullAppState } from "../../state";
import { setUsername } from "../../../system/StorageManager";
import { getPolicy } from "../../policy/actions/actions";

export const LOGIN_ACTION_NAME = "LOGIN_ACTION";
export const LOGOUT_ACTION_NAME = "LOGOUT_ACTION";
export const REQUIRE_NEW_PASSWORD_ACTION_NAME = "REQUIRE_NEW_PASSWORD_ACTION";
export const NOT_CONFIRMED = "NOT_CONFIRMED";
export const LOGIN_ERROR_ACTION_NAME = "LOGIN_ERROR_ACTION";
export const LOGIN_LOADING = "LOGIN_LOADING_ACTION";
export const REQUIRES_TERMS = "REQUIRES_TERMS";
export const TERMS_ACCEPTED = "TERMS_ACCEPTED";
export const TERMS_DECLINED = "TERMS_DECLINED";
export const ERROR_DISSMISSED = "ERROR_DISSMISSED";

// After logout
export const CLEAR_STORE = "CLEAR_STORE";

export interface ILoginAction extends IAction {
    userName?: string;
    error?: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user?: any;
}

export const performLogin = (
    userName: string,
    password: string
): ThunkAction<
    void,
    FullAppState,
    Record<string, unknown>,
    AnyAction
> => async (
    dispatch: ThunkDispatch<
        Record<string, unknown>,
        Record<string, unknown>,
        AnyAction
    >,
    getState
) => {
        const isLoginLoading = getState().login.loading;
        if (isLoginLoading) {
            return;
        }

        dispatch(loginLoading());
        const result = await SignIn(userName, password);

        await setUsername(userName);

        switch (result.status) {
            case SignInStatus.Success:
                getTermsAccepted(result.userObject.attributes["custom:policyContractNumber"]).then((accepted) => {
                    console.log(accepted);
                    if (accepted) {
                        dispatch(login(result, userName));
                    }
                    else {
                        dispatch(showTerms(result, userName));
                    }

                    dispatch(
                        getPolicy(result.userObject.attributes["custom:policyContractNumber"])
                    );
                });
                return;
            case SignInStatus.NewPasswordRequired:
                dispatch(requireNewPassword(result, userName));
                return;
            case SignInStatus.NotConfirmed:
                dispatch(notConfirmed(userName));
                return;
            case SignInStatus.Failed:
                dispatch(loginError(result));
                // dispatch(
                //     addNotification({
                //         type: "danger",
                //         message: result.error!,
                //         duration: 0,
                //     })
                // );
                return;
        }
    };

export const changePassword = (
    password: string
): ThunkAction<
    void,
    Record<string, unknown>,
    Record<string, unknown>,
    AnyAction
> => async (
    dispatch: ThunkDispatch<
        Record<string, unknown>,
        Record<string, unknown>,
        AnyAction
    >,
    getState
) => {
        dispatch(loginLoading());
        const state = getState() as FullAppState;
        const result = await ChangePassword(state.login.userObject, password);

        switch (result.status) {
            case SignInStatus.Success:
                getTermsAccepted(state.login.userObject.attributes["custom:policyContractNumber"]).then((accepted) => {
                    if (accepted) dispatch(login(result, state.login.userName!));
                    else dispatch(showTerms(result, state.login.userName!));
                    dispatch(
                        getPolicy(
                            state.login.userObject.attributes["custom:policyContractNumber"]
                        )
                    );
                });
                return;
            case SignInStatus.Failed:
                dispatch(loginError(result));
                return;
        }
    };

export function requireNewPassword(
    res: ISignInResponse,
    email: string
): ILoginAction {
    // LoginEvent("newPassword");
    return {
        type: REQUIRE_NEW_PASSWORD_ACTION_NAME,
        user: res.userObject,
        userName: email,
    };
}

export function notConfirmed(email: string): ILoginAction {
    // LoginEvent("newPassword");
    return {
        type: NOT_CONFIRMED,
        user: null,
        userName: email,
    };
}

export function clearError(): ILoginAction {
    return {
        type: LOGIN_ERROR_ACTION_NAME,
        error: null
    };
}

export function loginError(res: ISignInResponse): ILoginAction {
    return {
        type: LOGIN_ERROR_ACTION_NAME,
        error: res.error
    };
}

export function login(
    loginResult: ISignInResponse,
    email: string
): ILoginAction {
    // LoginEvent("Logged");
    return {
        type: LOGIN_ACTION_NAME,
        user: loginResult.userObject,
        userName: email,
    };
}

export function logout(): IAction {
    return {
        type: LOGOUT_ACTION_NAME,
    };
}

//After logout

export function clearRedux(): IAction {
    removeMileageRecord();
    return {
        type: CLEAR_STORE,
    };
}

export function loginLoading(): IAction {
    return {
        type: LOGIN_LOADING,
    };
}

export function showTerms(
    loginResult: ISignInResponse,
    email: string
): ILoginAction {
    // PageView("TermsAndCondition");
    return {
        type: REQUIRES_TERMS,
        user: loginResult.userObject,
        userName: email,
    };
}

export function termsAccept(policyNumber: string): IAction {
    setTermsAccepted(policyNumber);
    return {
        type: TERMS_ACCEPTED,
    };
}

export function termsDeclined(): IAction {
    // UserAction("termsDeclined");
    return {
        type: TERMS_DECLINED,
    };
}

export function dismissError(): IAction {
    return {
        type: ERROR_DISSMISSED,
    };
}
