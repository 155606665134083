import geotabHelper, {
    params,
    multiParams,
    anyObject,
    multiParamSingle,
} from "../";
import {
    Device as MapDevice,
    Exception as MapException,
    Point,
    Trip as MapTrip,
} from "@seven-fields-software-ltd/geotab-map-js";
import { MileageRecord } from "../../api/types/mileage";
import { toMiles } from "../../util/mileage";
import { API } from "aws-amplify";
import { DateUtil } from "../../util/date";

import dayjs from "dayjs";

export interface LogRecordObject {
    device: string;
    startTime: string;
    endTime: string;
}

export interface Trip extends MapTrip, LogRecordObject {
    id: string;
    stopPoint: Point;
    distance: number;
    driverName: string;
    drivingDuration: plugin.Duration;
    stopDuration: plugin.Duration;
    loadedExceptions?: boolean
}

export interface Device extends MapDevice {
    id: string;
    activeFrom: string;
    activeTo: string;
    vehicleIdentificationNumber: string;
    serialNumber: string
    positionUpdate?: dayjs.Dayjs
}

export interface Entity {
    id: string
}

export interface Exception extends MapException, LogRecordObject {
    id: string;
    ruleId: string;
    loadedTrips?: boolean
}

export interface Diagnostic extends Entity {
    code?: number;
    controller?: Entity;
    diagnosticType?: string;
    engineType?: string;
    faultResetMode?: string;
    title?: string
    name?: string;
    links?: Record<string, { text: string, path: string }>
    source?: string;
    unitOfMeasure?: string;
    validLoggingPeriod?: string;
    isLogGuaranteedOnEstimateError?: boolean;
    version?: string;
}

export interface FaultData {
    device: Entity
    id: string;
    name: string;
    faultState: string;
    dateTime: string;
    amberWarningLamp?: boolean;
    count?: number;
    diagnostic: Diagnostic;
    failureMode?: string;
    malfunctionLamp?: boolean;
    protectWarningLamp?: boolean;
    redStopLamp?: boolean;
    points: Point[];
    loadedTrips?: boolean
}

export interface Rule {
    id: string,
    name: string
}

export interface LogRecord {
    id: string
    device: { id: string }
    dateTime: string
    latitude: number
    longitude: number
    speed: number
}

export const geotabLogout = async (): Promise<void> => {
    await geotabHelper.logout();
};

export const makeTypedCall = async <T extends Entity>(method: string, params: params): Promise<T[]> => {
    return new Promise((resolve, reject) => {
        geotabHelper.call(
            method,
            params,
            (results) => {
                resolve(results as T[]);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const makeCall = (method: string, params: params): Promise<anyObject[]> => {
    return new Promise((resolve, reject) => {
        geotabHelper.call(
            method,
            params,
            (results) => {
                resolve(results);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const makeMultiCall: (params: multiParams) => Promise<anyObject[]> = (
    params
) => {
    return new Promise((resolve, reject) => {
        geotabHelper.multiCall(
            params,
            (results) => resolve(results),
            (error) => reject(error)
        );
    });
};

export const getUser: (username: string) => Promise<anyObject[]> = (
    username
) => {
    const parameters: params = {
        typeName: "User",
        search: {
            name: username,
        },
    };
    return makeCall("Get", parameters);
};

export const getDevices = async (deviceId?: string): Promise<Device[]> => {
    try {
        const params: params = { typeName: "Device" };
        if (deviceId) {
            params.search = { deviceSearch: { id: deviceId } };
        }
        const devices = await makeCall("Get", params);
        if (devices.length > 0) {
            return devices.map<Device>((device: anyObject) => {
                return {
                    id: device.id,
                    name: device.name,
                    activeFrom: device.activeFrom,
                    activeTo: device.activeTo,
                    position: { x: 0, y: 0 },
                    vehicleIdentificationNumber: device.vehicleIdentificationNumber,
                    serialNumber: device.serialNumber
                };
            });
            // }
        } else {
            return [];
        }
    } catch (e) {
        throw Error(e);
    }
};
export const getDeviceData: (device: Device) => Promise<Point> = async (
    device
) => {
    try {
        const data = await makeCall("Get", {
            typeName: "DeviceStatusInfo",
            search: {
                deviceSearch: {
                    id: device.id,
                },
            },
        });
        if (data.length > 0) {
            return { x: data[0].longitude, y: data[0].latitude };
        } else {
            return { x: 0, y: 0 };
        }
    } catch (e) {
        throw new Error(e);
    }
};
export const getDevicesData: () => Promise<
    { id: string; point: Point }[]
> = async () => {
    try {
        const devices = await makeCall("Get", { typeName: "DeviceStatusInfo" });
        if (devices.length > 0) {
            return devices.map<{ id: string; point: Point }>((device: anyObject) => {
                return {
                    id: device.device.id,
                    point: { y: device.latitude, x: device.longitude },
                };
            });
        } else {
            return [];
        }
    } catch (e) {
        throw Error(e);
    }
};

const convertDurationToDayjsDuration = (duration: string) => {
    const parts = duration.split(":");

    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let milliseconds = 0;

    if (parts[0].indexOf(".") >= 0) {
        const splitFirst = parts[0].split(".");
        days = Number.parseInt(splitFirst[0]);
        hours = Number.parseInt(splitFirst[1]);
    } else {
        hours = Number.parseInt(parts[0]);
    }

    minutes = Number.parseInt(parts[1]);

    if (parts[2].indexOf(".") >= 0) {
        const splitSeconds = parts[2].split(".");
        seconds = Number.parseInt(splitSeconds[0]);
        milliseconds = Number.parseInt(splitSeconds[1]);
    } else {
        seconds = Number.parseInt(parts[2]);
    }

    return DateUtil.duration({ days, hours, minutes, seconds, milliseconds });
};

const generateTrip = (fetchedTrip: anyObject): Trip => {
    return {
        device: fetchedTrip.device.id,
        points: [],
        startPoint: fetchedTrip.startPoint,
        stopPoint: fetchedTrip.stopPoint,
        startTime: fetchedTrip.start,
        endTime: fetchedTrip.stop,
        driverName: fetchedTrip.driver,
        distance: fetchedTrip.distance,
        drivingDuration: convertDurationToDayjsDuration(fetchedTrip.drivingDuration),
        stopDuration: convertDurationToDayjsDuration(fetchedTrip.stopDuration),
        id: fetchedTrip.id,
    };
};

export const getTripsData = async (fromDate: string, toDate: string | undefined | null, deviceId?: string | null | undefined, resultsLimit = 1000, includeOverlap = true): Promise<Trip[]> => {
    try {
        const callParams = {
            typeName: "Trip",
            resultsLimit: resultsLimit,
            search: { fromDate }
        } as params;

        if (toDate) {
            callParams.search = { ...callParams.search, toDate };
        }

        if (callParams.search && includeOverlap) {
            callParams.search.includeOverlappedTrips = includeOverlap;
        }

        if (deviceId && callParams.search) {
            callParams.search.deviceSearch = {
                id: deviceId
            };
        }

        const trips = await makeCall("Get", callParams);
        if (trips.length > 0) {
            return trips.map<Trip>((fetchedTrip: anyObject) => {
                return generateTrip(fetchedTrip);
            });
        } else {
            return [];
        }
    } catch (e) {
        throw new Error(e);
    }
};

export const getCurrentOdometer = async (
    record: MileageRecord
): Promise<MileageRecord> => {
    try {
        const data = await makeCall("Get", {
            typeName: "StatusData",
            resultsLimit: 1,
            search: {
                diagnosticSearch: {
                    id: record.diagnosticId,
                },
                deviceSearch: { id: record.deviceId },
                fromDate: DateUtil.utc().toISOString(),
            },
        });

        return {
            dateTime: data[0].dateTime,
            diagnosticId: data[0].diagnostic.id,
            id: data[0].id,
            value: toMiles(data[0].data),
            deviceId: data[0].device.id,
            keyname: "client",
        } as MileageRecord;
    } catch (e) {
        throw new Error(e);
    }
};

export const getTripByDeviceAndTime = async (deviceId: string, tripStart: string, tripEnd: string): Promise<Trip | null> => {
    try {
        const tripData = await makeCall("Get", {
            typeName: "Trip",
            search: {
                deviceSearch: {
                    id: deviceId,
                },
                fromDate: tripStart,
                toDate: tripEnd
            },
        });

        if (tripData[0]) {
            return generateTrip(tripData[0]);
        }

        return null;
    } catch (e) {
        throw new Error(e);
    }
};

export const getTrip: (
    tripId: string
) => Promise<Trip | null> = async (tripId) => {
    try {
        if (!tripId) return null;
        const tripData = await makeCall("Get", {
            typeName: "Trip",
            search: {
                id: tripId
            },
        });

        if (tripData[0]) {
            return generateTrip(tripData[0]);
        }

        return null;
    } catch (e) {
        throw new Error(e);
    }
};

export const getFullLogRecord = async (deviceId: string, startTime: string, endTime: string): Promise<LogRecord[]> => {
    try {
        if (!deviceId) return [];
        const records = await makeTypedCall<LogRecord>("Get", {
            typeName: "LogRecord",
            search: {
                deviceSearch: { id: deviceId },
                fromDate: startTime,
                toDate: endTime,
            },
        });

        return records;
    } catch (e) {
        throw new Error(e);
    }
};

export const getLogRecord = async (deviceId: string, startTime: string, endTime: string): Promise<Point[]> => {
    try {
        if (!deviceId) return [];
        const tripData = await makeCall("Get", {
            typeName: "LogRecord",
            search: {
                deviceSearch: { id: deviceId },
                fromDate: startTime,
                toDate: endTime,
            },
        });
        return tripData.map<Point>((data: anyObject) => {
            return { x: data.longitude, y: data.latitude };
        });
    } catch (e) {
        throw new Error(e);
    }
};

export const getLogRecordForDevice = async (deviceId: string, startTime: string, endTime: string): Promise<Point[]> => {
    return getLogRecord(deviceId, startTime, endTime);
};

export const getLogRecordForEntity: (
    record: LogRecordObject
) => Promise<Point[]> = async (record) => {
    return getLogRecord(record.device, record.startTime, record.endTime);
};

const generateExceptions = (exceptions: anyObject[]) => {
    return exceptions.map<Exception>((ex: anyObject) => {
        return {
            name: ex.rule?.id || ex.ruleId,
            points: [],
            color: "",
            id: ex.id,
            ruleId: ex.rule?.id || ex.ruleId,
            startTime: ex.activeFrom,
            endTime: ex.activeTo,
            device: ex.device
                ? ex.device.id
                : ex.deviceId,
        } as Exception;
    });
};

export const getExceptions: (
    limit: number,
    fromDate?: string,
    toDate?: string,
    deviceId?: string,
    policyId?: string
) => Promise<Exception[]> = async (
    limit,
    fromDate,
    toDate,
    deviceId,
    policyId
) => {
        try {
            const params: params = {
                typeName: "ExceptionEvent",
                resultsLimit: limit,
                search: {
                    fromDate,
                    toDate,
                },
            };
            if (deviceId) {
                params.search = {
                    fromDate,
                    toDate,
                    deviceSearch: {
                        id: deviceId,
                    },
                };
            }
            const exceptions = await makeCall("Get", params);
            if (deviceId && policyId) {
                const speedExceptions = await API.get(
                    "AppEndpoint",
                    `/exceptions/${policyId}/${deviceId}?activeFrom=${fromDate}&activeTo=${toDate}`,
                    {}
                ) as [];
                if (speedExceptions && speedExceptions.length > 0) {
                    exceptions.push([...speedExceptions]);
                }
            }

            return generateExceptions(exceptions);
        } catch (e) {
            throw new Error(e);
        }
    };

export const getRules = async (): Promise<Rule[]> => {
    const params: params = {
        typeName: "Rule",
    };

    return await makeCall("Get", params) as Rule[];
};

export const getLastCommunication = async (deviceId: string, date?: dayjs.Dayjs): Promise<LogRecord> => {
    const params: params = {
        typeName: "LogRecord",
        resultsLimit: 1,
        search: {
            deviceSearch: {
                id: deviceId
            },
            fromDate: date?.toISOString() || dayjs.utc().toISOString()
        }
    };

    const result = await makeCall("Get", params) as LogRecord[];
    return result[0];
};

export const getExceptionsInfo: (
    exceptions: anyObject[]
) => Promise<Exception[]> = async (exceptions) => {
    try {
        const params: multiParams = exceptions.map<multiParamSingle>(
            (exception) => {
                return [
                    "Get",
                    {
                        typeName: "Rule",
                        search: {
                            id: exception.rule ? exception.rule.id : exception.ruleId,
                        },
                        resultsLimit: 1,
                    },
                ];
            }
        );
        const call = await makeMultiCall(params);
        return call.map<Exception>((results) => {
            const exce = results[0];
            const selectedException = exceptions.find(
                (exc) => (exc.rule ? exc.rule.id : exc.ruleId) === exce.id
            );
            if (selectedException) {
                return {
                    name: exce.name,
                    points: [],
                    color: exce.color,
                    id: selectedException.id,
                    ruleId: exce.id,
                    startTime: selectedException.activeFrom,
                    endTime: selectedException.activeTo,
                    device: selectedException.device
                        ? selectedException.device.id
                        : selectedException.deviceId,
                } as Exception;
            } else {
                console.log("We couldn't find the required exception?", exce);
                return {
                    name: exce.name,
                    points: [],
                    color: exce.color,
                    id: "",
                    ruleId: exce.id,
                    startTime: "",
                    endTime: "",
                    device: "",
                } as Exception;
            }
        });
    } catch (e) {
        throw new Error(e);
    }
};

export const getExceptionPoints = async (exception: Exception): Promise<Point[]> => {
    try {
        const points = await getLogRecordForEntity(exception as LogRecordObject);
        return points;
    } catch (e) {
        throw new Error(e);
    }
};

export const getFaultData = async (
    limit: number,
    fromDate?: string,
    toDate?: string,
    diagnosticId?: string | null
): Promise<FaultData[]> => {
    try {
        const params: params = {
            typeName: "FaultData",
            resultsLimit: limit,
            search: {
                fromDate,
                toDate,
            },
        };

        if (diagnosticId && params?.search) {
            params.search.diagnosticSearch = {
                id: diagnosticId
            };
        }
        const faultData = await makeCall("Get", params) as FaultData[];
        return faultData as FaultData[];
    } catch (e) {
        throw new Error(e);
    }
};

export const getFaultsDataInfo = async (faultData: FaultData[]): Promise<FaultData[]> => {
    try {
        const params: multiParams = faultData.map<multiParamSingle>((data) => {
            console.log(data);
            return [
                "Get",
                {
                    typeName: "Diagnostic",
                    search: { id: data.diagnostic.id },
                    resultsLimit: 1,
                },
            ];
        });
        const call = await makeMultiCall(params);

        for (let i = 0; i < faultData.length; i++) {
            const fault = faultData[i] as FaultData;

            if (call[i]) {
                fault.diagnostic = call[i][0] as Diagnostic;
            }
        }

        return faultData;

    } catch (e) {
        throw new Error(e);
    }
};

export const getFaultDataPoints = async (faultData: FaultData): Promise<Point[]> => {
    try {
        const points = await getLogRecordForDevice(faultData.device.id, faultData.dateTime, faultData.dateTime);
        return points;
    } catch (e) {
        throw new Error(e);
    }
};

export const saveEntity = async (policyNumber: string, deviceId: string, name: string): Promise<boolean> => {
    try {
        await API.get("AppEndpoint", `/rename/${policyNumber}?id=${deviceId}&name=${encodeURIComponent(name)}`, {});
        return true;
    } catch (e) {
        throw new Error(e.message);
    }
};
