import React, { useEffect } from "react";
import "./DatePicker.css";
import {
  IonItem,
  IonLabel,
  IonDatetime,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import i18n from "../../i18n";
import dayjs from "dayjs";

interface PickerProps {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  settingfrom: (from: dayjs.Dayjs) => void;
  settingto: (to: dayjs.Dayjs) => void;
  style?: React.CSSProperties;
}

const format = "MMM DD, YYYY h:mm a";
const DatePicker: React.FC<PickerProps> = (props: PickerProps) => {
  useEffect(() => {
    //Accessibility Adding Title for Buttons manually
    setTimeout(() => {
      const startBtn = document.getElementById(
        "start_date"
      ) as HTMLInputElement;
      if (startBtn?.shadowRoot !== undefined) {
        if (startBtn.shadowRoot?.lastElementChild !== undefined) {
          startBtn.shadowRoot.lastElementChild?.setAttribute(
            "title",
            "Start Date"
          );
        }
      }

      const endBtn = document.getElementById("end_date") as HTMLInputElement;
      if (endBtn?.shadowRoot !== undefined) {
        if (endBtn.shadowRoot?.lastElementChild !== undefined) {
          endBtn.shadowRoot.lastElementChild?.setAttribute("title", "End Date");
        }
      }
    }, 200);
  }, [props]);

  return (
    <IonGrid style={props.style}>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonLabel>{i18n.t("datepick.start")}</IonLabel>
            <IonDatetime
              displayFormat={format}
              value={props.from.toISOString()}
              onIonChange={(e) => props.settingfrom(dayjs(e.detail.value!))}
              aria-label="From Date Filter"
              id="start_date"
            ></IonDatetime>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel>{i18n.t("datepick.end")}</IonLabel>
            <IonDatetime
              displayFormat={format}
              value={props.to.toISOString()}
              onIonChange={(e) => props.settingto(dayjs(e.detail.value!))}
              aria-label="To Date Filter"
              id="end_date"
            ></IonDatetime>
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DatePicker;
