import IVehiclesState from "../state/vehiclesState";
import {
    CHANGE_NAME as CHANGE_DEVICE_NAME,
    LOAD_VEHICLES,
    VehiclesActionTypes,
    VehicleNameChange,
    SET_POSITIONS,
    SetPositionsType,
    LOADING_DEVICES,
    LoadingDevicesType,
    DeviceErrorType,
    DEVICE_ERROR,
} from "../actions/vehicles";
import { CLEAR_STORE } from "../../login/actions/login";
import { DateUtil } from "../../../util/date";

const initialState: IVehiclesState = {
    items: [],
    loaded: false,
    loading: true,
    error: false
};

// const dumdata: IVehicle[] = [{
// 		name: "Ford F150 AZM9590",
// 		id: "1"
// 	},
// 	{
// 		name: "Testing",
// 		id: "2"
// 	},
// 	{
// 		name: "Card",
// 		id: "3"
// 	}
// ]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function (
    state: IVehiclesState = initialState,
    action: VehiclesActionTypes | VehicleNameChange | SetPositionsType | LoadingDevicesType | DeviceErrorType
): IVehiclesState {
    switch (action.type) {
        case DEVICE_ERROR:
            return { ...state, error: action.error, loaded: false };
        case LOADING_DEVICES:
            return { ...state, loading: action.loading, loaded: false };
        case SET_POSITIONS: {
            const devices = state.items;
            for (const d of devices) {
                if (action.positions[d.id]) {
                    d.position = { y: action.positions[d.id].latitude, x: action.positions[d.id].longitude };
                    d.positionUpdate = DateUtil.utc(action.positions[d.id].dateTime);
                }
            }
            return { ...state, items: [...devices] };
        }
        case LOAD_VEHICLES:
            return { ...state, items: action.devices, loading: false, loaded: true };
        case CHANGE_DEVICE_NAME: {
            const devices = state.items.filter(
                (item) => item.id !== action.device.id
            );
            return { ...state, items: [...devices, action.device] };
        }
        case CLEAR_STORE: {
            return initialState;
        }
        default:
            return state;
    }
}
