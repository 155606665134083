import {
  IonCard,
  IonContent,
  IonImg,
  IonItem,
  IonSpinner,
  IonText,
} from "@ionic/react";
import "./SignInLoading.css";
import React from "react";
import { useViewport } from "../../hooks/useViewport";
import { isMobileViewport } from "../../util/platform";
const SignInLoading: React.FC = () => {
  const viewport = useViewport();

  return (
    <IonContent className="loading-bg">
      <div role="alert" aria-label="Logging in securely"></div>
      <div className="centered-flex-loading" aria-hidden="true">
        <IonCard
          className={
            isMobileViewport(viewport)
              ? "signin-loading-card"
              : "signin-loading-card-desktop"
          }
        >
          <IonItem className="loading-img-item">
            <IonImg
              className="loading-image"
              src="assets/farmers-logo.png"
              alt="Farmers Logo"
            />
          </IonItem>
          <IonItem
            lines="none"
            className="loading-img-item"
            style={{ marginTop: "7px" }}
          >
            <div className="centered-flex-loading" style={{ margin: "auto" }}>
              {/*-- Crescent --*/}
              <IonSpinner
                name="crescent"
                color="primary"
                style={{ marginRight: "5px" }}
              />
              <IonText className="loading-text">Logging in securely</IonText>
            </div>
          </IonItem>
        </IonCard>
      </div>
    </IonContent>
  );
};
export default SignInLoading;
