import IAction from "../../actions";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ICustomerRecord } from "../../../entities/customer";
import { CLEAR_STORE } from "../../login/actions/login";

export const LOAD_COMPANY = "LOAD_COMPANY";
export const SET_COMPANY_LOADING = "SET_COMPANY_LOADING";
export const SET_COMPANY_LOADING_DONE = "SET_COMPANY_LOADING_DONE";

export type CompanyActionTypes = ILoadCompanyAction | IAction;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILoadCompanyAction extends IAction {
  type: typeof LOAD_COMPANY | typeof CLEAR_STORE;
  data: ICustomerRecord;
}

export const setCompanyLoading = (): IAction => {
  return {
    type: SET_COMPANY_LOADING,
  };
};

export const setLoadingDone = (): IAction => {
  return {
    type: SET_COMPANY_LOADING_DONE,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCompany = (): ThunkAction<
  void,
  unknown,
  unknown,
  AnyAction
> => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  try {
    console.log("Fetching company");
    dispatch(setCompanyLoading());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const customer: any = await API.graphql(graphqlOperation(customQueries.singleCustomerByEmail, { email: email }));
    // console.log(JSON.stringify(customer.data.singleCustomerByEmail));
    // dispatch(loadCompany(customer.data.singleCustomerByEmail));
    // dispatch(loadNewsFeed(customer.data.singleCustomerByEmail.id));
    dispatch(setLoadingDone());
  } catch (err) {
    console.log(err);
  }
};

export function loadCompany(customer: ICustomerRecord): ILoadCompanyAction {
  return {
    type: LOAD_COMPANY,
    data: customer,
  };
}
