import { DateUtil } from "../util/date";
import { useSelector } from "react-redux";
import { FullAppState } from "../store/state";
import { useDebugManager } from "./useDebugManager";

export const useAfterPolicy = (): boolean => {
    const dm = useDebugManager();
    const policy = useSelector((state: FullAppState) => {
        return state.policy.policy;
    });

    if (!policy) {
        return false;
    }

    if (dm.policyState != null && dm.policyState == 2) {
        return true;
    }

    const endEffectiveDate = DateUtil.local(policy?.PolicyEndEffectiveDate);
    return policy?.RequestType == "D" || endEffectiveDate < DateUtil.local();
};