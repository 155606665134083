import React from "react";
import "./ThreeRowCard.css";

import tw from "twin.macro";

interface Props {
  row1Title: string;
  row1Value: string;
  row1Unit?: string;
  row2Title: string;
  row2Value: string;
  row2Unit?: string;
  row3Title?: string | null;
  row3Value?: string | (() => React.ReactNode);
  row3Unit?: string;
  itemGap?: string;
}

const ThreeRowCard: React.FC<Props> = (props: Props) => {
  const row3Value = !props.row3Value
    ? "--"
    : typeof props.row3Value == "string"
    ? props.row3Value
    : props.row3Value();

  const itemGap = props.itemGap ? tw`mt-8` : tw`mt-4`;

  return (
    <div tw="pl-4 pb-4" role="grid">
      <div role="rowgroup">
        <div role="row" tabIndex={0}>
          <div tw="mt-4" className="desktop-card-subtitle">
            {props.row1Title}
          </div>
          <div
            className="threeRowNum"
            tw="text-farmersblue"
            aria-hidden={props.row1Value == "--" || !props.row1Value}
          >
            <span>{props.row1Value || "--"}</span>
            {props.row1Unit && <span tw="text-lg"> {props.row1Unit}</span>}
          </div>
          <div
            role="cell"
            aria-hidden={props.row1Value != "--" && props.row1Value != null}
            aria-label="No Data"
            className="screen-reader-only"
          >
            No Data
          </div>
        </div>

        <div role="row" tabIndex={0}>
          <div css={[itemGap]} className="desktop-card-subtitle">
            {props.row2Title}
          </div>
          <div
            className="threeRowNum"
            tw="text-headinggray"
            aria-hidden={props.row2Value == "--" || !props.row2Value}
          >
            <span>{props.row2Value || "--"}</span>
            {props.row2Unit && <span tw="text-lg"> {props.row2Unit}</span>}
          </div>
          <div
            role="cell"
            aria-hidden={props.row2Value != "--" && props.row2Value != null}
            aria-label="No Data"
            className="screen-reader-only"
          >
            No Data
          </div>
        </div>

        {props.row3Title && (
          <div role="row" tabIndex={0}>
            <div css={itemGap} className="desktop-card-subtitle">
              {props.row3Title || "--"}
            </div>
            <div
              className="threeRowNum"
              tw="text-headinggray"
              aria-hidden={props.row3Value == "--" || !props.row3Value}
            >
              <span>{row3Value}</span>
              {props.row3Unit && <span tw="text-lg"> {props.row3Unit}</span>}
            </div>

            <div
              role="cell"
              aria-hidden={props.row3Value != "--" && props.row3Value != null}
              aria-label="No Data"
              className="screen-reader-only"
            >
              No Data
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThreeRowCard;
