import React, { useEffect, useState } from "react";
import "./PageCommon.css";
import "./Vehicles.css";
import { PageView } from "../telemetry/TelemetryManager";
import VehicleList from "../components/VehicleList/VehicleList2";
import Notifications from "../components/Notifications/Notifications";
import i18n from "../i18n";
import VehiclesTabs from "../components/VehiclesTabs/VehiclesTabs";
import { location as mapPinIcon } from "ionicons/icons";

import tw from "twin.macro";

import { Device } from "../geotab/helpers";
import MapCard from "../components/MapCard/MapCard";
import { useSelector } from "react-redux";
import { FullAppState } from "../store/state";
import { useDevice } from "../hooks/useDevice";
import Page from "./Page";
import { IonIcon } from "@ionic/react";
import { useHistory } from "react-router";
import { useIsMobile } from "../hooks/useIsMobile";

const Vehicles: React.FC = () => {
  const history = useHistory();
  const [pageViewthrown, setPageViewThrown] = useState(false);
  if (!pageViewthrown) {
    setPageViewThrown(true);
    PageView(i18n.t("vehicle.title"));
  }

  const [device, setDevice] = useState<Device | null>();
  const initialDevice = useDevice();
  useEffect(() => {
    if (initialDevice && !device) {
      setDevice(initialDevice);
    }
    document.title = "FairMile® | All Vehicles";
  }, [device]);

  const [filter, settingFilter] = useState(0);

  const vehicles = useSelector((state: FullAppState) => state.vehicles.items);
  const isMobile = useIsMobile();

  return (
    <Page
      renderHeader={() => {
        return (
          <div>
            <VehiclesTabs selectedTab={filter} setSelectedTab={settingFilter} />
          </div>
        );
      }}
      showBack={false}
      title={i18n.t("vehicle.title")}
    >
      <div tw="flex flex-col">
        <Notifications />
        <div css={isMobile ? tw`mt-4` : tw``} style={{ maxWidth: "1036px" }}>
          {isMobile && (
            <button
              onClick={() => {
                history.push("/map?device=all");
              }}
              tw="pr-4 w-full text-right cursor-pointer text-highlightblue uppercase"
            >
              <div tw="flex text-right w-full justify-end items-center">
                <div tw="flex-none" aria-hidden={true}>
                  <IonIcon
                    tw="mr-2 flex-none text-highlightblue"
                    icon={mapPinIcon}
                    ariaLabel="Current Location"
                  />
                </div>

                <span className="seeMore-desktop">Show all on map</span>
              </div>
            </button>
          )}
          <div tw="flex flex-wrap">
            <VehicleList selectedTab={filter} renderDeviceCard={false} />
            {vehicles?.length > 0 && !isMobile && (
              <div>
                <MapCard
                  mapCss={filter == 0 ? "vehicle-map-area" : "device-map-area"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Vehicles;
