import { IonIcon, IonPopover, IonText } from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { alertCircle, informationCircle } from "ionicons/icons";
import { Device, getLastCommunication, LogRecord } from "../../geotab/helpers";
import { DateUtil } from "../../util/date";
import Card from "../Card/Card";

import "twin.macro";
import i18n from "../../i18n";
import { useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { isMobileViewport } from "../../util/platform";
import { useViewport } from "../../hooks/useViewport";
import { DeliveryStatus, VehicleRecordWithGeotab } from "../../entities/policy";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useHistory } from "react-router";

dayjs.extend(utc);

interface IProps {
  device: Device;
  vehicleRecord?: VehicleRecordWithGeotab;
  overrideState?: DeviceCardState;
}

const shippingInfo = (
  deliveryStatus?: DeliveryStatus,
  overrideState?: number
): React.ReactNode => {
  if (!deliveryStatus) {
    return <div></div>;
  }
  let label = "";

  if (deliveryStatus.DeliveredDate && overrideState != 0) {
    label = "Delivered Date";
  } else {
    label = "Shipped Date";
  }

  return (
    <div>
      <div tw="mt-4" className="desktop-card-subtitle">
        {label}
      </div>
      <div>
        {DateUtil.dateOnlyFormat(DateUtil.utc(deliveryStatus?.DeliveredDate)) ||
          DateUtil.dateOnlyFormat(DateUtil.utc(deliveryStatus?.ShipDate))}
      </div>
    </div>
  );
};

export type DeviceCardState =
  | "shipped"
  | "installed"
  | "paired"
  | "stopcommunicating"
  | "troublepairing";

const UnpluggedDevice: React.FC<IProps> = ({
  device,
  vehicleRecord,
  overrideState,
}: IProps) => {
  const [loaded] = useState(true);
  const viewport = useViewport();
  const history = useHistory();

  const allDeliveryStatus = useSelector((state: FullAppState) => {
    return state.policy.fullPolicy?.DeliveryStatus || [];
  });

  const deliveryStatus = useMemo(() => {
    for (const d of allDeliveryStatus) {
      if (d.SerialNumber == device.serialNumber) {
        return d;
      }
    }
    return null;
  }, [allDeliveryStatus]);

  const [lastCommunication, setLastCommunication] = useState<
    LogRecord | null | 0
  >(0);

  const [troublePairingInit, setTroublePairing] = useState(false);

  useEffect(() => {
    if (lastCommunication != null && lastCommunication != 0) {
      const d = DateUtil.utc(lastCommunication.dateTime);
      const now = DateUtil.utc();

      if (now.diff(d, "minutes") < 20) {
        return;
      }
    }
    getLastCommunication(device.id)
      .then((v) => {
        setLastCommunication(v);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [loaded]);

  useEffect(() => {
    if (lastCommunication && !vehicleRecord) {
      getLastCommunication(device.id, DateUtil.utc().add(-1, "day")).then(
        (l) => {
          const dt = DateUtil.utc(l.dateTime);
          const now = DateUtil.utc();

          if (now.diff(dt, "day") >= 1) {
            setTroublePairing(true);
          }
        }
      );
    }
  }, [lastCommunication, vehicleRecord]);

  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any;
  }>({ showPopover: false, event: undefined });

  const overDueCommunication = lastCommunication
    ? DateUtil.utc(lastCommunication.dateTime).add(1, "day") < DateUtil.utc()
    : false;

  //   const showShipping =
  //     lastCommunication == null &&
  //     deliveryStatus &&
  //     deliveryStatus?.TrackingNumber
  //       ? "TRACK PACKAGE"
  //       : undefined;

  //   const installed = lastCommunication != null;
  //   const paired = vehicleRecord;

  // States not installed and not paird and shipped
  //   const shipped = true;
  //   const installed = false;
  //   const paired = false;
  //   const communicating = false;

  // State installed but not paired
  //   const shipped = true;
  //   const installed = true;
  //   const paired = false;
  //   const communicating = true;

  // State installed but not paired and too long ago
  //   const shipped = true;
  //   const installed = true;
  //   const paired = false;
  //   const communicating = true;
  //   const troublePairing = true;

  // State paired and communicating
  //   const shipped = true;
  //   const installed = true;
  //   const paired = true;
  //   const communicating = true;

  // State paired and  not communicating
  //   const shipped = true;
  //   const installed = true;
  //   const paired = true;
  //   const communicating = false;

  const shipped = deliveryStatus != null;
  const installed = lastCommunication != 0 || lastCommunication != null;
  const paired = vehicleRecord != null;
  const communicating = !overDueCommunication;
  const troublePairing = troublePairingInit;

  let state: DeviceCardState;
  let status = "";

  if (overrideState == "shipped" || (shipped && !installed && !paired)) {
    status = "Shipped";
    state = "shipped";
  } else if (
    overrideState == "installed" ||
    overrideState == "troublepairing" ||
    (installed && !paired)
  ) {
    if (overrideState == "troublepairing" || troublePairing) {
      status = "Trouble Pairing...";
      state = "troublepairing";
    } else {
      status = "Pairing...";
      state = "installed";
    }
  } else if (overrideState == "paired" || (paired && communicating)) {
    status = "Active (Communicating)";
    state = "paired";
  } else if (
    overrideState == "stopcommunicating" ||
    (paired && !communicating)
  ) {
    status = "Active (Not Communicating)";
    state = "stopcommunicating";
  } else {
    state = "shipped";
  }

  return (
    <Card
      title={device.serialNumber}
      ariaTitle={`Device Serial Number: ${device.serialNumber}`}
      subtitle={vehicleRecord ? "Installed Device" : "Uninstalled Device"}
      ariaLabel={
        vehicleRecord ? "Installed Device Card" : "Uninstalled Device Card"
      }
      callToAction={
        state == "shipped"
          ? `https://www.fedex.com/fedextrack/?trknbr=${deliveryStatus?.TrackingNumber}`
          : undefined
      }
      callToActionLabel={state == "shipped" ? "TRACK PACKAGE" : undefined}
      leftColumnColor={isMobileViewport(viewport) ? "#0073cf" : undefined}
    >
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
        }}
      >
        <div tw="p-1">
          <IonText className="moreinfotext">
            {i18n.t("vehicle.info.missingvin")}
          </IonText>
        </div>
      </IonPopover>
      <div tw="flex flex-col ml-4 mt-4 pb-8">
        <div className="desktop-card-subtitle">Status</div>
        <div tw="flex flex-col text-center">
          <div tw="flex text-left">
            <div tw="font-bold">{status}</div>
            {state == "troublepairing" && (
              <IonIcon
                tw="ml-4"
                onClick={(e) => {
                  setShowPopover({ showPopover: true, event: e });
                }}
                className="alert-circle"
                aria-label="Information Icon"
                icon={informationCircle}
              ></IonIcon>
            )}
          </div>
          {(state == "paired" || state == "stopcommunicating") &&
            vehicleRecord && (
              <div tw="flex flex-col text-left">
                <p tw="mt-1 max-w-2xl text-sm text-headinggray">
                  {`${device.name}`}
                </p>
                <p tw="mt-1 max-w-2xl text-sm text-headinggray">
                  {`${vehicleRecord.Year} ${vehicleRecord.Make} ${vehicleRecord.Model}`}
                </p>
                <p tw="mt-1 max-w-2xl text-sm text-headinggray">{`VIN #${vehicleRecord?.VIN}`}</p>
              </div>
            )}
          {/* We have installed the device, but it hasn't detected the vin yet*/}
        </div>

        {state == "shipped" && shippingInfo(deliveryStatus || undefined, 0)}

        <section
          hidden={
            state != "installed" &&
            state != "paired" &&
            state != "troublepairing" &&
            state != "stopcommunicating"
          }
          id="communication"
        >
          <div tw="flex" id={state}>
            {(state == "paired" ||
              state == "stopcommunicating" ||
              state == "troublepairing") &&
              !communicating && (
                <div>
                  <IonIcon
                    tw="mt-4 mr-2 text-farmersred"
                    icon={alertCircle}
                    title="No recent communication"
                    aria-label="No recent communication"
                    ariaLabel="No recent communication"
                    aria-hidden={true}
                  />
                </div>
              )}
            <div tw="mt-4" className="desktop-card-subtitle">
              Last Communication
            </div>
          </div>
          <div>
            {(lastCommunication != 0 &&
              lastCommunication?.dateTime &&
              DateUtil.WithSecondsFormat(
                dayjs.utc(lastCommunication?.dateTime).local()
              )) ||
              "--"}
          </div>
          {(state == "stopcommunicating" || state == "troublepairing") && (
            <div tw="mt-1 cursor-pointer">
              <span
                onClick={() => {
                  history.push("/more?faq=1");
                }}
                tw="text-sm cursor-pointer text-farmersblue"
              >
                Please see support for installation troubleshooting.
              </span>
            </div>
          )}
        </section>
      </div>
    </Card>
  );
};

export default UnpluggedDevice;
