import { LOAD_SNAPSHOT, ISnapshotAction } from "../actions/snapshot";
import ISnapshotState from "../state/ISnapshotState";

const initialState: ISnapshotState = {
	MTDCost: 0,
	AvgCost: 0
};

export default function (state: ISnapshotState = initialState, action: ISnapshotAction): ISnapshotState {
	switch (action.type) {
		case LOAD_SNAPSHOT:
			return { ...state, MTDCost: 0, AvgCost: 0};
		default:
			return state;
	}
}