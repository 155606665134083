import React, { useEffect, useState } from "react";
import data from "./faq.json";

import tw from "twin.macro";
import Card from "../Card/Card";

import { chevronDown, chevronUp } from "ionicons/icons";

import "./FAQ.css";

import { IonIcon } from "@ionic/react";
import { Plugins } from "@capacitor/core";
import { useIsMobile } from "../../hooks/useIsMobile";
const { Browser } = Plugins;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const openUrl = async (url: string) => {
  await Browser.open({ url });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
((window as unknown) as any).openUrl = openUrl;

interface Section {
  text: string;
  sections?: Section[];
}

interface FaqData {
  sections: Section[];
}

const renderQuestion = (section: Section) => {
  return (
    <div tw="pt-6 pl-4" tabIndex={0}>
      <dt tw="text-lg">
        <div tw="text-left w-full flex justify-between items-start text-gray-400">
          <div tw="grid grid-flow-col w-full">
            <h3 tw="col-auto text-farmersblue pr-8">{section.text}</h3>
          </div>
        </div>
      </dt>
      <dd tw="mt-2 pr-12">
        {section?.sections?.map((a, index) => {
          return (
            <div key={index} tw="text-base text-gray-500">
              <div
                className="answer"
                dangerouslySetInnerHTML={{ __html: a.text }}
              ></div>
            </div>
          );
        })}
      </dd>
    </div>
  );
};

const renderSection = (
  section: Section,
  index: number,
  openQuestion: number,
  setOpenQuestion: (index: number) => void,
  openSection: number,
  setOpenSection: (index: number) => void
) => {
  return (
    <div css={openSection == index ? tw`mb-4` : ""}>
      <button
        tw="text-left"
        onClick={() => {
          if (openSection == index) {
            setOpenSection(-1);
            setOpenQuestion(-1);
            return;
          }
          setOpenSection(index);
          setOpenQuestion(-1);
        }}
      >
        <div tw="grid grid-flow-col w-full">
          <p
            tw="col-auto"
            css={openSection == index ? tw`text-farmersblue` : tw`text-black`}
            className="desktop-card-title"
          >
            {section.text}
          </p>
          <div className="screen-reader-only">
            {openSection == index ? "Expanded" : "Collapsed"}
          </div>
          <div tw="flex justify-end mr-4 items-center" aria-hidden={true}>
            <IonIcon
              icon={openSection == index ? chevronUp : chevronDown}
            ></IonIcon>
          </div>
        </div>
      </button>
      <div>
        {section.sections?.map((s, si) => (
          <div key={index * 10 + si}>
            {openSection == index && renderQuestion(s)}
          </div>
        ))}
      </div>
    </div>
  );
};

interface Props {
  selectedSection?: string;
}

const FAQ: React.FC<Props> = (props: Props) => {
  const faq = data as FaqData;
  const [open, setOpen] = useState<number>(-1);
  const [openSection, setOpenSection] = useState<number>(-1);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (props.selectedSection && openSection == -1) {
      try {
        const num = Number.parseInt(props.selectedSection);
        setOpenSection(num);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isMobile]);

  return (
    <div tw="mr-4 pb-4" role="list">
      {openSection >= 0 && (
        <div role="alert" className="screen-reader-only">
          Open Section is: {faq.sections[openSection]?.text}
        </div>
      )}
      {faq.sections.map((f, index) => (
        <div css={isMobile ? tw`mr-4` : ""} key={index} role="listitem">
          <Card
            isTabIndex={false}
            ariaLabel="Support"
            hideHeader={true}
            useMaxSize={!isMobile}
            cardSize={!isMobile ? "908px" : undefined}
          >
            <div tw="ml-4 py-2">
              {renderSection(
                f,
                index,
                open,
                setOpen,
                openSection,
                setOpenSection
              )}
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
