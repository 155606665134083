import { IonContent, IonPage } from "@ionic/react";
import PageHeader from "../components/PageHeader";
import React, { useEffect } from "react";

interface Props {
  url: string;
  location?: {
    search: string;
  };
}

export const WebView: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    document.title = "FairMile® | WebView";
  }, []);

  const url = props?.location?.search
    ? decodeURI(props.location.search.split("=")[1])
    : "";

  return (
    <IonPage style={{ background: "#F8FBFE" }} title="Settings">
      <PageHeader title="" subtitle="" showBack={true} />
      <IonContent>
        <iframe
          src={url}
          style={{ width: "100%", height: "100%" }}
          title="External Webview Link"
        ></iframe>
      </IonContent>
    </IonPage>
  );
};
