import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, } from "react-router-dom";
import { Device } from "../geotab/helpers";
import { FullAppState } from "../store/state";

function parseQuery(queryString: string) {
    const query: Record<string, string> = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}
export const useDevice = (paramName?: string, param?: Record<string, string>): Device | null => {
    const devices = useSelector((state: FullAppState) => state.vehicles.items);
    const [device, setDevice] = useState<Device | null>(null);

    if (!paramName) {
        paramName = "id";
    }

    const location = useLocation();
    const params = param || parseQuery(location.search);

    if (location.pathname.indexOf("/vehicle/") >= 0) {
        const sp = location.pathname.split("/");
        const found = devices.find((d: Device) => d.id === sp[sp.length - 1]);

        if ((!device || device.id !== sp[sp.length - 1]) && found) {
            setDevice(found);
            return found;
        }
    }

    if (!device || device.id !== params[paramName]) {
        if (devices) {
            const found = devices.find((d: Device) => d.id === params[paramName!]);
            if (found) {
                setDevice(found);
            }
        }
    }

    return device;
};