import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { Map as AdvMap } from "@seven-fields-software-ltd/geotab-map-js";
import Card from "../Card/Card";

import "twin.macro";
import { getCenterOfPoints } from "../../util/mapUtil";
import { useDebugManager } from "../../hooks/useDebugManager";

interface Props {
  mapCss: string;
}

const MapCard: React.FC<Props> = ({ mapCss }: Props) => {
  const vehiclesInit = useSelector(
    (state: FullAppState) => state.vehicles.items
  );

  const debugManager = useDebugManager();

  const vehicles = debugManager.showOnlyDebugDevices
    ? debugManager.devices || []
    : [...vehiclesInit, ...(debugManager.devices || [])];

  const center = useMemo(() => {
    if (vehicles?.length == 0) {
      return { x: 0, y: 0 };
    }
    const points = vehicles.map((p) => {
      return p.position;
    });
    return getCenterOfPoints(points);
  }, [vehiclesInit]);

  return (
    <Card
      title="Current Location"
      ariaLabel="Current Location Map Card"
      callToAction="/map?device=all"
    >
      <div
        tw="flex-grow"
        className={mapCss}
        //  == "map-area" ? { height: "305px" } : { height: "185px" }}
      >
        {vehicles?.[0] && (
          //   <div className={mapCss == "map-area" ? "map-area" : "small-map-area"}>
          <AdvMap
            center={center}
            zoom={13}
            devices={vehicles}
            customTileUrl="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY29ubmVjdGVkY292ZXJhZ2UtbWF0dCIsImEiOiJja2owdXhvZTAxeHI2MnFyd256NnplcHNiIn0.f6SfNygN0KcCgtVa2HIcDw"
            customTileAttributation='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            setRef={(ref) => {
              console.log("Map Ref:", ref);
            }}
          ></AdvMap>
        )}
      </div>
    </Card>
  );
};

export default MapCard;
