import ILoginState from "../state/loginState";
import {
    LOGIN_ACTION_NAME,
    ILoginAction,
    LOGOUT_ACTION_NAME,
    REQUIRE_NEW_PASSWORD_ACTION_NAME,
    LOGIN_ERROR_ACTION_NAME,
    LOGIN_LOADING,
    REQUIRES_TERMS,
    TERMS_ACCEPTED,
    TERMS_DECLINED,
    CLEAR_STORE,
    NOT_CONFIRMED,
    ERROR_DISSMISSED,
} from "../actions/login";

const initialState: ILoginState = {
    requireNewPassword: false,
    requireConfirmation: false,
    isLoggedIn: false,
    error: null,
    errorType: null,
    userObject: null,
    loading: false,
    userName: null,
    showTerms: false,
};

export const LoginErrorType = {
    NoUser: "USER DOES NOT EXIST.",
    InvalidPassword: "INCORRECT USERNAME OR PASSWORD.",
    NoPolicy: "PRESIGNUP FAILED WITH ERROR NO_POLICY.",
    AccountExists: "AN ACCOUNT WITH THE GIVEN EMAIL ALREADY EXISTS.",
    PasswordNoMatch: "PASSWORDS_DO_NOT_MATCH",
    ForgotPasswordNoExist: "USERNAME/CLIENT ID COMBINATION NOT FOUND.",
    TooManyAttempts: "ATTEMPT LIMIT EXCEEDED, PLEASE TRY AFTER SOME TIME.",
    InvalidVerificationCode: "INVALID VERIFICATION CODE PROVIDED, PLEASE TRY AGAIN.",
    PasswordCannotBeEmpty: "PASSWORD CANNOT BE EMPTY",
    UsernameCannotBeEmpty: "UsernameCannotBeEmpty"
};

const getErrorFromString = (error?: string) => {
    return error?.toUpperCase();
};

export default function (
    state: ILoginState = initialState,
    action: ILoginAction
): ILoginState {
    switch (action.type) {
        case NOT_CONFIRMED:
            return {
                ...state,
                requireNewPassword: false,
                requireConfirmation: true,
                isLoggedIn: false,
                error: null,
                userObject: action.user,
                loading: false,
                userName: action.userName,
            };
        case REQUIRE_NEW_PASSWORD_ACTION_NAME:
            return {
                ...state,
                requireNewPassword: true,
                requireConfirmation: false,
                isLoggedIn: false,
                error: null,
                userObject: action.user,
                loading: false,
                userName: action.userName,
            };
        case LOGIN_ACTION_NAME:
            return {
                ...state,
                isLoggedIn: !state.isLoggedIn,
                requireNewPassword: false,
                requireConfirmation: false,
                error: null,
                userObject: action.user,
                loading: false,
                userName: action.userName,
            };
        case LOGOUT_ACTION_NAME:
            return initialState;
        case LOGIN_ERROR_ACTION_NAME:
            return {
                ...state,
                requireNewPassword: false,
                requireConfirmation: false,
                isLoggedIn: false,
                error: action.error,
                errorType: getErrorFromString(action.error?.toUpperCase()),
                userObject: null,
                loading: false,
                userName: null,
            };
        case LOGIN_LOADING:
            return { ...state, loading: true };
        case REQUIRES_TERMS:
            return {
                ...state,
                isLoggedIn: false,
                requireNewPassword: false,
                requireConfirmation: false,
                showTerms: true,
                error: null,
                userObject: action.user,
                loading: false,
                userName: action.userName,
            };
        case TERMS_ACCEPTED:
            return { ...state, isLoggedIn: true, showTerms: false };
        case TERMS_DECLINED:
            return state;
        case ERROR_DISSMISSED:
            return { ...state, error: undefined, errorType: undefined };
        case CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
}
