import dayjs from "dayjs";
import { useMemo } from "react";
import { DateUtil } from "../util/date";

export const useInitialStoredDates = (startStorageKey: string, endStorageKey: string): [dayjs.Dayjs | undefined, dayjs.Dayjs | undefined] => {
    const [initialStart, initialEnd] = useMemo(() => {
        // const initialStart = localStorage.getItem(initialStartStorageKey);
        // const initialEnd = localStorage.getItem(initialEndStorageKey);

        const initialStart = window.sessionStorage[startStorageKey];
        const initialEnd = window.sessionStorage[endStorageKey];

        let parsedInitialStart: dayjs.Dayjs | undefined = undefined;

        try {
            parsedInitialStart =
                (initialStart && DateUtil.utc(initialStart)) || undefined;
        } catch (err) {
            //nop
        }

        let parsedInitialEnd: dayjs.Dayjs | undefined = undefined;

        try {
            parsedInitialEnd = (initialEnd && DateUtil.utc(initialEnd)) || undefined;
        } catch (err) {
            //nop
        }

        return [parsedInitialStart, parsedInitialEnd];
    }, []);

    return [initialStart, initialEnd];
};