import dayjs from "dayjs";
import dutc from "dayjs/plugin/utc";
import calendar from "dayjs/plugin/calendar";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { DateFormatDateOnly, DateFormatTimeOnlyWithSeconds, DateFormatWithSeconds } from "../constants";

dayjs.extend(dutc);
dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

interface durationType {
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
    milliseconds: number
}

export class DateUtil {
    static duration(durationObj: durationType): plugin.Duration {
        return dayjs.duration(durationObj);
    }

    static unix(value: number): dayjs.Dayjs {
        return dayjs.unix(value);
    }

    static utc(value?: string | number): dayjs.Dayjs {
        if (value == null) {
            return dayjs.utc();
        }
        return dayjs.utc(value);
    }

    static local(value?: string | number): dayjs.Dayjs {
        if (value == null) {
            return dayjs();
        }
        return dayjs(value);
    }

    static WithSecondsFormat(value: dayjs.Dayjs): string {
        return value.format(DateFormatWithSeconds);
    }

    static dateOnlyFormat(value: dayjs.Dayjs): string {
        return value.format(DateFormatDateOnly);
    }

    static timeOnlyFormat(value: dayjs.Dayjs): string {
        return value.format(DateFormatTimeOnlyWithSeconds);
    }

    static dateToLocalTimeZone(date: dayjs.Dayjs): dayjs.Dayjs {
        if (date.isUTC()) {
            return date.local();
        }

        return date;
    }

    static durationShortFormat(duration?: plugin.Duration): string {
        if (!duration) {
            return "--";
        }
        let str = "";

        if (duration.hours() > 0) {
            str += "H [h] ";
        }

        if (duration.minutes() > 0) {
            str += "m [m] ";
        }

        if (duration.seconds() > 0) {
            str += "s [s]";
        }

        return str.length > 0 ? duration.format(str) : "0 seconds";
    }

    static durationFormat(duration?: plugin.Duration): string {
        if (!duration) {
            return "--";
        }
        let str = "";

        if (duration.hours() > 0) {
            str += "H [hours] ";
        }

        if (duration.minutes() > 0) {
            str += "m [minutes] ";
        }

        if (duration.seconds() > 0) {
            str += "s [seconds]";
        }

        return str.length > 0 ? duration.format(str) : "0 seconds";
    }
}