import { IonIcon } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import React from "react";
import { useBeforePolicy } from "../../hooks/useBeforePolicy";
import { usePolicy } from "../../hooks/usePolicy";
import { DateUtil } from "../../util/date";

import tw from "twin.macro";

interface Props {
  message?: string;
  override?: boolean;
}

const BeforePolicy: React.FC<Props> = (props: Props) => {
  const beforePolicy = useBeforePolicy();
  const policy = usePolicy();

  const effectiveDate = DateUtil.dateOnlyFormat(
    DateUtil.local(policy?.PolicyEffectiveDate)
  );
  return (
    <div css={beforePolicy ? tw`pb-2 pr-4` : ""}>
      {(props.override || beforePolicy) && (
        <div tw="flex m-1">
          <IonIcon
            tw="ml-4 mr-2"
            className="alert-circle"
            aria-label="Information Icon"
            icon={informationCircle}
          ></IonIcon>
          <p tw="text-sm text-headinggray">
            {props.message == "Trip"
              ? `Any trips that occur before ${effectiveDate} are for information purposes only, and will not be included in billing.`
              : `We begin tracking your mileage on your policy effective date of ${effectiveDate}`}
          </p>
        </div>
      )}
    </div>
  );
};

export default BeforePolicy;
