import refiner from "refiner-js";
import { Settings } from "../refiner/refinerSettings";

export const showFeedback = (): void => {
    const myIframe = document.getElementById("refiner-widget-frame");
    if (myIframe?.title != "FairMile Feedback") {
        refiner("setProject", Settings.ProjectId);
        if (myIframe !== null) myIframe.title = "FairMile Feedback";
    }

    const refinerSurvey = Settings.SurveyId;
    refiner("showForm", refinerSurvey, true);
};
