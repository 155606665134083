import { CLEAR_STORE } from "../../login/actions/login";
import { ILoadPolicyAction, LOAD_POLICY } from "../actions/actions";
import IPolicyState from "../state/state";

const initialState: IPolicyState = {
    policy: null,
    vehicles: {},
    fullPolicy: null
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function (state: IPolicyState = initialState, action: ILoadPolicyAction): IPolicyState {
    switch (action.type) {
        case LOAD_POLICY:
            return { ...state, policy: action.policy || null, vehicles: action.vehicles || {}, fullPolicy: action.fullPolicy };
        case CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
}