import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

export interface Viewport {
  width: number;
  height: number;
}

const ViewportContext = createContext<Viewport>({
  width: window.innerWidth,
  height: window.innerHeight,
});

interface Props {
  children: ReactNode;
}

export const ViewportProvider: FC<Props> = (props: Props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ width, height }}>
      {props.children}
    </ViewportContext.Provider>
  );
};

export function useViewport(): Viewport {
  return useContext<Viewport>(ViewportContext);
}
