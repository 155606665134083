import React, { useState } from "react";
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonContent,
  IonTitle,
  IonToast,
  IonImg,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { FullAppState } from "../../store/state";
import { changePassword } from "../../store/login/actions/login";
import "./MainLogin.css";
import i18n from "../../i18n";

// interface Props {
//   goBack: () => void;
// }

export const NewPassword: React.FC = () => {
  const loading = useSelector((state: FullAppState) => state.login.loading);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [errorToast, setErrorToast] = useState<string>();
  const dispatch = useDispatch();

  const validatePassword = () => {
    if (newPassword === confirmPassword) {
      dispatch(changePassword(newPassword!));
    } else {
      setErrorToast(i18n.t("newpassword.error"));
    }
  };

  return (
    <IonContent className="slidePage">
      <div className="fullscreen">
        <div id="textContainer" className="center">
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonImg id="logo" src="assets/splash.png" alt="Splash Logo" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonTitle style={{ marginTop: "10px" }}>
                  {i18n.t("login.changepw")}
                </IonTitle>
                <div style={{ marginTop: "10px" }}>
                  <IonItem>
                    <IonInput
                      disabled={loading}
                      type="password"
                      placeholder="New Password"
                      onIonChange={(e) => setNewPassword(e.detail.value!)}
                    />
                  </IonItem>

                  <IonItem>
                    <IonInput
                      disabled={loading}
                      type="password"
                      placeholder="Confirm Password"
                      onIonChange={(e) => setConfirmPassword(e.detail.value!)}
                    />
                  </IonItem>
                </div>
              </IonCol>
            </IonRow>
            <IonRow id="bottomRow">
              <IonButton
                class="center"
                disabled={loading}
                onClick={() => validatePassword()}
              >
                {i18n.t("button.changepw")}
              </IonButton>
            </IonRow>
          </IonGrid>
        </div>
      </div>
      <IonToast
        isOpen={errorToast != null && errorToast.length > 0}
        message={errorToast}
        duration={2000}
        position="bottom"
      />
    </IonContent>
  );
};
