import {
    FailedLoadActiontype as FailedLoadTripActiontype,
    FAILED_LOAD_TRIP,
    LoadTripActionType,
    LOAD_SINGLE_TRIP,
    LOAD_TRIPS,
    LOAD_TRIP_DATA,
    TripsActionType,
} from "./../actions/trips";
import ITripsState from "../state/tripsState";
import { Trip } from "../../../geotab/helpers";
import { CLEAR_STORE } from "../../login/actions/login";

const defaultState: ITripsState = {
    items: [],
    failedItems: {}
};

const sortTrip = (a: Trip, b: Trip): number => {
    if (a.startTime > b.startTime) {
        return -1;
    } else if (a.startTime < b.startTime) {
        return 1;
    }

    return 0;
};

export default function (
    state: ITripsState = defaultState,
    action: TripsActionType | LoadTripActionType | FailedLoadTripActiontype
): ITripsState {
    switch (action.type) {
        case LOAD_TRIPS: {
            let trips = [...action.trips];
            if (state.items && state.items.length > 0) {
                trips = state.items;
                for (const t of action.trips) {
                    const oldTrip = trips.findIndex(oldTrip => { return oldTrip.id == t.id; });
                    if (oldTrip >= 0) {
                        trips[oldTrip] = t;
                    } else {
                        trips.push(t);
                    }
                }
            }

            return { ...state, items: [...trips.sort(sortTrip)] };
        }
        case LOAD_TRIP_DATA: {
            const trips = state.items.filter((trip) => trip.id !== action.trip.id);
            trips.push(action.trip);
            return { ...state, items: trips.sort(sortTrip) };
        }
        case LOAD_SINGLE_TRIP: {
            const trips = state.items.filter((trip) => trip.id !== action.trip.id);
            trips.push(action.trip);
            return { ...state, items: trips.sort(sortTrip) };
        }
        case FAILED_LOAD_TRIP: {
            const failedTrips = { ...state.failedItems, [action.tripId]: action.reason };
            return { ...state, failedItems: failedTrips };
        }
        case CLEAR_STORE: {
            return defaultState;
        }
        default: {
            return state;
        }
    }
}
