import React, { useEffect, useState } from "react";
import { FullAppState } from "../../store/state";
import { INewsFeedItem } from "../../store/newsfeed/state/newsfeedState";
import "./NewsFeed.css";
import { useSelector, useDispatch } from "react-redux";
import { loadNewsFeed } from "../../store/newsfeed/actions/newsfeed";
import NewsfeedItem from "./newsfeedItem";

import Card from "../Card/Card";

import "twin.macro";
import { IonSpinner } from "@ionic/react";

const NewsFeed: React.FC = () => {
  const [intervalKey, setIntervalKey] = useState<NodeJS.Timeout | null>(null);
  const newsFeedList = useSelector((state: FullAppState) => state.newsfeed) as {
    items: INewsFeedItem[];
  };

  const newsFeedLoading = useSelector((state: FullAppState) => {
    return state.newsfeed.loading;
  });

  const policyNumber = useSelector(
    (state: FullAppState) => state?.policy?.policy?.PolicyNumber
  );

  const isLoggedIn = useSelector((state: FullAppState) => {
    return state.login.isLoggedIn;
  });

  if (newsFeedList && newsFeedList.items && newsFeedList.items.length > 0) {
    newsFeedList.items = newsFeedList.items.sort(
      (a: INewsFeedItem, b: INewsFeedItem) => {
        if (a.time > b.time) {
          return -1;
        } else if (a.time < b.time) {
          return 1;
        }

        return 0;
      }
    );
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (intervalKey || !policyNumber) {
      return;
    }

    const interval = setInterval(() => {
      if (isLoggedIn) {
        dispatch(loadNewsFeed());
      }
    }, 17000);

    if (isLoggedIn && newsFeedList.items.length === 0) {
      dispatch(loadNewsFeed());
    }

    setIntervalKey(interval);

    return () => clearInterval(interval);
  }, [policyNumber, dispatch]);

  return (
    <Card title="News Feed" ariaLabel="News Feed Card" callToAction="/newsfeed">
      <div>
        <div
          tw="overflow-y-auto overflow-x-hidden"
          style={{ height: "302px" }}
          role="list"
        >
          {newsFeedLoading && (
            <div tw="flex justify-center items-center h-full text-farmersblue">
              <IonSpinner />
            </div>
          )}
          {!newsFeedLoading &&
            newsFeedList.items.map((item, key) => {
              return (
                <div key={key} tw="pl-4 border-b border-dashed">
                  <NewsfeedItem
                    item={item}
                    mobile={false}
                    key={key}
                    lastItem={key == newsFeedList.items.length - 1}
                  />
                </div>
              );
            })}

          {!newsFeedLoading && newsFeedList.items.length == 0 && (
            <div tw="mx-4 mt-4">No news feed items</div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default NewsFeed;
