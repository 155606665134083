import { Plugins, StatusBarStyle } from "@capacitor/core";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import AppUrlListener from "./AppUrlListener";
import { saveUserToken } from "./aws/util";
import DesktopHeader from "./components/DesktopHeader/DesktopHeader";
import { Drawer } from "./components/Drawer/Drawer2";
import Idle from "./components/Idle/Idle";
import Routes from "./components/Router/Routes";
import SignInLoading from "./components/SignInLoading/SignInLoading";
import TabBar from "./components/TabBar/TabBar2";
import useAppInit from "./hooks/useAppInit";
import { useIsMobile } from "./hooks/useIsMobile";
import { FullAppState } from "./store/state";
import { isMobile } from "./util/platform";
import * as SplashScreenManager from "./system/SplashScreenManager";

import AmplifyInit from "./aws/Amplify";

import InitNotifications from "./notifications/NotificationManager";

import "twin.macro";
import AppError from "./components/AppError/AppError";

const AppController: React.FC = () => {
  SplashScreenManager.hide();
  const { StatusBar } = Plugins;

  const policyNumber = useSelector((state: FullAppState) => {
    return state.policy.policy?.PolicyNumber;
  });

  const isLoggedIn = useSelector((state: FullAppState) => {
    return state.login.isLoggedIn;
  });

  const history = useHistory();

  const mounted = useRef(true);

  useEffect(() => {
    AmplifyInit();
    if (isMobile()) {
      StatusBar.setStyle({ style: StatusBarStyle.Dark });
      InitNotifications((url: string) => history.push(url));
    }
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (policyNumber != null) {
      saveUserToken(policyNumber);
    }
  }, [policyNumber]);

  const mobile = useIsMobile();

  const deviceError = useSelector(
    (state: FullAppState) => state.vehicles.error
  );

  const loading = !useAppInit() && isLoggedIn;

  const location = useLocation();
  const hideTabBar = location.search.indexOf("hidebar=true") >= 0;

  return (
    <div tw="h-full w-screen">
      <AppUrlListener></AppUrlListener>
      <Idle />
      {deviceError && <AppError />}
      {!deviceError && loading && <SignInLoading />}

      {!deviceError && !loading && mobile && (
        <div tw="flex flex-col h-full w-full z-10" aria-live="polite">
          <Routes isLoggedIn={isLoggedIn} removeOutlet={true} />
          {<div hidden={!isLoggedIn} id="bottom-space" tw="h-16 pt-2"></div>}
        </div>
      )}

      <div hidden={deviceError || loading} tw="z-40">
        <div tw="fixed bottom-0 w-screen z-40">
          {mobile && isLoggedIn && !hideTabBar && <TabBar />}
        </div>
      </div>
      {!deviceError && !loading && !mobile && (
        <div id="chrome" tw="flex flex-col">
          <DesktopHeader />
          <div
            id="main-content"
            tw="flex"
            style={{ height: "calc(100vh - 81px)" }}
          >
            <div id="drawer-container" tw="h-full" role="navigation">
              <Drawer isLoggedIn={isLoggedIn} />
            </div>
            <div
              id="desktop-routes"
              tw="h-full w-full"
              role="main"
              aria-live="polite"
            >
              <Routes
                id={
                  isLoggedIn
                    ? "main-menu-content-logged-in"
                    : !mobile
                    ? "main-menu-content"
                    : ""
                }
                isLoggedIn={isLoggedIn}
                removeOutlet={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppController;
