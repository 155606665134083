import { Auth } from "aws-amplify";
import { LoginEvent } from "../telemetry/TelemetryManager";

export enum SignInStatus {
    Success,
    Failed,
    NewPasswordRequired,
    NotConfirmed
}

export interface ISignInResponse {
    status: SignInStatus;
    username?: string;
    name?: string;
    email?: string;
    session?: string;
    error?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userObject?: any;
}

export async function SignIn(
    username: string,
    password: string
): Promise<ISignInResponse> {
    try {
        const user = await Auth.signIn(username, password);
        if (user.challengeName && user.challengeName === "NEW_PASSWORD_REQUIRED") {
            LoginEvent("NewPasswordRequired");
            return {
                status: SignInStatus.NewPasswordRequired,
                username: user.username,
                name: user.challengeParam.userAttributes.name,
                email: user.challengeParam.userAttributes.email,
                session: user.Session,
                userObject: user,
            };
        } else {
            return {
                status: SignInStatus.Success,
                username: user.username,
                name: "",
                email: "",
                session: user.Session,
                userObject: user,
            };
        }
    } catch (error) {
        if (error && error.code == "UserNotConfirmedException") {
            return {
                status: SignInStatus.NotConfirmed,
                username: username,
            };
        }
        // LoginEvent("LoginFailed");
        return {
            status: SignInStatus.Failed,
            error: error.message,
        };
    }
}

export async function ChangePassword(
    user: { challengeParam: { userAttributes: Record<string, unknown> } },
    newPassword: string
): Promise<ISignInResponse> {
    try {
        const passwordResponse = await Auth.completeNewPassword(
            user,
            newPassword,
            user.challengeParam.userAttributes
        );
        console.log(passwordResponse);

        return {
            status: SignInStatus.Success,
        };
    } catch (error) {
        return {
            status: SignInStatus.Failed,
            error: error,
        };
    }
}

export async function logout(): Promise<void> {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log(error);
    }
}
