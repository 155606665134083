import React from "react";
import Card from "../Card/Card";
import tw from "twin.macro";
import i18n from "../../i18n";

export interface MileageCardInfo {
  title: string;
  Miles: number[];
  id: string;
  sortKey: string;
  startOdometer: number;
  endOdometer: number;
}

interface Props {
  cardInfo: MileageCardInfo;
}
const MileageCard: React.FC<Props> = (props: Props) => {
  const decimalPlaces = 0;
  return (
    <Card
      ariaLabel={props.cardInfo.title}
      title={props.cardInfo.title}
      infoText={i18n.t("mileage.info")}
      infoTextTitle={"Mileage Information"}
    >
      <section id="mileage-card-main" tw="uppercase items-center mt-4">
        <div tw="overflow-hidden">
          <dl tw="grid grid-cols-3 grid-rows-1 justify-items-auto">
            <div tw="flex flex-col items-center">
              <dt tw="text-headinggray">Actual</dt>

              <dd className="desktop-card-title" tw="pt-2">
                {props.cardInfo.Miles[1].toFixed(decimalPlaces)}
              </dd>

              <dt tw="lowercase text-headinggray">miles</dt>
            </div>
            <div tw="flex flex-col items-center">
              <dt tw="text-headinggray">Estimated</dt>
              <dd className="desktop-card-title" tw="pt-2">
                <span>{props.cardInfo.Miles[0].toFixed(decimalPlaces)}</span>
              </dd>

              <dt tw="lowercase text-headinggray">miles</dt>
            </div>
            <div tw="flex flex-col items-center">
              <dt tw="text-headinggray">Difference</dt>
              <dd className="desktop-card-title" tw="pt-2">
                <span
                  css={
                    props.cardInfo.Miles[2] >= 0
                      ? tw`text-farmersblue`
                      : tw`text-farmersred`
                  }
                >
                  {(props.cardInfo.Miles[2] * -1).toFixed(decimalPlaces)}
                </span>
              </dd>

              <dt
                tw="lowercase text-headinggray"
                css={
                  props.cardInfo.Miles[2] >= 0
                    ? tw`text-farmersblue`
                    : tw`text-farmersred`
                }
              >
                miles
              </dt>
            </div>
          </dl>
        </div>
      </section>

      <div tw="mx-16 border-t border-dashed mt-4"></div>
      <section id="odometer">
        <div tw="mx-8 uppercase mt-4">
          <div tw="items-center my-4" style={{ fontSize: "16px" }}>
            <div tw="overflow-hidden">
              <dl tw="grid grid-cols-2 grid-rows-1 justify-center">
                <div tw="flex flex-col items-center">
                  <dt tw="text-headinggray">Odometer Start</dt>
                  <dd tw="pt-2 lowercase font-bold">
                    {props.cardInfo.startOdometer.toFixed(decimalPlaces)} miles
                  </dd>
                </div>
                <div tw="flex flex-col items-center">
                  <dt tw="text-headinggray">Odometer End</dt>
                  <dd tw="pt-2 lowercase font-bold">
                    {props.cardInfo.endOdometer.toFixed(decimalPlaces)} miles
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
    </Card>
  );
};

export default MileageCard;
