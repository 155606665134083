import { useEffect, useMemo, useState } from "react";
import dayjs from 'dayjs';
import { DateUtil } from "../util/date";
import { Device } from "../geotab/helpers";
import { ICardItem } from "../store/cardmock/state/cardState";

const useDateFilter = <T>(
    list: T[],
    filter: (item: T, index: number, fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => boolean,
    mapToCard: (list: T[]) => ICardItem[],
    setShowLoading: (value: boolean) => void,
    load: (from: dayjs.Dayjs, to: dayjs.Dayjs) => Promise<unknown>,
    device?: Device | null,
    initialFromDate?: dayjs.Dayjs,
    initialToDate?: dayjs.Dayjs
)
    : [list: ICardItem[], fromDate: dayjs.Dayjs, setFrom: (d: dayjs.Dayjs) => void, toDate: dayjs.Dayjs, setTo: (d: dayjs.Dayjs) => void] => {

    const [toDate, setTo] = useState<dayjs.Dayjs>(initialToDate || DateUtil.local());
    const [fromDate, setFrom] = useState<dayjs.Dayjs>(
        initialFromDate || DateUtil.local().add(-1, "month")
    );

    const [lastSearch, setLastSearch] = useState<string>("");

    useEffect(() => {
        const from = fromDate.toISOString();
        const to = toDate.toISOString();
        const search = `${to}-${from}`;

        if (search == lastSearch) {
            return;
        }

        setShowLoading(true);
        setLastSearch(search);

        load(fromDate, toDate).then(() => {
            setShowLoading(false);
        });
    }, [fromDate, toDate, device]);

    const filteredList = useMemo(() => {
        if (!fromDate || !toDate) {
            return [];
        }

        const filtered = list.filter((i, index) => {
            const result = filter(i, index, fromDate, toDate);
            return result;
        });

        return mapToCard(filtered);
    }, [list, list.length, lastSearch, device]);

    return [filteredList, fromDate, setFrom, toDate, setTo];
};

export default useDateFilter;