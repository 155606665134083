import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import GlobalStyles from "./components/GlobalStyles";

import "./i18n";
import App from "./App";

// import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/state";
import { devToolsEnhancer } from "redux-devtools-extension";

import * as SplashScreenManager from "./system/SplashScreenManager";
import { ViewportProvider } from "./hooks/useViewport";

SplashScreenManager.show();

// import { FirebaseX } from "@ionic-native/firebase-x/index";
// import * as RemoteConfigManager from './system/RemoteConfigManager';
// FirebaseX.setAnalyticsCollectionEnabled(true);
// FirebaseX.setCrashlyticsCollectionEnabled(true);
// FirebaseX.logEvent("app_session_start", {});
// RemoteConfigManager.init().catch(err => {
// 	console.log(err);
// });

//PushNotificationInit();
const keyboardUserCssClass = "keyboardUser";
function setIsKeyboardUser(isKeyboard: boolean) {
  const { body } = document;
  if (isKeyboard) {
    body.classList.contains(keyboardUserCssClass) ||
      body.classList.add(keyboardUserCssClass);
  } else {
    body.classList.remove(keyboardUserCssClass);
  }
}

// This is a quick hack to activate focus styles only when the user is
// navigating with TAB key. This is the best compromise we've found to
// keep nice design without sacrifying accessibility.
document.addEventListener("keydown", (e: KeyboardEvent) => {
  if (e.key === "Tab") {
    setIsKeyboardUser(true);
  }
});

document.addEventListener("click", (e: MouseEvent) => {
  // Pressing ENTER on buttons triggers a click event with coordinates to 0
  setIsKeyboardUser(!e.screenX && !e.screenY);
});

document.addEventListener("mousedown", () => {
  setIsKeyboardUser(false);
});

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk), devToolsEnhancer({}))
);
const ReduxApp = () => (
  <Provider store={store}>
    <ViewportProvider>
      <GlobalStyles />
      <App />
    </ViewportProvider>
  </Provider>
);

ReactDOM.render(<ReduxApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
//serviceWorker.register();
