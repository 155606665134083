import { Auth } from "aws-amplify";
import { updateEndpoint } from "./Pinpoint";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ISignInResponse, SignInStatus } from "./auth";
import {
    getNotificationToken,
} from "../system/StorageManager";
import { DateUtil } from "../util/date";

export const checkIfUserLogged: () => Promise<boolean> = async () => {
    try {
        const session = await Auth.currentSession();
        if (session) {
            const accessToken = session.getAccessToken();
            const date = DateUtil.utc();
            if (accessToken?.payload?.auth_time) {
                const authTime = DateUtil.unix(accessToken.payload.auth_time);
                return authTime.add(30, 'minute') > date;
            }
        }
        return false;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const getLoggedUser: () => Promise<
    ISignInResponse | undefined
> = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        return {
            status: SignInStatus.Success,
            username: user.username,
            name: user.attributes.name,
            email: user.attributes.email,
            session: user.Session,
            userObject: user,
        };
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const saveUserToken = async (policyNumber: string): Promise<void> => {
    try {
        if (!policyNumber) {
            return;
        }

        const token = await getNotificationToken();
        if (!token) {
            return;
        }

        console.log(await updateEndpoint(token, policyNumber));
    } catch (err) {
        console.log(err);
    }
};