import dayjs from "dayjs";
import { HistoricMileageRecord, MileageRecord } from "../api/types/mileage";
import { VehicleRecord } from "../entities/policy";
import { Device } from "../geotab/helpers";
import { HistoricMileageStore } from "../store/mileage/state/mileageState";
import { DateUtil } from "./date";

// import { VehicleRecord } from "../entities/policy";
// import { Device } from "../geotab/helpers";
// import { HistoricMileageRecord, MileageRecord } from "../api/types/mileage";

export function toMiles(input: number): number {
    return (input * 0.621371) / 1000;
}

export function toMilesNoDiv(input: number): number {
    return (input * 0.621371);
}

export function fromMiles(input: number): number {
    return Math.round(input * 1.60934);
}

// All functions grouped by date use this as a key
export function getMonthKey(date: dayjs.Dayjs): string {
    return date.format("YYYY-MM");
}

/**
 * 
 * @param policyVehicles 
 * @param devices 
 * @param deviceId 
 */
export function getEstimatedMileageForMonthForDevice(policyVehicles: Record<string, VehicleRecord>, devices: Device[], deviceId: string): number | null {
    //Find the vehicle that is matched with this device. If no match don't include it
    let policyVehicle: VehicleRecord | null = null;
    const keys = Object.keys(policyVehicles);
    for (let i = 0; i < keys.length; i++) {
        for (let j = 0; j < devices.length; j++) {
            const device = devices[j];
            if (device.id == deviceId && device.vehicleIdentificationNumber == policyVehicles[keys[i]].VIN) {
                policyVehicle = policyVehicles[keys[i]];
            }
        }
    }

    if (policyVehicle == null) {
        return null;
    }

    const est = Math.ceil(
        Number.parseInt(policyVehicle.EstimatedAnnualMileage!) / 12
    );

    return est;
}

export const getAverageMileagePerMonth = (historicMileage: Record<string, Record<string, HistoricMileageRecord>>): Record<string, number> => {
    const monthMap: Record<string, number> = {};
    for (const deviceId of Object.keys(historicMileage)) {
        const device = historicMileage[deviceId];
        if (!device) {
            continue;
        }
        for (const monthKey of Object.keys(device)) {
            const monthMileageRecord = device[monthKey];
            if (!monthMap[monthKey]) {
                monthMap[monthKey] = 0;
            }

            monthMap[monthKey] += monthMileageRecord?.mileage ? monthMileageRecord.mileage : 0;
        }
    }

    return monthMap;
};

export type MileageStat = { total: number, count: number };
export const getTotalMileageStatsPerMonth = (historicMileageStore: HistoricMileageStore, currentMileage: Record<string, MileageRecord>, device?: Device | null): Record<string, MileageStat> => {
    const monthMap: Record<string, MileageStat> = {};
    for (const deviceId of Object.keys(historicMileageStore)) {
        if (device && device.id != deviceId) {
            continue;
        }

        // Get the months mileage per for this device
        const recordsForDevice = historicMileageStore[deviceId];
        if (!recordsForDevice) {
            continue;
        }

        // get the current mileage for this device
        const currentMileageRecord = currentMileage[deviceId];
        // Get the month key, but if we don't have the record then we shouldn't match with anyting and just ignore the current mileage. This can happen as there are multiple
        // requests to get the current and historic. Although the historic does contain the server's idea of currentMileage at the time of getting historic
        const currentMileageMonthKey = currentMileageRecord && getMonthKey(DateUtil.utc(currentMileageRecord.dateTime)) || "--";

        //FOr each month
        for (const monthKey of Object.keys(recordsForDevice.historicMileage)) {
            const monthMileageRecord = recordsForDevice.historicMileage[monthKey];

            // Initialize the record
            if (!monthMap[monthKey]) {
                monthMap[monthKey] = {
                    total: 0,
                    count: 0
                };
            }

            // If the current mileage exists and is for this month
            if (monthKey == currentMileageMonthKey) {
                // If the current record is greater then the end record that exists then use the current milage
                if (
                    monthMileageRecord.startRecord != null &&
                    (monthMileageRecord.endRecord == null ||
                        currentMileageRecord.dateTime > monthMileageRecord.endRecord.dateTime)
                ) {
                    monthMap[monthKey].total += currentMileage[deviceId].value - monthMileageRecord.startRecord.value;
                    monthMap[monthKey].count++;
                } else {
                    // Use the mileage that exists in the historic record
                    monthMap[monthKey].total += monthMileageRecord.mileage ?? 0;
                    monthMap[monthKey].count++;
                }
            }
            else {
                // The current record isn't for the month we are calculating so just use what is in the historic record
                monthMap[monthKey].total += monthMileageRecord.mileage ?? 0;
                monthMap[monthKey].count++;
            }
        }
    }

    return monthMap;
};