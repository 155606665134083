import dayjs from "dayjs";
import { useEffect } from "react";

export const useStoreInitialDates = (fromStorageKey: string, fromDate: dayjs.Dayjs, toStorageKey: string, toDate: dayjs.Dayjs): void => {
    useEffect(() => {
        // localStorage.setItem(initialStartStorageKey, fromDate.toISOString());
        // localStorage.setItem(initialEndStorageKey, toDate.toISOString());
        if (fromDate) {
            window.sessionStorage[fromStorageKey] = fromDate.toISOString();
        }

        if (toDate) {
            window.sessionStorage[toStorageKey] = toDate.toISOString();
        }
    }, [fromDate, toDate]);
};