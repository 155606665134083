import React from "react";
import "./SystemCard.css";
import "../Common/popover.css";
import { CardItemType, ICardItem } from "../../store/cardmock/state/cardState";
import MileageContainer from "./MileageContainer";
import FaultExceptionContainer from "./FaultExceptionContainer";
import TripContainer from "./TripContainer";

import "twin.macro";
import Card from "../Card/Card";

interface CardProps {
  card: ICardItem;
  style?: React.CSSProperties;
}

const ConditionalContainer: React.FC<CardProps> = (props: CardProps) => {
  const card = props.card;
  if (card.typeName === 3) {
    return (
      <MileageContainer
        miles={card.Miles || ["--", "--", "--"]}
        startOdometer={card.startOdometer}
        endOdometer={card.endOdometer}
      />
    );
  } else if (card.typeName === 0 || card.typeName === 1) {
    return (
      <FaultExceptionContainer
        title={card.title}
        description={card.subTitle}
        descriptionLinks={card.subtitleLinks}
        start={card.activeFrom}
        end={card.activeTo}
        isFault={card.typeName == 0}
      />
    );
  } else if (card.typeName === 2) {
    return (
      <TripContainer
        start={card.activeFrom}
        end={card.activeTo}
        distance={card.distance ?? "--"}
        stopDuration={card.stopDuration}
        drivingDuration={card.drivingDuration}
      />
    );
  } else {
    return <></>;
  }
};

const SystemCard: React.FC<CardProps> = (props: CardProps) => {
  return (
    <Card
      ariaLabel={
        props.card.typeName == CardItemType.Trip
          ? "Trip Card"
          : props.card.typeName == CardItemType.Exception
          ? "Exception Card"
          : "Fault Card"
      }
      hideHeader={props.card.typeName == 2 || props.card.typeName == 0}
      title={props.card.title}
      callToAction={props.card.callToAction}
      callToActionLabel="Show on Map"
    >
      <ConditionalContainer card={props.card} />
    </Card>
  );
};

export default SystemCard;
