import React, { useEffect, useMemo, useState } from "react";

import "./Vehicle.css";
import { CardItemType, ICardItem } from "../../store/cardmock/state/cardState";
import ListCard from "../../components/ListCard/ListCard4";
import { useDispatch, useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { loadTrips } from "../../store/trips/actions/trips";
import { loadExceptions } from "../../store/exceptions/actions/exceptions";
import { loadFaultData } from "../../store/faultData/actions/faultData";
import { PageView } from "../../telemetry/TelemetryManager";
import { Device } from "../../geotab/helpers";
import { DateUtil } from "../../util/date";
import Notifications from "../../components/Notifications/Notifications";
import SnapshotCard from "../../components/SnapshotCard/Snapshot3";

import tw from "twin.macro";
import MapCard from "../../components/MapCard/MapCard";
import { useDevice } from "../../hooks/useDevice";
import Page from "../Page";
import { useIsMobile } from "../../hooks/useIsMobile";
import i18n from "../../i18n";

interface Props {
  vehicleName: string;
}

const Vehicle: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const initialDevice = useDevice();

  const [device, setDevice] = useState<Device | null>();

  useEffect(() => {
    if (initialDevice && !device) {
      setDevice(initialDevice);
    }
  }, [setDevice]);

  const trips = useSelector((state: FullAppState) => {
    return state.trips.items.filter((t) => {
      if (device === null) return true;
      else return t.device === device?.id;
    });
  });

  const faultData = useSelector((state: FullAppState) => {
    return state.faultData.items.filter((f) => {
      if (device === null) return true;
      if (f.device.id == device?.id) {
        return true;
      }
    });
  });

  const exceptions = useSelector((state: FullAppState) => {
    return state.exceptions.items.filter((t) => {
      if (device === null) return true;
      return t.device === device?.id;
    });
  });

  const tripCards: ICardItem[] = useMemo(() => {
    const cards: ICardItem[] = [];

    const cutTrips = trips.slice(0, 5);

    cutTrips.forEach((t) => {
      cards.push({
        title: `${device?.name}`,
        typeName: 2,
        activeFrom: t.startTime,
        activeTo: t.endTime,
        callToAction: "/map?trip=" + t?.id,
        distance: t.distance.toFixed(0).toString(),
        id: "",
        Miles: [],
      });
    });

    return cards;
  }, [trips]);

  useEffect(() => {
    if (!device) {
      return;
    }

    PageView("Vehicle", { deviceId: device?.id });
    const fromDate = DateUtil.utc().add(-1, "month");
    const toDate = DateUtil.utc();
    if (exceptions.length == 0) {
      dispatch(
        loadExceptions(fromDate.toISOString(), toDate.toISOString(), 500)
      );
    }

    if (trips.length == 0) {
      dispatch(
        loadTrips(fromDate.toISOString(), toDate.toISOString(), device?.id, 500)
      );
    }

    if (faultData.length == 0) {
      dispatch(
        loadFaultData(fromDate.toISOString(), toDate.toISOString(), 500)
      );
    }
  }, [device, dispatch]);

  useEffect(() => {
    //Accessibility Added manually
    const heading = document.querySelectorAll(
      "[role='heading']"
    ) as NodeListOf<Element>;
    let i = 2;
    heading.forEach((item) => {
      item.setAttribute("aria-level", i.toString());
      i++;
    });
  }, []);

  document.title = "FairMile® | Vehicle Data";

  const faultCards: ICardItem[] = useMemo(() => {
    const cards: ICardItem[] = [];

    const cutFaults = [...faultData]
      .sort((a, b) => {
        if (a.dateTime > b.dateTime) {
          return -1;
        }

        return 1;
      })
      .slice(0, 5);

    cutFaults.forEach((f) => {
      cards.push({
        title: `${f.diagnostic.name}`,
        titleLinks: f.diagnostic.links,
        typeName: CardItemType.Fault,
        activeFrom: f.dateTime,
        activeTo: f.dateTime,
        // callToAction: `/map?fault=${f?.id}`,
        distance: "",
        id: "",
        Miles: [],
      });
    });

    return cards;
  }, [faultData]);

  const exceptionCards: ICardItem[] = useMemo(() => {
    const cards: ICardItem[] = [];

    const cutExceptions = [...exceptions]
      .sort((a, b) => {
        if (a.startTime > b.startTime) {
          return -1;
        }

        return 1;
      })
      .slice(0, 5);

    cutExceptions.forEach((exception) => {
      cards.push({
        title: exception.name,
        typeName: CardItemType.Exception,
        activeFrom: exception.startTime,
        activeTo: exception.endTime,
        callToAction: "/map?exception=" + exception?.id,
        distance: "",
        id: "",
        Miles: [],
      });
    });

    return cards;
  }, [exceptions]);

  const isMobile = useIsMobile();

  //   ? i18n.t("vehicle.info.exception")
  //   : i18n.t("vehicle.info.fault")}

  return (
    <Page
      title="Vehicle"
      showBack={true}
      showVehicleSelector={true}
      setDevice={setDevice}
      hideAllVehicles={true}
      defaultDevice={device || undefined}
    >
      <div tw="flex flex-col">
        <Notifications />
        <div css={isMobile ? tw`` : "mb-16"} style={{ maxWidth: "1036px" }}>
          <div tw="flex flex-wrap mb-4">
            <div css={isMobile ? tw`w-full mr-8` : ""} tw="">
              <SnapshotCard
                selectedDevice={device?.id}
                title="Vehicle Mileage"
                mileageReports={true}
                currentLocation={true}
                showAverage={false}
              />
            </div>

            {!isMobile && (
              <div id="map-card-container" tw="">
                <MapCard mapCss="small-map-area" />
              </div>
            )}

            <div css={isMobile ? tw`w-full pr-8` : tw``}>
              <ListCard
                ariaLabel="Trips"
                title="Trips"
                cards={tripCards}
                callToAction="/trips"
                leftColumnColor={isMobile ? "#0073cf" : undefined}
              ></ListCard>
            </div>

            <div tw="flex flex-col" css={isMobile ? tw`w-full pr-8` : tw``}>
              <ListCard
                infoText={i18n.t("vehicle.info.fault")}
                ariaLabel="Faults"
                title="Faults"
                cards={faultCards}
                callToAction="/faults"
                leftColumnColor={isMobile ? "#e01933" : undefined}
              ></ListCard>

              <div tw="">
                <ListCard
                  infoText={i18n.t("vehicle.info.exception")}
                  ariaLabel="Driving Alerts"
                  title="Driving Alerts"
                  cards={exceptionCards}
                  callToAction="/exceptions"
                  leftColumnColor={isMobile ? "#0073cf" : undefined}
                ></ListCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Vehicle;
