/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Action } from "redux";
import { DeviceCardState } from "../components/VehicleList/UnpluggedDevice";
import { Device, Exception } from "../geotab/helpers";
import { doLoadException } from "../store/exceptions/actions/exceptions";
import { DateUtil } from "../util/date";

export enum PolicyState {
    before,
    during,
    after
}

export type PlatformOverride = "web" | "ios" | "android";

export class DebugManager {
    public policyState?: PolicyState;
    public deviceCardState?: DeviceCardState;
    public periodMileage?: number;
    public devices?: Device[] = [];
    public showOnlyDebugDevices?: boolean;
    /** How many times should we look the devices list to test the layout */
    public loopDeviceNumber?: number;
    public moveDeviceInterval?: NodeJS.Timeout;
    public platformOverride?: PlatformOverride;

    setLoopDeviceNumber(val?: number): void {
    }

    changePolicyState(): void {
    }

    setDeviceCardState(): void {
    }

    setPeriodMileage(mileage?: number): void {
    }

    addDevice(device: Device): void {
    }

    removeDevice(device: Device): void {
    }

    moveDevices(value: boolean): void {
    }

    setShowOnlyDebugDevices(value: boolean): void {
    }

    setOverridePlatform(platform: PlatformOverride): void {
    }

    addNonTripException(dispatch: (action: Action) => void, exceptions: Exception[]): void {
    }
}

class RealDebugManager extends DebugManager {
    public policyState?: PolicyState;
    public deviceCardState?: DeviceCardState;
    public periodMileage?: number;
    public devices?: Device[] = [];
    public showOnlyDebugDevices?: boolean;
    /** How many times should we look the devices list to test the layout */
    public loopDeviceNumber?: number;
    public moveDeviceInterval?: NodeJS.Timeout;
    public platformOverride?: PlatformOverride;

    setLoopDeviceNumber(val?: number): void {
        this.loopDeviceNumber = val;
    }

    changePolicyState(): void {
        this.policyState = this.policyState == null ? 0 : ++this.policyState;
        if (this.policyState > 2) {
            this.policyState = 0;
        }
    }

    setDeviceCardState(): void {
        if (!this.deviceCardState) {
            this.deviceCardState = "shipped";
            return;
        }
        switch (this.deviceCardState) {
            case "shipped":
                this.deviceCardState = "installed";
                return;
            case "installed":
                this.deviceCardState = "paired";
                return;
            case "paired":
                this.deviceCardState = "stopcommunicating";
                return;
            case "stopcommunicating":
                this.deviceCardState = "troublepairing";
                return;
            case "troublepairing":
                this.deviceCardState = undefined;
                return;
        }
    }

    setPeriodMileage(mileage?: number): void {
        this.periodMileage = mileage;
    }

    addDevice(device: Device): void {
        this.devices?.push(device);
    }

    removeDevice(device: Device): void {
        this.devices = this.devices?.filter((d) => d.id != device.id);
    }

    moveDevices(value: boolean): void {
        if (this.moveDeviceInterval) {
            clearInterval(this.moveDeviceInterval);
            this.moveDeviceInterval = undefined;
        }

        if (!value || !this.devices || this.devices.length == 0) {
            return;
        }

        this.moveDeviceInterval = setInterval(() => {
            for (const d of this.devices || []) {
                const xDir = Math.random() > 0.5;
                const yDir = Math.random() > 0.5;

                const yRandom = Math.random() / 100 * (xDir ? 1 : -1);
                const xRandom = Math.random() / 100 * (yDir ? 1 : -1);

                d.position.x += yRandom;
                d.position.y += xRandom;
                d.positionUpdate = DateUtil.utc();
            }
        }, 2000);
    }

    setShowOnlyDebugDevices(value: boolean): void {
        this.showOnlyDebugDevices = value;
    }

    setOverridePlatform(platform: PlatformOverride): void {
        this.platformOverride = platform;
    }

    addNonTripException(dispatch: (action: Action) => void, exceptions: Exception[]): void {
        if (!exceptions || exceptions.length == 0) {
            return;
        }

        const exception = { ...exceptions[0] };
        exception.name = "Debug Exception";
        exception.startTime = DateUtil.utc().add(10, 'day').toISOString();
        exception.endTime = DateUtil.utc().add(11, 'day').toISOString();
        exception.id = Math.random().toString();

        dispatch(doLoadException(exception));
    }
}

export default (): DebugManager => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w = (window as unknown) as Record<string, any>;
    if (!w.debugManager) {
        if (process.env.NODE_ENV != "development") {
            const dm = new DebugManager();
            w.debugManager = dm;
        } else {
            const dm = new RealDebugManager();
            w.debugManager = dm;
        }
    }

    return w.debugManager;
};