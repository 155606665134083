import { GeotabCredentials } from "../../types/geotab/auth";
import { API } from 'aws-amplify';
import geotabHelper from "../../geotab";
import { DateUtil } from "../../util/date";

type CredentialResult = { result: GeotabCredentials };
type SavedCredentials = { email: string, credentials: GeotabCredentials, time: string | null | undefined };

const credentialKey = "geotabCredentials";
export const fetchGeotabCredentials = async (email: string): Promise<GeotabCredentials> => {
    const credResult: CredentialResult = (await API.get("AppAuthEndpoint", "/geotab/authenticate-saml", {}));

    if (!credResult?.result?.credentials) {
        throw new Error("Unable to parse authentication result");
    }

    if (credResult && credResult.result && credResult.result.credentials && credResult.result.credentials.database) {
        localStorage.setItem(credentialKey, JSON.stringify({ email, credentials: credResult.result, time: DateUtil.utc().toISOString() }));
    } else {
        throw new Error("Failed to connect to Geotab");
    }

    await geotabHelper.connect(credResult.result);

    return credResult.result;
};

export const clearLocalCredentials = (): void => {
    localStorage.removeItem(credentialKey);
};

export const fetchLocalCredentials = async (email: string): Promise<GeotabCredentials | null> => {
    const localCredentials = localStorage.getItem(credentialKey);

    if (localCredentials) {
        const creds: SavedCredentials | null = JSON.parse(localCredentials);

        if (creds && creds.time) {
            const savedTime = DateUtil.utc(creds.time);
            if (DateUtil.utc().diff(savedTime, 'hours') > 20) {
                return null;
            }
        } else if (creds && !creds.time) {
            return null;
        }

        const credCheck = creds != null && creds.email == email && creds.credentials?.credentials?.database != null;
        if (!credCheck) {
            return null;
        }

        if (!creds?.credentials) {
            return null;
        }

        await geotabHelper.connect(creds.credentials);
        return creds.credentials;
    }

    return null;
};