import "./TabBar.css";
import React, { useEffect, useState } from "react";
import "../../App.css";
import tw from "twin.macro";
import { IonIcon } from "@ionic/react";
import { ellipsisHorizontal, home, statsChart } from "ionicons/icons";
import { useHistory, useLocation } from "react-router";

const TabBar: React.FC = () => {
  const tabbarItem = tw`flex flex-col items-center justify-center flex-grow cursor-pointer`;
  const selectedTabbarItem = tw`text-farmersblue`;
  const menuIcon = tw`w-7 h-7`;
  const location = useLocation();
  const history = useHistory();
  const [lastMain, setLastMain] = useState<string | null>(null);

  const locDashboard = location.pathname.indexOf("dashboard") >= 0;
  const locInsights = location.pathname.indexOf("insights") >= 0;
  const locVehicles = location.pathname.indexOf("vehicles") >= 0;
  const locMore = location.pathname.indexOf("more") >= 0;

  useEffect(() => {
    if (locDashboard) {
      setLastMain("dashboard");
    } else if (locInsights) {
      setLastMain("insights");
    } else if (locVehicles) {
      setLastMain("vehicles");
    } else if (locMore) {
      setLastMain("more");
    }
  }, [locDashboard, locInsights, locVehicles, locMore]);

  const dashboard = locDashboard || lastMain == "dashboard";
  const insights = locInsights || lastMain == "insights";
  const vehicles = locVehicles || lastMain == "vehicles";
  const more = locMore || lastMain == "more";

  const navigate = (nav: string) => {
    history.push(nav);
  };

  return (
    <div role="navigation" aria-label="App Tab Bar">
      <div tw="h-16 border-t bg-white flex px-8 items-center justify-center flex-shrink-0 text-headinggray">
        <button
          css={[tabbarItem, dashboard ? selectedTabbarItem : ""]}
          onClick={(e) => {
            e.stopPropagation();
            navigate("/dashboard");
          }}
        >
          <IonIcon
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={menuIcon}
            
            //   src="./assets/appicons/business-icon.svg"
            icon={home}
            // aria-label="Company overview"
            aria-hidden={true}
          ></IonIcon>
          <span className="tabbar-label">Dashboard</span>
        </button>

        <button
          css={[tabbarItem, insights ? selectedTabbarItem : ""]}
          onClick={(e) => {
            e.stopPropagation();
            navigate("/insights");
          }}
        >
          <IonIcon
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={menuIcon}
            icon={statsChart}
            // aria-label="Company overview"
            aria-hidden={true}
          ></IonIcon>
          <span className="tabbar-label">Insights</span>
        </button>

        <button
          css={[tabbarItem, vehicles ? selectedTabbarItem : ""]}
          onClick={(e) => {
            e.stopPropagation();
            navigate("/vehicles");
          }}
        >
          <IonIcon
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={menuIcon}
            src="/assets/truck.svg"
            // aria-label="Vehicle and devices"
          ></IonIcon>
          <span className="tabbar-label">Vehicles</span>
        </button>

        <button
          css={[tabbarItem, more ? selectedTabbarItem : ""]}
          onClick={(e) => {
            e.stopPropagation();
            navigate("/more");
          }}
        >
          <IonIcon
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={menuIcon}
            icon={ellipsisHorizontal}
            aria-label="More Icon"
          />
          <span className="tabbar-label">More</span>
        </button>
      </div>
    </div>
  );
};

export default TabBar;
