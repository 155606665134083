// AF
let initialized = false;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function () {
    if (!initialized) {
        initialized = true;
        return {
            region: "us-east-1",
            userPoolId: "us-east-1_Fv8ARXV8Z",
            userPoolWebClientId: "6j0cnq4u8rvefh5m15346l221v",
            mandatorySignIn: true,
            identityPoolId: "us-east-1:9535231e-a40c-4c35-996e-dee43853235a"
        };

    }
}
