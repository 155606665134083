import { AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { CLEAR_STORE } from "../../login/actions/login";
import { HistoricMileageResponse, MileageRecord } from "../../../api/types/mileage";
import dayjs from 'dayjs';
import { API } from "aws-amplify";
import { getCurrentOdometer } from "../../../geotab/helpers";
import { FullAppState } from "../../state";
import { DateUtil } from "../../../util/date";

export const LOAD_HISTORIC_MILEAGE = "LOAD_HISTORIC_MILEAGE";
export const LOAD_CURRENT_MILEAGE = "LOAD_CURRENT_MILEAGE";
export const SET_HISTORIC_MILEAGE = "SET_HISTORIC_MILEAGE";
export const SET_CURRENT_MILEAGE = "SET_CURRENT_MILEAGE";

interface ILoadHistoricMileage {
    type: typeof LOAD_HISTORIC_MILEAGE | typeof CLEAR_STORE;
}

interface ILoadCurrentMileage {
    type: typeof LOAD_CURRENT_MILEAGE | typeof CLEAR_STORE;
}

interface ISetHistoricMileage {
    type: typeof SET_HISTORIC_MILEAGE;
    deviceId: string;
    historicMileageResponse: HistoricMileageResponse;
}

interface ISetCurrentMileage {
    type: typeof SET_CURRENT_MILEAGE;
    deviceId: string;
    mileageRecord: MileageRecord;
}

export type LoadMileageType = ILoadHistoricMileage | ISetHistoricMileage | ISetCurrentMileage | ILoadHistoricMileage;

export function setHistoricMileage(id: string, historicMileageResponse: HistoricMileageResponse): ISetHistoricMileage {
    return { type: SET_HISTORIC_MILEAGE, deviceId: id, historicMileageResponse };
}

export function setCurrentMileage(id: string, mileageRecord: MileageRecord): ISetCurrentMileage {
    return { type: SET_CURRENT_MILEAGE, deviceId: id, mileageRecord };
}

//Gets an object that has keys of the month YYYY-MM and the mileage records associated with them
export async function getHistoricMileage(id: string, policyNumber: string, fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs): Promise<HistoricMileageResponse> {
    try {
        if (!policyNumber) {
            return { historicMileage: {} } as HistoricMileageResponse;
        }
        const res = (await API.get(
            "AppEndpoint",
            `/mileage_boundary/${id}?startDate=${fromDate}&endDate=${toDate}&policyNumber=${policyNumber}&unit=miles`,
            {}
        )) as HistoricMileageResponse;

        if (!res) {
            return { historicMileage: {} } as HistoricMileageResponse;
        }

        return res;
    } catch (err) {
        console.log(err);
        return { historicMileage: {} } as HistoricMileageResponse;
    }
}

export async function getMileage(id: string, policyNumber?: string): Promise<MileageRecord> {
    if (!policyNumber) {
        return {} as MileageRecord;
    }

    const res = (await API.get(
        "AppEndpoint",
        `/mileage/${id}?policyNumber=${policyNumber}&unit=miles`,
        {}
    )) as MileageRecord;
    if (!res) {
        return {} as MileageRecord;
    }

    return res;
}

export function loadCurrentMileage(deviceId: string, policyNumber: string): ThunkAction<
    Promise<void | ILoadCurrentMileage>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async (dispatch: Dispatch<ISetCurrentMileage>, getState: () => FullAppState) => {
        try {
            const state = getState();
            const value = state.mileage.lastCurrentFetch;
            const now = DateUtil.utc();

            if (now.diff(value, 'seconds') < 30) {
                return;
            }
            const mileage = await getMileage(deviceId, policyNumber);
            dispatch(setCurrentMileage(deviceId, mileage));
        } catch (err) {
            console.log(err);
        }
    };
}

export function loadCurrentGeotab(existingRecord: MileageRecord): ThunkAction<
    Promise<void | ILoadCurrentMileage>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async (dispatch: Dispatch<ISetCurrentMileage | ISetHistoricMileage>) => {
        try {
            const mileage = await getCurrentOdometer(existingRecord);
            dispatch(setCurrentMileage(existingRecord.deviceId, mileage));
        } catch (err) {
            console.log(err);
        }
    };
}

export function loadHistoricMileage(deviceId: string, policyNumber: string, fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs): ThunkAction<
    Promise<void | ILoadHistoricMileage>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async (dispatch: Dispatch<ISetHistoricMileage>, getState: () => FullAppState) => {
        try {
            const state = getState();
            const policy = state.policy.policy;
            const effectiveDate = dayjs(policy?.PolicyEffectiveDate);
            const mileage = await getHistoricMileage(deviceId, policyNumber, effectiveDate, toDate);
            dispatch(setHistoricMileage(deviceId, mileage));
        } catch (err) {
            console.log(err);
        }
    };
}