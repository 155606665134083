import React from "react";
import "./DesktopHeader.css";

import "twin.macro";

const DesktopHeader: React.FC = () => {
  return (
    <header tw="bg-white shadow-md z-10">
      <h1 aria-label="FairMile Customer Portal"></h1>
      <div tw="flex justify-center" aria-hidden={true}>
        <img
          src="assets/farmers-logo.png"
          className="desktop-logo"
          alt="Farmers Logo"
        ></img>
      </div>
    </header>
  );
};
export default DesktopHeader;
