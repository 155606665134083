import { Auth } from "aws-amplify";
import { EndpointDefinition } from "../Amplify";

export default (): EndpointDefinition => {
    return {
        endpoints: [
            {
                name: "AppEndpoint",
                endpoint: "https://1m3qxlzxe2.execute-api.us-east-1.amazonaws.com/prod",
                custom_header: async (): Promise<{ Authorization: string }> => {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    };
                },
            },
            {
                name: "AppAuthEndpoint",
                endpoint: "https://1m3qxlzxe2.execute-api.us-east-1.amazonaws.com/prod",
                custom_header: async (): Promise<{ Authorization: string }> => {
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`,
                    };
                },
            },
        ],
    };
};
