import { AnalyticsConfig } from "../Amplify";

const analyticsConfigure: () => AnalyticsConfig = () => {
    return {
        disabled: false,
        AWSPinpoint: {
            // OPTIONAL -  Amazon Pinpoint App Client ID
            appId: 'd1ef857dd3984656a9446008f798fe76',
            // OPTIONAL -  Amazon service region
            region: 'us-east-1',
        }
    };
};

export default analyticsConfigure;