import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchGeotabCredentials, fetchLocalCredentials } from "../../../actions/geotab/authenticate";
import { LoadingStatus } from "../../../types/Loading";
import { IGeotabAuthAction, GEOTAB_AUTHENTICATION_ACTION, LOAD_GEOTAB_CREDENTIALS_ACTION } from "./types";

export const authenticateGeotab = (email: string):
    ThunkAction<void, Record<string, unknown>, Record<string, unknown>, AnyAction> => async (
        dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, IGeotabAuthAction>
    ) => {

        try {
            dispatch({
                type: LOAD_GEOTAB_CREDENTIALS_ACTION,
                LoadingStatus: LoadingStatus.LOADING
            });

            let credentials = await fetchLocalCredentials(email);

            if (!credentials) {
                credentials = await fetchGeotabCredentials(email);
            }

            console.log("G AUTH Success");

            dispatch({
                type: GEOTAB_AUTHENTICATION_ACTION,
                geotabCredentials: credentials,
                LoadingStatus: LoadingStatus.SUCCESS,
                isAuthenticated: true
            });
        }
        catch (err) {
            dispatch({
                type: LOAD_GEOTAB_CREDENTIALS_ACTION,
                LoadingStatus: LoadingStatus.FAILURE,
                isAuthenticated: false
            });
            console.log('Geotab Auth Error');
            console.log(err);
        }
    };