import { Plugins } from "@capacitor/core";
import { v4 as uuidv4 } from "uuid";
const { Storage } = Plugins;

let uuid_install: string;

export const getUsername = async (): Promise<string | null> => {
    const value = await Storage.get({ key: "stored_username" });
    return value.value;
};

export const setUsername = async (userName: string): Promise<void> => {
    await Storage.set({ key: "stored_username", value: userName });
};

export const removeUsername = async (): Promise<void> => {
    await Storage.remove({ key: "stored_username" });
};

export const getTermsAccepted = async (policyNumber: string): Promise<boolean> => {
    const value = await Storage.get({ key: "terms_accepted" });

    if (value?.value != null) {
        try {
            const arr = JSON.parse(value.value);
            if (Array.isArray(arr)) {
                const index = arr.findIndex(v => {
                    if (v == policyNumber) {
                        return true;
                    }
                });
                if (index >= 0) {
                    return true;
                }
            }
        } catch (err) {
            return false;
        }
    }

    return false;
};

export const removeTermsAccepted = async (policyNumber: string): Promise<void> => {
    const termsAccepted = await Storage.get({ key: "terms_accepted" });
    if (!termsAccepted?.value) {
        return;
    }

    let obj = JSON.parse(termsAccepted.value);
    if (obj && Array.isArray(obj)) {
        let index = obj.findIndex((v: string) => {
            return v == policyNumber;
        });

        while (index > 0) {
            obj = obj.splice(index, 1);

            index = obj.findIndex((v: string) => {
                return v == policyNumber;
            });
        }

        await Storage.set({ key: "terms_accepted", value: JSON.stringify(obj) });
    } else {
        await Storage.remove({ key: "terms_accepted" });
    }
};

export const setTermsAccepted = async (policyNumber: string): Promise<void> => {
    const termsAccepted = await Storage.get({ key: "terms_accepted" });
    if (termsAccepted?.value != null) {
        try {
            const obj = JSON.parse(termsAccepted.value);
            if (Array.isArray(obj)) {
                obj.push(policyNumber);

                await Storage.set({
                    key: "terms_accepted",
                    value: JSON.stringify(obj)
                });
            } else {
                await Storage.set({
                    key: "terms_accepted",
                    value: JSON.stringify([policyNumber])
                });
            }
        }
        catch (err) {
            await Storage.set({
                key: "terms_accepted",
                value: JSON.stringify([policyNumber])
            });
        }
    } else {
        await Storage.set({
            key: "terms_accepted",
            value: JSON.stringify([policyNumber])
        });
    }
};

export const getUuid = async (): Promise<string | null> => {
    if (uuid_install !== null && uuid_install !== undefined) {
        return uuid_install;
    } else {
        const value = await Storage.get({ key: "uuid_install" });
        if (value && value.value && value.value.length > 0) {
            uuid_install = value.value;
        }
        return value.value;
    }
};

export const setUuid = async (): Promise<void> => {
    await Storage.set({
        key: "uuid_install",
        value: uuidv4(),
    });
};

export const getMileageRecord = async (): Promise<string | null> => {
    const value = await Storage.get({ key: "policy_record" });
    return value.value;
};

export const setMileageRecord = async (
    value: string
): Promise<void> => {
    await Storage.set({
        key: "policy_record",
        value: value,
    });
};

export const removeMileageRecord = async (): Promise<void> => {
    await Storage.remove({ key: "policy_record" });
};


export interface NotificationItem {
    policyNumber: string,
    token: string
}
export const getNotificationTokenRegistered = async (): Promise<NotificationItem | null> => {
    const val = (await Storage.get({ key: "notification_token_registered" }))?.value;
    if (val) {
        try {
            const item = JSON.parse(val);
            if (item.policyNumber && item.token) {
                return item as NotificationItem;
            }

            return null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    return null;
};

export const setNotificationTokenRegistered = async (
    policyNumber: string,
    token: string
): Promise<void> => {
    await Storage.set({
        key: "notification_token_registered",
        value: JSON.stringify({ token: token, policyNumber: policyNumber })
    });
};

export const getNotificationToken = async (): Promise<string | null> => {
    const token = await Storage.get({ key: "notification_token" });
    return token.value;
};

export const setNotificationToken = async (token: string): Promise<void> => {
    await Storage.set({ key: "notification_token", value: token });
};

getUuid().then((uuid) => {
    if (uuid !== null && uuid_install !== undefined) {
        uuid_install = uuid;
    }
});
