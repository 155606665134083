import {
  IonModal,
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import { logout } from "../../store/login/actions/login";
import { FullAppState } from "../../store/state";
import { DateUtil } from "../../util/date";

import "./Idle.css";

const reset = () => {
  window.location.replace("/logout");
};

const Idle: React.FC = () => {
  const dispatch = useDispatch();
  const [isTimedOut, setIsTimedOut] = useState<boolean>(false);

  const isLoggedIn = useSelector(
    (state: FullAppState) => state.login.isLoggedIn
  );

  useEffect(() => {
    if (window.location.href.indexOf("login") > 0) {
      setIsTimedOut(false);
    }
  }, [window.location.href]);

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onActive: () => {
      if (isLoggedIn) {
        console.log("Active", DateUtil.local().toISOString());
      }
    },
    onIdle: () => {
      console.log("Idle", DateUtil.local().toISOString());
      Auth.signOut();
      dispatch(logout());

      if (
        window.location.href.indexOf("login") < 0 &&
        location.href.indexOf("register") < 0 &&
        window.location.href.indexOf("forgotpassword") < 0
      ) {
        setIsTimedOut(true);
      }
    },
  });

  return (
    <IonModal
      isOpen={isTimedOut}
      onDidDismiss={reset}
      cssClass="idle-modal-class"
      backdropDismiss={false}
      showBackdrop={true}
      animated
    >
      <IonGrid>
        <IonItem style={{ marginTop: "10px", color: "#003087" }} lines="none">
          <IonText className="modal-text">{i18n.t("idle.title")}</IonText>
        </IonItem>
        <IonRow className="idle-buttons-row">
          <IonCol>
            <IonButton
              className="custom-button"
              onClick={(e) => {
                e.stopPropagation();
                reset();
              }}
            >
              {i18n.t("idle.button")}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default Idle;
