import {
  INotificationAction,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATION
} from "../actions/notificationsActions";
import INotificationsState from "../state/notificationsState";

const defaultState: INotificationsState = { notifications: [] };

export default function notificationsReducer(
  state = defaultState,
  action: INotificationAction
): INotificationsState {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return { notifications: [...state.notifications, action.notification!] };
    }
    case REMOVE_NOTIFICATION: {
      const notifications = state.notifications;
      notifications.splice(action.index!, 1);
      return {
        notifications: [...notifications],
      };
    }
    case CLEAR_NOTIFICATION: {
      let notifications = state.notifications;
      notifications = [];
      return {
        notifications: notifications,
      };
    }
    default:
      return state;
  }
}
