import React from "react";
import { VehicleRecordWithGeotab } from "../../entities/policy";
import "twin.macro";
import { IonIcon } from "@ionic/react";
import { pencil } from "ionicons/icons";

interface Props {
  item: VehicleRecordWithGeotab;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  editClicked?: () => void;
}
const VehicleCardHeader: React.FC<Props> = ({
  item,
  onClick,
  editClicked,
}: Props) => {
  const name = item.Device?.name || `${item.Make} ${item.Model} ${item.Year}`;
  return (
    <div tw="flex w-full border-b border-solid border-gray-200 flex pl-4 py-4 border-b flex-wrap text-left">
      <button tw="flex text-left" onClick={onClick} disabled={onClick == null}>
        <div aria-hidden={true}>
          <IonIcon
            src="./assets/truck.svg"
            tw="mr-4 mt-2 text-farmersblue"
            style={{ width: "60px", height: "26px" }}
            aria-hidden={true}
          ></IonIcon>
        </div>

        <div tw="flex flex-col">
          <h2 className="vehiclesCard-header">{name}</h2>
          <p tw="mt-1 max-w-2xl text-sm text-headinggray">{`VIN #${item.VIN}`}</p>
        </div>
      </button>

      <div tw="text-xl text-farmersblue mr-8 flex justify-end items-center flex-grow">
        <button
          onClick={(e) => {
            editClicked?.();
            e.stopPropagation();
          }}
          tw="flex z-40 w-9 h-9 items-center justify-center"
          aria-label="Edit Vehicle Name"
        >
          <div aria-hidden={true}>
            <IonIcon tw="" class="icon-btn" icon={pencil}></IonIcon>
          </div>
        </button>
      </div>
    </div>
  );
};

export default VehicleCardHeader;
