import React from "react";
import { IonIcon } from "@ionic/react";
import { location as mapPinIcon } from "ionicons/icons";
import { useHistory } from "react-router";

import "twin.macro";
import { DateUtil } from "../../util/date";
import { Links, renderDescription } from "../LinkRenderer/LinkRenderer";
import { CardItemType } from "../../store/cardmock/state/cardState";

interface Props {
  eventName: string;
  links?: Links;
  startTime: string;
  endTime: string;
  cardItemType: CardItemType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const FaultExceptionRow: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  return (
    <button
      onClick={props.onClick}
      tw="flex text-left items-center p-0 w-full h-full"
    >
      <div className="screen-reader-only">
        {props.cardItemType == CardItemType.Fault ? "Fault" : "Driving Alert"}
      </div>
      <dl tw="flex flex-col flex-grow">
        <dd tw="mt-1" style={{ fontSize: "0.9em" }} className="trip-data-label">
          {props.links
            ? renderDescription(history, props.eventName, props.links)
            : props.eventName}
        </dd>

        <dd
          tw="mt-1 flex-none"
          style={{ fontSize: "0.9em", color: "#585858" }}
          className="trip-data-label"
        >
          <div>
            at{" "}
            {DateUtil.WithSecondsFormat(DateUtil.utc(props.startTime).local())}
          </div>
        </dd>
      </dl>

      <div
        hidden={props.cardItemType == CardItemType.Fault}
        tw="flex justify-end flex-grow"
      >
        <IonIcon
          tw="mr-2 flex-none text-highlightblue text-xl"
          icon={mapPinIcon}
          ariaLabel="Current Location"
        />
      </div>
    </button>
  );
};

export default FaultExceptionRow;
