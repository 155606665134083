import { IonRow, IonText, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";

import "./Dashboard.css";
import SnapshotCard from "../components/SnapshotCard/Snapshot3";
import DesktopNewsFeed from "../components/NewsFeed/DesktopNewsFeed";
import MobileNewsFeed from "../components/NewsFeed/MobileNewsFeed";
import { PageView } from "../telemetry/TelemetryManager";
import { FullAppState } from "../store/state";
import { useSelector } from "react-redux";
import "../App.css";
import dayjs from "dayjs";
//import DesktopHeader from "../components/DesktopHeader/DesktopHeader";
// Leaflet.Icon.Default.imagePath =
// 	"//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/";
import tw from "twin.macro";
import Page from "./Page";
import { useIsMobile } from "../hooks/useIsMobile";

const Dashboard: React.FC<RouteComponentProps<{ name: string }>> = () => {
  const history = useHistory();
  const companyName = useSelector(
    (state: FullAppState) => state.policy.policy?.InsuredName
  );

  const policyNumber = useSelector(
    (state: FullAppState) => state.policy.policy?.PolicyNumber
  );

  const effectiveDate = useSelector((state: FullAppState) =>
    dayjs(state.policy.policy?.PolicyEffectiveDate)
  );

  const [pageViewthrown, setPageViewThrown] = useState(false);
  if (!pageViewthrown) {
    setPageViewThrown(true);
    PageView("Dashboard");
  }

  useEffect(() => {
    document.title = "FairMile® | Dashboard";
  }, []);

  useEffect(() => {
    if (policyNumber != null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).lastUrl) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        history.push((window as any).lastUrl);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (window as any).lastUrl;
      }
    }
  }, [policyNumber]);

  const isMobile = useIsMobile();

  return (
    <Page
      title="Company Overview"
      showBack={false}
      removeBottomMargin={isMobile}
    >
      <div tw="flex flex-col h-full" style={{ maxWidth: "1036px" }}>
        <div>
          {isMobile && (
            <div
              tw="flex pt-4 pl-8 pb-4"
              style={{ backgroundColor: "white" }}
              tabIndex={0}
              role="group"
            >
              <div className="screen-reader-only">Policy Overview Card</div>
              <IonImg className="store-icon" src="assets/icon-storefront.svg" />
              <div tw="flex flex-col ml-2">
                <h3 tw="font-medium text-farmersblue">{companyName || "--"}</h3>
                <IonText className="main-subtitle">Business Insurance</IonText>
                <IonText className="main-subtitle">{`Policy #${
                  policyNumber || "--"
                }`}</IonText>
                <IonRow
                  className="main-subtitle"
                  style={{ fontSize: "13px" }}
                >{`Policy Start Date: ${
                  effectiveDate ? effectiveDate.format("YYYY-MM-DD") : "--"
                }`}</IonRow>
              </div>
            </div>
          )}
        </div>

        {/* <Notifications /> */}
        <div tw="flex flex-wrap" css={isMobile ? tw`w-full pr-8` : ""}>
          <div tw="flex-grow">
            <SnapshotCard
              itemGap="mt-8"
              title="Overall Mileage"
              showAverage={true}
              seeMore={true}
            />
          </div>
          {!isMobile && (
            <div tw="flex-grow" aria-label="News Feed Card">
              <DesktopNewsFeed />
            </div>
          )}
        </div>

        <div>{isMobile && <MobileNewsFeed />}</div>
      </div>
    </Page>
  );
};

export default Dashboard;
