import React from "react";
import { Device } from "../../geotab/helpers";
import { DateUtil } from "../../util/date";

import "twin.macro";
import { useDebugManager } from "../../hooks/useDebugManager";

interface Props {
  device?: Device | null;
}

const CurrentLocationInfo: React.FC<Props> = (props: Props) => {
  const debugManager = useDebugManager();
  return (
    <section
      id="map-info"
      tw="mt-4"
      aria-label="Current Location Map"
      tabIndex={0}
    >
      <div tw="">
        {/* //   css={isMobile ? tw`ml-4` : tw`ml-8`}> */}
        <div
          //   css={isMobile ? tw`mt-8 ml-4` : tw`mt-8`}
          tw="uppercase text-farmersblue"
          className="h2"
        >
          Current Location
        </div>
        <div
          //   css={isMobile ? tw`ml-4 mt-2` : tw``}
          tw="uppercase text-headinggray mb-4"
        >
          {`Last Updated: ${
            (props.device &&
              props.device.positionUpdate &&
              DateUtil.WithSecondsFormat(
                props.device.positionUpdate.local()
              )) ||
            (debugManager.devices?.length &&
              DateUtil.WithSecondsFormat(DateUtil.local().add(-20, "day"))) ||
            "--"
          }`}
        </div>
      </div>
    </section>
  );
};

export default CurrentLocationInfo;
