import React, { useState, useEffect } from "react";

import "./Trips.css";
import { useSelector, useDispatch } from "react-redux";
import { FullAppState } from "../../store/state";
import { Device, Exception } from "../../geotab/helpers";
import { PageView } from "../../telemetry/TelemetryManager";
import dayjs from "dayjs";
import { DateUtil } from "../../util/date";

import Page from "../Page";
import Notifications from "../../components/Notifications/Notifications";
import DatePicker from "../../components/DatePicker";
import SystemCard from "../../components/SystemCard/SystemCard2";

import tw from "twin.macro";
import { loadExceptions } from "../../store/exceptions/actions/exceptions";
import { ICardItem } from "../../store/cardmock/state/cardState";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import useDateFilter from "../../hooks/useDateFilter";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useInitialStoredDates } from "../../hooks/useInitialStoredDates";
import { useStoreInitialDates } from "../../hooks/useStoreInitialDates";

const initialStartStorageKey = "ExceptionScreenInitialStartDate";
const initialEndStorageKey = "ExceptionScreenInitialEnd";
const Trips: React.FC = () => {
  const exceptions = useSelector(
    (state: FullAppState) => state.exceptions.items
  ) as Exception[];

  const [device, setDevice] = useState<Device | null>();

  const mapToCard = (list: Exception[]) => {
    const mapped = list.map<ICardItem>((x) => {
      return {
        typeName: 1,
        title: x.name,
        subTitle: x.name,
        activeFrom: x.startTime,
        activeTo: x.endTime,
        distance: "",
        id: x.id,
        callToAction: `/map?exception=${x.id}`,
      };
    });
    return mapped;
  };

  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    PageView("Exceptions", { deviceId: device?.id });
  }, [dispatch]);

  const [initialStart, initialEnd] = useInitialStoredDates(
    initialStartStorageKey,
    initialEndStorageKey
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dateFilter = (
    e: Exception,
    index: number,
    fromDate: dayjs.Dayjs,
    toDate: dayjs.Dayjs
  ) => {
    return (
      DateUtil.utc(e.startTime) >= fromDate &&
      DateUtil.utc(e.startTime) <= toDate &&
      (!device || device.id == e.device)
    );
  };

  const thunkDispatch = useDispatch<
    ThunkDispatch<FullAppState, never, AnyAction>
  >();

  const [
    filteredItems,
    fromDate,
    setFrom,
    toDate,
    setTo,
  ] = useDateFilter<Exception>(
    exceptions,
    dateFilter as (e: unknown, index: number) => boolean,
    mapToCard,
    setShowLoading,
    (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => {
      return thunkDispatch(
        loadExceptions(fromDate.toISOString(), toDate.toISOString())
      );
    },
    device,
    initialStart,
    initialEnd
  );

  useStoreInitialDates(
    initialStartStorageKey,
    fromDate,
    initialEndStorageKey,
    toDate
  );

  useEffect(() => {
    document.title = "FairMile® | Exceptions";
  }, []);

  const isMobile = useIsMobile();

  return (
    <Page
      showLoading={showLoading}
      loadingTimeout={() => setShowLoading(false)}
      defaultDevice={device || undefined}
      setDevice={setDevice}
      title="DRIVING ALERTS"
      showVehicleSelector={true}
      showBack={true}
    >
      <div tw="pb-4" style={{ maxWidth: "1036px" }}>
        <Notifications />
        <DatePicker
          style={!isMobile ? { paddingLeft: "28px", paddingRight: "28px" } : {}}
          from={fromDate}
          to={toDate}
          settingfrom={setFrom}
          settingto={setTo}
        />
        <div tw="flex flex-wrap">
          {filteredItems.map((item, index) => {
            return (
              <div css={isMobile ? tw`w-full mr-8` : ""} key={index}>
                <SystemCard card={item} />
              </div>
            );
          })}
        </div>

        {/* <TripList trips={filteredlist} /> */}
      </div>
    </Page>
  );
};

export default Trips;
