import {
  IonCard,
  IonContent,
  IonImg,
  IonItem,
  IonSpinner,
  IonText,
} from "@ionic/react";
import "./AppError.css";
import React, { useState } from "react";
import tw from "twin.macro";
import { useIsMobile } from "../../hooks/useIsMobile";
const AppError: React.FC = () => {
  const isMobile = useIsMobile();
  const [reloading, setReloading] = useState(false);

  return (
    <IonContent className="loading-bg">
      <div className="centered-flex-loading">
        <IonCard
          className={isMobile ? "apperror-card" : "apperror-card-desktop"}
        >
          <IonItem className="loading-img-item">
            <IonImg
              className="loading-image"
              src="assets/farmers-logo.png"
              alt="Splash Logo"
            />
          </IonItem>
          <IonItem
            lines="none"
            className="loading-img-item"
            style={{ marginTop: "7px" }}
          >
            <div className="centered-flex-loading" style={{ margin: "auto" }}>
              <div tw="flex flex-col items-center">
                <span tw="text-sm px-4 text-center">
                  There has been an issue accessing FairMile. Please wait and
                  retry.
                </span>
                <div
                  tw="my-4 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!reloading) {
                      setReloading(true);
                      setTimeout(() => window.location.reload(), 5000);
                    }
                  }}
                >
                  <div tw="flex flex-col">
                    {reloading && <IonSpinner />}

                    <IonText
                      hidden={reloading}
                      
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
                      css={
                        reloading
                          ? tw`text-headinggray`
                          : tw`text-highlightblue`
                      }
                      className="seeMore-desktop"
                    >
                      Retry
                    </IonText>
                  </div>
                </div>
              </div>
            </div>
          </IonItem>
        </IonCard>
      </div>
    </IonContent>
  );
};
export default AppError;
