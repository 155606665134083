import React, { useState, useEffect, useMemo } from "react";
import "./Mileage.css";
import { useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { useDevice } from "../../hooks/useDevice";
import {
  getEstimatedMileageForMonthForDevice,
  getTotalMileageStatsPerMonth,
} from "../../util/mileage";
import dayjs from "dayjs";
import { CardItemType } from "../../store/cardmock/state/cardState";
import { Device } from "../../geotab/helpers";

import Page from "../Page";
import MileageCard, {
  MileageCardInfo,
} from "../../components/MileageCard/MileageCard";
import {
  HistoricMileageRecord,
  HistoricMileageResponse,
} from "../../api/types/mileage";
import { useIsMobile } from "../../hooks/useIsMobile";
import NoDataMileageCard from "../../components/MileageCard/NoDataMileageCard";
import { useBeforePolicy } from "../../hooks/useBeforePolicy";

import tw from "twin.macro";

const getOdometer = (
  device: Device | null,
  historic: Record<string, HistoricMileageResponse>,
  monthKey: string
): HistoricMileageRecord | null => {
  if (device) {
    const deviceRecords = historic[device.id];
    if (!deviceRecords) {
      return null;
    }

    const month = deviceRecords.historicMileage[monthKey];
    return month;
  } else {
    let start = 0;
    let end = 0;
    let diagnostic: string | null = null;
    for (const r of Object.keys(historic)) {
      const veh = historic[r];
      if (veh.historicMileage[monthKey]) {
        const month = veh.historicMileage[monthKey];
        if (
          month.startRecord?.diagnosticId == "DiagnosticOdometerAdjustmentId"
        ) {
          diagnostic = "DiagnosticOdmeterAdjustmentId";
        }
        if (month) {
          start += month.startRecord?.value || 0;
          end += month.endRecord?.value || 0;
        }
      }
    }

    return {
      deviceId: "all",
      monthKey: monthKey,
      startRecord: {
        diagnosticId: diagnostic || "DiagnosticOdometerId",
        value: start,
      },
      endRecord: {
        diagnosticId: diagnostic || "DiagnosticOdometerId",
        value: end,
      },
      mileage: end - start,
    } as HistoricMileageRecord;
  }
};

interface Props {
  match?: {
    params?: Record<string, string>;
  };
}

const Mileage: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();
  const initialDevice = useDevice("id", props?.match?.params);
  const [device, setDevice] = useState<Device | null>(null);
  useEffect(() => {
    if (!device && initialDevice) {
      setDevice(initialDevice);
    }
  }, [initialDevice]);

  const historicMileageStore = useSelector(
    (state: FullAppState) => state.mileage.historicMileage
  );

  const policy = useSelector((state: FullAppState) => state.policy.policy);

  const policyVehicles = useSelector(
    (state: FullAppState) => state.policy.vehicles
  );

  const devices = useSelector((state: FullAppState) => state.vehicles.items);

  const currentMileage = useSelector((state: FullAppState) => {
    return state.mileage.currentMileage;
  });

  const cards = useMemo(
    (device?: Device | null) => {
      const mileageStats = getTotalMileageStatsPerMonth(
        historicMileageStore,
        currentMileage,
        device
      );

      if (!policy) {
        return [];
      }

      const keys = Object.keys(mileageStats).filter((k) => {
        const enrollmentDate = dayjs(policy.PolicyEffectiveDate).format(
          "YYYY-MM"
        );

        if (k < enrollmentDate) {
          return false;
        }

        return true;
      });

      let totalEstimated = 0;
      const toSum = device ? [device] : devices;
      for (let i = 0; i < toSum.length; i++) {
        const fetchedEstimate = getEstimatedMileageForMonthForDevice(
          policyVehicles,
          devices,
          toSum[i].id
        );

        if (fetchedEstimate) {
          totalEstimated += fetchedEstimate;
        }
      }

      const mapped = keys.map((monthKey) => {
        const odometer = getOdometer(
          device || null,
          historicMileageStore,
          monthKey
        );
        const monthlyTotal = mileageStats[monthKey]?.total ?? 0;
        const card = {
          typeName: CardItemType.Mileage,
          title: dayjs(monthKey).format("MMMM"),
          Miles: [
            totalEstimated,
            monthlyTotal || 0,
            totalEstimated - monthlyTotal,
          ],
          id: "4",
          sortKey: dayjs(monthKey).format("YYYY-MM"),
          startOdometer: (odometer && odometer.startRecord?.value) || 0,
          endOdometer: (odometer && odometer.endRecord?.value) || 0,
          mileageSource: odometer?.startRecord?.diagnosticId || "",
        } as MileageCardInfo;

        return card;
      });

      return mapped.sort((a, b) => {
        return a.sortKey.localeCompare(b.sortKey) * -1;
      });
    },
    [historicMileageStore, currentMileage, device]
  );

  useEffect(() => {
    document.title = "FairMile® | Mileage Reports";
  }, []);

  const beforePolicy = useBeforePolicy();

  return (
    <Page
      showBack={true}
      title="Mileage Reports"
      showVehicleSelector={true}
      setDevice={setDevice}
      defaultDevice={device || undefined}
    >
      <div
        tw="flex flex-wrap"
        css={isMobile ? tw`w-full pb-4` : ""}
        style={isMobile ? {} : { maxWidth: "1036px" }}
      >
        {cards.map((c, i) => {
          return (
            <div key={i} css={isMobile ? tw`w-full mr-8` : ""}>
              {!beforePolicy ? (
                <MileageCard key={i} cardInfo={c} />
              ) : (
                <NoDataMileageCard key={i} />
              )}
            </div>
          );
        })}
      </div>
    </Page>
  );
};

export default Mileage;
