import React from "react";
import Card from "../Card/Card";
import i18n from "../../i18n";
import BeforePolicy from "../BeforePolicy/BeforePolicy";
import "twin.macro";

export interface MileageCardInfo {
  title: string;
  Miles: number[];
  id: string;
  sortKey: string;
  startOdometer: number;
  endOdometer: number;
}

const NoDataMileageCard: React.FC = () => {
  return (
    <Card
      ariaLabel={"No mileage data yet"}
      title={"No Mileage Available"}
      infoText={i18n.t("mileage.info")}
      infoTextTitle={"Mileage Information"}
      subtitle="Mileage"
    >
      <div tw="mr-8 my-4">
        <BeforePolicy override={true} />
      </div>
    </Card>
  );
};

export default NoDataMileageCard;
