import { FaultData } from "../../../geotab/helpers";

const whiteList: Record<string, { title: string, body: string, links?: Record<string, { text: string, path: string }> }> = {
    ["DiagnosticGeneralVehicleWarningLightId"]: {
        title: "Vehicle Warning light is on",
        body: "You have a general vehicle warning light on. Please confirm if there is an issue, and consult your vehicles manual for more information"
    },
    ["DiagnosticDeviceHasBeenUnpluggedId"]: {
        title: "Device is unplugged",
        body: "Please re-install the device to avoid mileage penalties and see {billing_support} for information on penalties.",
        links: {
            billing_support: {
                text: "support",
                path: "/more?faq=3"
            }
        }
    },
    ["DiagnosticDeviceRestartedBecauseAllPowerWasRemovedId"]: {
        title: "Power was removed from the device",
        body: "Device is having trouble connecting to your vehicle. Please check if it is loose, and see {installation_support}.",
        links: {
            installation_support: {
                text: "installation support",
                path: "/more?faq=1"
            }
        }
    }
};

export default function (faultData?: FaultData[] | null): FaultData[] {
    if (!faultData) {
        return [];
    }

    return faultData.filter((f) => {
        const str = whiteList[f.diagnostic.id];
        if (str) {
            f.diagnostic.title = str.title;
            f.diagnostic.name = str.body;
            f.diagnostic.links = str.links;
            return true;
        }

        return false;
    });
}