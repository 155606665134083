import React, { useState, useEffect } from "react";

import "./Trips.css";
import { useSelector, useDispatch } from "react-redux";
import { FullAppState } from "../../store/state";
import { Device, FaultData } from "../../geotab/helpers";
import { PageView } from "../../telemetry/TelemetryManager";
import dayjs from "dayjs";
import { DateUtil } from "../../util/date";

import Page from "../Page";
import Notifications from "../../components/Notifications/Notifications";
import DatePicker from "../../components/DatePicker";
import SystemCard from "../../components/SystemCard/SystemCard2";

import tw from "twin.macro";
import { ICardItem } from "../../store/cardmock/state/cardState";
import { loadFaultData } from "../../store/faultData/actions/faultData";
import useDateFilter from "../../hooks/useDateFilter";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDevice } from "../../hooks/useDevice";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useStoreInitialDates } from "../../hooks/useStoreInitialDates";
import { useInitialStoredDates } from "../../hooks/useInitialStoredDates";

const initialStartStorageKey = "FaultsScreenInitialStartDate";
const initialEndStorageKey = "FaultsScreenInitialEnd";

const Faults: React.FC = () => {
  const faults = useSelector(
    (state: FullAppState) => state.faultData.items
  ) as FaultData[];

  const initialDevice = useDevice();
  const [device, setDevice] = useState<Device | null>();

  useEffect(() => {
    if (initialDevice && !device) {
      setDevice(initialDevice);
    }
  }, [initialDevice]);

  const mapToCard = (list: FaultData[]) => {
    const mapped = list.map<ICardItem>((x) => {
      return {
        typeName: 0,
        title: x.diagnostic.title,
        subTitle: x.diagnostic.name,
        subtitleLinks: x.diagnostic.links,
        activeFrom: x.dateTime,
        activeTo: x.dateTime,
        distance: "",
        id: x.id,
        callToAction: `/map?fault=${x.id}`,
      };
    });
    return mapped;
  };
  const [showLoading, setShowLoading] = useState(true);

  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dateFilter = (
    e: FaultData,
    index: number,
    fromDate: dayjs.Dayjs,
    toDate: dayjs.Dayjs
  ) => {
    return (
      DateUtil.utc(e.dateTime) >= fromDate &&
      DateUtil.utc(e.dateTime) <= toDate &&
      (!device || device.id == e.device.id)
    );
  };

  const thunkDispatch = useDispatch<
    ThunkDispatch<FullAppState, never, AnyAction>
  >();

  const [initialStart, initialEnd] = useInitialStoredDates(
    initialStartStorageKey,
    initialEndStorageKey
  );

  const [filteredList, fromDate, setFrom, toDate, setTo] = useDateFilter(
    faults,
    dateFilter,
    mapToCard,
    setShowLoading,
    (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => {
      return thunkDispatch(
        loadFaultData(fromDate.toISOString(), toDate.toISOString())
      );
    },
    device,
    initialStart,
    initialEnd
  );

  useStoreInitialDates(
    initialStartStorageKey,
    fromDate,
    initialEndStorageKey,
    toDate
  );

  useEffect(() => {
    PageView("Faults", { deviceId: device?.id });
    document.title = "FairMile® | Faults";
  }, [dispatch]);

  const isMobile = useIsMobile();

  return (
    <Page
      showLoading={showLoading}
      loadingTimeout={() => setShowLoading(false)}
      defaultDevice={device || undefined}
      setDevice={setDevice}
      title="Vehicle Faults"
      showVehicleSelector={true}
      showBack={true}
    >
      <div tw="pb-4" style={{ maxWidth: "1036px" }}>
        <Notifications />
        <DatePicker
          style={!isMobile ? { paddingLeft: "28px", paddingRight: "28px" } : {}}
          from={fromDate}
          to={toDate}
          settingfrom={setFrom}
          settingto={setTo}
        />
        <div tw="flex flex-wrap flex-grow">
          {filteredList.map((item, index) => {
            return (
              <div
                tw="h-full"
                css={isMobile ? tw`w-full mr-8` : ""}
                key={index}
              >
                <SystemCard card={item} />
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default Faults;
