export enum CardItemType {
    Fault,
    Exception,
    Trip,
    Mileage,
    Device,
}

export interface ICardItem {
    typeName: CardItemType;
    title?: string;
    titleLinks?: Record<string, { text: string, path: string }>
    subTitle?: string;
    subtitleLinks?: Record<string, { text: string, path: string }>
    activeFrom: string;
    activeTo: string;
    distance?: string;
    drivingDuration?: plugin.Duration;
    stopDuration?: plugin.Duration;
    Miles?: string[];
    callToAction?: string;
    id: string;
    startOdometer?: number | null
    endOdometer?: number | null
}

export default interface ICardState {
    items: ICardItem[];
}
