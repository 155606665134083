import React, { useEffect } from "react";
import "./VehiclesTabs.css";
import i18n from "../../i18n";
import "twin.macro";
import { useIsMobile } from "../../hooks/useIsMobile";

interface TabsProps {
  selectedTab: number;
  setSelectedTab: (from: number) => void;
}

const VehiclesTabs: React.FC<TabsProps> = (props: TabsProps) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    const search = location.search.substring(1);

    if (search.length == 0) {
      return;
    }

    const queryParameters: Record<string, unknown> = JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );

    let tab: string = queryParameters["tab"] as string;

    if (tab) {
      tab = tab.toLowerCase();
      if (tab == "devices") {
        props.setSelectedTab(1);
        return;
      }

      if (tab == "active") {
        props.setSelectedTab(0);
      }
    } else {
      props.setSelectedTab(0);
    }
  }, [location.search]);

  return (
    <div tw="flex justify-center w-full bg-white" style={{ height: "60px" }}>
      <div
        tw="grid grid-cols-2 grid-rows-1 bg-white"
        style={isMobile ? { width: "100%" } : { width: "60%" }}
      >
        <button
          tw="flex justify-center items-center"
          className={
            "tab-col " + (props.selectedTab === 0 ? "tab-col-active" : "")
          }
          role="tab"
          onClick={() => props.setSelectedTab(0)}
        >
          <span>{i18n.t("vehicle.tabs.active")}</span>
        </button>
        <button
          tw="flex justify-center items-center"
          className={
            "tab-col " + (props.selectedTab === 1 ? "tab-col-active" : "")
          }
          role="tab"
          onClick={() => props.setSelectedTab(1)}
        >
          <span>{i18n.t("vehicle.tabs.notactive")}</span>
        </button>
      </div>
    </div>
  );
};

export default VehiclesTabs;
