import React, { useState } from "react";
import {
  IonButton,
  IonGrid,
  IonImg,
  IonItem,
  IonRow,
  IonText,
  IonRouterLink,
  IonCard,
  IonCol,
  isPlatform,
} from "@ionic/react";
import "./Register.css";
import { useHistory } from "react-router";
import i18n from "../../../i18n";
import CustomInput from "../../CustomInput";
import LoginError from "../Error";
import { LoginErrorType } from "../../../store/login/reducers/loginReducer";
import PasswordPolicy from "../PasswordPolicy";
import "twin.macro";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useIsIOS } from "../../../hooks/useIsIOS";

interface Props {
  register: (email: string, password: string) => void;
  error: string | null | undefined;
  isRegistering: boolean;
}

// eslint-disable-next-line react/prop-types
export const MainRegister: React.FC<Props> = ({
  register,
  error,
  isRegistering,
}: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const history = useHistory();
  const [internalError, setInternalError] = useState<string | null>(null);

  const validate = (
    email: string,
    password: string,
    confirmPassword: string
  ) => {
    if (
      email != null &&
      password != null &&
      confirmPassword != null &&
      password === confirmPassword
    ) {
      setInternalError(null);
      return true;
    }

    if (password != confirmPassword) {
      setInternalError(LoginErrorType.PasswordNoMatch);
    }

    return false;
  };

  const isMobile = useIsMobile();
  const isIOS = useIsIOS();

  return (
    <div tw="h-full w-full overflow-y-auto" role="main">
      <IonGrid className="login-grid">
        {isMobile && (
          <IonRow>
            <IonCol className="p-0 login-bg">
              <div className="img-container">
                <IonImg
                  src="assets/loginRect.svg"
                  className="login-rectangle"
                  alt="Splash Logo"
                ></IonImg>
                <IonImg
                  src="assets/loginbranding.svg"
                  className="top-img"
                  alt="Logo branding"
                ></IonImg>
                <IonText className="welcome-text" role="heading">
                  Welcome to
                </IonText>
                <IonText className="FairMile-text" role="heading">
                  FairMile<span className="header-span">®</span>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        )}

        <IonRow>
          <IonCol style={{ display: "flex" }}>
            <IonCard className={isMobile ? "login-card" : "login-card-desktop"}>
              <IonItem className="mt-9 custom-input-item mb-ios" lines="none">
                <CustomInput
                  label="Email address"
                  id="email"
                  value={email}
                  autoCapitalize={false}
                  disabled={false}
                  type="text"
                  onChange={(e) =>
                    setEmail((e.target as HTMLInputElement).value)
                  }
                />
              </IonItem>
              <IonItem
                className="mt-9 custom-input-item"
                lines="none"
                style={{ marginBottom: "20px" }}
              >
                <CustomInput
                  label="Password"
                  id="password"
                  value={password}
                  disabled={false}
                  type="password"
                  onChange={(e) => {
                    const str = (e.target as HTMLInputElement).value;
                    setPassword(str);
                  }}
                />
              </IonItem>
              <IonItem className="mt-9 custom-input-item" lines="none">
                <CustomInput
                  label="Confirm Password"
                  id="confirm"
                  value={confirmation}
                  disabled={false}
                  type="password"
                  onChange={(e) =>
                    setConfirmation((e.target as HTMLInputElement).value)
                  }
                />
              </IonItem>

              <PasswordPolicy password={password} confirmation={confirmation} />
              <LoginError error={internalError || error}></LoginError>

              {/* <IonItem lines="none" className="custom-input-item">
                <IonButton
                  tw="w-full mt-4"
                  class="custom-button"
                  className={isIOS ? "" : "signin-btn-color"}
                  disabled={!all || email == null}
                  onClick={() => {
                    if (validate(email, password, confirmation)) {
                      register(email, password);
                    } else {
                      console.log("Invalid Credentials");
                    }
                  }}
                >
                  {i18n.t("button.submit")}
                </IonButton>
              </IonItem> */}

              <div
                tw="flex w-full mx-4"
                className={
                  "no-background-desktop" +
                  "desktop-login-btn " +
                  (isPlatform("ipad") ? "ml-90" : "")
                }
              >
                <IonButton
                  disabled={isRegistering}
                  tw="w-full mt-4"
                  style={{ width: "156px" }}
                  className={
                    isIOS ? "custom-button" : "custom-button signin-btn-color"
                  }
                  onClick={() => {
                    if (validate(email, password, confirmation)) {
                      register(email, password);
                    } else {
                      console.log("Invalid Credentials");
                    }
                  }}
                >
                  {/* {i18n.t("button.signin")} */}
                  Register
                </IonButton>
              </div>

              <IonItem lines="none" className="link-item">
                <IonRouterLink
                  href="#"
                  className="link"
                  onClick={(e) => {
                    e.preventDefault();
                    history.replace("/login");
                  }}
                >
                  {i18n.t("button.backtosign")}
                </IonRouterLink>
              </IonItem>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};
