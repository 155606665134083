import Analytics from '@aws-amplify/analytics';
import { getPlatform } from '../util/platform';
import { AppVersion } from '@ionic-native/app-version';

interface PinpointEndpoint {
    address?: string;
    userId: string;
    attributes: Record<string, string>;
    location: {
        country: string
        region?: string
    };
    demographic: {
        platform: "android" | "ios" | "web",
        appVersion: string
    },
    optOut: "NONE" | "ALL",
    channelType?: "GCM" | "APNS"
}

export const updateEndpoint = async (deviceToken: string, policyNumber: string): Promise<unknown> => {
    const platform = getPlatform();
    if (!deviceToken || !policyNumber) {
        console.log("Missing information to create endpoint");
        return;
    }

    let version = "0.0.1";
    try {
        version = await AppVersion.getVersionNumber();
    }
    catch (e) {
        version = "0.0.1";
        console.log(e);
    }

    const params = {
        userId: policyNumber,
        attributes: {
        },
        location: {
            country: 'US' // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
        },
        demographic: {
            platform: platform, // The platform of the endpoint device, such as iOS or Android.
            appVersion: version
        },
        optOut: "NONE"
    } as PinpointEndpoint;

    if (platform == 'android' || platform == 'ios') {
        params.address = deviceToken;
        params.channelType = platform == "android" ? "GCM" : "APNS";
    }

    console.log(params);

    await Analytics.updateEndpoint(params);
    ((window as unknown) as Record<string, boolean>).endpointCreated = true;
};