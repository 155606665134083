import React, { useState, useRef, useEffect } from "react";
import { IonSlide, IonSlides } from "@ionic/react";
import "./ForgotPassword.css";
import { Auth } from "aws-amplify";
import { MainForgotPassword } from "./MainForgotPassword";
import { SubmitForgotPassword } from "./SubmitForgotpassword";
import { useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { Redirect, useHistory } from "react-router";

export const ForgotPassword: React.FC = () => {
  useEffect(() => {
    document.title = "FairMile® | Forget Password";
  }, []);

  // const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);

  const history = useHistory();
  const [email, setEmail] = useState<string>();

  const forgetPassword: (email: string) => void = (email) => {
    // LoginEvent("ForgetPassword");
    Auth.forgotPassword(email)
      .then(() => {
        setError(null);
        setEmail(email);
        swipeTo(1);
      })
      .catch((err: { message: string } | string) => {
        if (typeof err == "object" && err.message) {
          setError(err.message);
        } else {
          setError(err as string);
        }
      });
  };

  const submitForgotPassword = (
    email: string,
    code: string,
    password: string
  ) => {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        history.push("/login");
      })
      .catch((err) => {
        if (err.message) {
          setError(err.message);
        } else {
          setError(err);
        }
      });
  };

  const slideRef = useRef(null);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const isLoggedIn = useSelector(
    (state: FullAppState) => state.login.isLoggedIn
  );
  const swipeTo = async (index: number) => {
    if (slideRef && slideRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const slides: any = slideRef.current;
      await slides.lockSwipeToNext(false);
      slides.slideTo(index);
      await slides.lockSwipeToNext(true);
      setSelectedPage(index);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  const slideOpts = {
    initialSlide: selectedPage,
    speed: 400,
    allowSlidePrev: false,
    allowSlideNext: false,
  };

  return (
    <IonSlides
      ref={slideRef}
      className="loginPage"
      pager={false}
      options={slideOpts}
    >
      <IonSlide>
        <MainForgotPassword forgetPassword={forgetPassword} error={error} />
      </IonSlide>
      <IonSlide>
        <SubmitForgotPassword
          error={error || undefined}
          setError={setError}
          email={email!}
          submitForgetPassword={submitForgotPassword}
        />
      </IonSlide>
    </IonSlides>
  );
};
