import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppState, Plugins } from "@capacitor/core";
const { App: CapApp } = Plugins;

const AppUrlListener: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    CapApp.addListener("appStateChange", (state: AppState) => {
      // state.isActive contains the active state
      console.log("App state changed. Is active?", state.isActive);
    });

    CapApp.addListener("appUrlOpen", function (data) {
      const slug = data.url.split("fairmile://app").pop();
      if (slug) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).lastUrl = slug;
        history.push(slug);
      }
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
