import { GeotabCredentials } from "../../../types/geotab/auth";
import { LoadingStatus } from "../../../types/Loading";
import IAction from "../../actions";

export interface IGeotabAuthAction extends IAction {
    geotabCredentials?: GeotabCredentials;
    LoadingStatus: LoadingStatus,
    isAuthenticated?: boolean
}

export const LOAD_GEOTAB_CREDENTIALS_ACTION = "LOAD_GEOTAB_CREDENTIALS";
export const GEOTAB_AUTHENTICATION_ACTION = "GEOTAB_AUTHENTICATION";