import React from "react";
import {
  INewsFeedItem,
  NewsFeedItemType,
} from "../../store/newsfeed/state/newsfeedState";
import { useHistory } from "react-router";
import { IonIcon } from "@ionic/react";
import {
  alertCircle,
  caretForward,
  chevronForwardOutline,
  documentText,
  informationCircle,
  warning,
} from "ionicons/icons";
import dayjs from "dayjs";
import { DateUtil } from "../../util/date";
import tw from "twin.macro";
import { useIsIOS } from "../../hooks/useIsIOS";

interface IProps {
  mobile?: boolean;
  item: INewsFeedItem;
  fullPage?: boolean;
  lastItem?: boolean;
}

const NewsfeedItem: React.FC<IProps> = ({
  mobile,
  item,
  fullPage,
  lastItem,
}: IProps) => {
  const history = useHistory();
  const dateFormat = "dddd MMM DD h:mm a";
  const isIOS = useIsIOS();

  const getLabelForItem = (item: INewsFeedItem) => {
    switch (item.type) {
      case NewsFeedItemType.Info: {
        return "Info";
      }
      case NewsFeedItemType.Warning: {
        return "Warning";
      }
      case NewsFeedItemType.Alert: {
        return "Alert";
      }
      case NewsFeedItemType.Report: {
        return "Report";
      }
    }
  };
  const getIconForItem = (item: INewsFeedItem) => {
    switch (item.type) {
      case NewsFeedItemType.Info: {
        return informationCircle;
      }
      case NewsFeedItemType.Warning: {
        return warning;
      }
      case NewsFeedItemType.Alert: {
        return alertCircle;
      }
      case NewsFeedItemType.Report: {
        return documentText;
      }
    }
  };
  const formatDate = (date: dayjs.Dayjs) => {
    return date.calendar(DateUtil.local(), {
      sameDay: "[Today at] h:mm a", // The same day ( Today at 2:30 AM )
      nextWeek: dateFormat, // The next week ( Sunday at 2:30 AM )
      lastDay: `[Yesterday] hh:mm a`, // The day before ( Yesterday at 2:30 AM )
      lastWeek: dateFormat, // Last week ( Last Monday at 2:30 AM )
      sameElse: dateFormat, // Everything else ( 7/10/2011 )
    });
  };

  const type = item.type;

  let color = "yellow";
  switch (type) {
    case NewsFeedItemType.Info:
      color = "blue";
      break;
    case NewsFeedItemType.Alert:
      color = "red";
      break;
    case NewsFeedItemType.Warning:
      color = "yellow";
      break;
    case NewsFeedItemType.Report:
      color = "grey";
      break;
  }

  return (
    <button
      role="listitem"
      aria-label={lastItem ? "News Feed Item - Last Item" : "News Feed Item"}
      tabIndex={0}
      tw="cursor-pointer flex items-center bg-white p-0 text-left w-full"
      css={mobile ? tw`pl-4` : fullPage ? tw`pl-4` : ""}
      onClick={() => {
        if (item.callToAction) {
          history.push(item.callToAction);
        }
      }}
    >
      <IonIcon
        tw="flex-none"
        icon={getIconForItem(item)}
        className={"newsfeed-icons " + color}
        ariaLabel={getLabelForItem(item)}
      />

      <div tw="my-4 mr-8 justify-start">
        <p className="custom-card-subtitle">
          {formatDate(dayjs(item.time).local())}
        </p>
        <p className="custom-card-title">{item.title}</p>
        {item.message && (
          <p className="custom-card-title red">{item.message}</p>
        )}
      </div>

      {mobile && (
        <div tw="flex pr-4 flex-grow items-stretch justify-end">
          <IonIcon
            tw="w-7 h-7 text-farmersblue"
            icon={isIOS ? chevronForwardOutline : caretForward}
          ></IonIcon>
        </div>
      )}
    </button>
  );
};

export default NewsfeedItem;
