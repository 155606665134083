import { getExceptions, getTrip, getTripByDeviceAndTime } from "./../../../geotab/helpers/index";
import { ThunkAction } from "redux-thunk";
import { Trip, getTripsData, getLogRecordForEntity } from "../../../geotab/helpers";
import { AnyAction } from "redux";
import { CLEAR_STORE } from "../../login/actions/login";
import { FullAppState } from "../../state";

export const LOAD_TRIPS = "load_trips";
export const LOAD_TRIP_DATA = "load_trip_data";
export const LOAD_SINGLE_TRIP = "load_single_trip";
export const FAILED_LOAD_TRIP = "failed_load_trip";

interface ITripsData {
    type: typeof LOAD_TRIPS
    trips: Trip[];
}

export type TripsActionType = ITripsData;

interface IFailedTripsLoadData {
    type: typeof FAILED_LOAD_TRIP
    tripId: string
    reason: string
}

export type FailedLoadActiontype = IFailedTripsLoadData;

interface ILoadTripData {
    type: typeof LOAD_TRIP_DATA | typeof CLEAR_STORE | typeof LOAD_SINGLE_TRIP;
    trip: Trip;
}
export type LoadTripActionType = ILoadTripData;

export function loadTrips(
    fromDate: string,
    toDate?: string | null,
    deviceId?: string | null,
    resultsLimit = 1000
): ThunkAction<Promise<void | ITripsData>, FullAppState, AnyAction, AnyAction> {
    return async function (dispatch) {
        try {
            const trips = await getTripsData(fromDate, toDate, deviceId, resultsLimit);
            dispatch(load(trips));
        } catch (err) {
            console.log("Error getting trips data");
        }
    };
}

export function loadTripsData(): ThunkAction<
    Promise<void | ITripsData>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch, state) {
        const trips: Trip[] = [];
        const tripsData = state().trips.items;
        const policy = state().policy.policy?.PolicyNumber;
        for (let i = 0; i < tripsData.length; i++) {
            const trip = tripsData[i];
            const points = await getLogRecordForEntity(trip);
            const exceptions = await getExceptions(
                36000,
                trip.startTime,
                trip.endTime,
                trip.device,
                policy
            );
            if (exceptions.length > 0) {
                for (let j = 0; j < exceptions.length; j++) {
                    const excPoints = await getLogRecordForEntity(exceptions[j]);
                    if (excPoints.length > 0) {
                        if (!trip.exceptions) {
                            trip.exceptions = [];
                        }
                        trip.exceptions.push({
                            ...exceptions[j],
                            points: excPoints,
                        });
                    }
                }
            }
            const tripObj = { ...trip, points };

            if (points.length > 0) {
                tripObj.startPoint = points[0];
                tripObj.stopPoint = points[points.length - 1];
            }
            trips.push(tripObj);
        }
        dispatch(load(trips));
    };
}

export function loadTripByDeviceAndTime(
    deviceId: string,
    tripStart: string,
    tripStop: string
): ThunkAction<Promise<void>, FullAppState, AnyAction, AnyAction> {
    return async function (dispatch) {
        const trip = await getTripByDeviceAndTime(deviceId, tripStart, tripStop);
        if (trip) {
            trip.points = [];
            dispatch({ type: LOAD_SINGLE_TRIP, trip: trip });
        } else {
            dispatch(failedLoadTrip(deviceId + tripStart + tripStop, "TRIP_NOT_FOUND"));
        }
    };
}

export function loadSingleTrip(
    tripId: string
): ThunkAction<Promise<void>, FullAppState, AnyAction, AnyAction> {
    return async function (dispatch) {
        const trip = await getTrip(tripId);
        if (trip) {
            trip.points = [];
            dispatch({ type: LOAD_SINGLE_TRIP, trip: trip });
        } else {
            dispatch(failedLoadTrip(tripId, "TRIP_NOT_FOUND"));
        }
    };
}

export function loadTripData(
    trip: Trip
): ThunkAction<Promise<void>, FullAppState, AnyAction, AnyAction> {
    return async function (dispatch, state) {
        const points = await getLogRecordForEntity(trip);
        const policy = state().policy.policy?.PolicyNumber;
        const exceptions = await getExceptions(
            36000,
            trip.startTime,
            trip.endTime,
            trip.device,
            policy
        );

        const rules = state().exceptions.rules;
        if (rules) {
            for (const e of exceptions) {
                if (e.ruleId) {
                    e.name = rules[e.ruleId]?.name || e.name;
                }
            }
        }

        trip.loadedExceptions = true;
        if (exceptions.length > 0) {
            for (let j = 0; j < exceptions.length; j++) {
                const excPoints = await getLogRecordForEntity(exceptions[j]);
                if (excPoints.length > 0) {
                    if (!trip.exceptions) {
                        trip.exceptions = [];
                    }
                    trip.exceptions.push({
                        ...exceptions[j],
                        points: excPoints,
                    });
                }
            }
        }
        console.log("Exceptions for the trip", trip.exceptions);
        dispatch(
            loadTrip({
                ...trip,
                points,
                startPoint: points[0],
                stopPoint: points[points.length - 1],
            })
        );
    };
}

function load(trips: Trip[]): ITripsData {
    return { type: LOAD_TRIPS, trips };
}

function loadTrip(trip: Trip): ILoadTripData {
    return { type: LOAD_TRIP_DATA, trip };
}

function failedLoadTrip(tripId: string, reason: string): IFailedTripsLoadData {
    return { type: FAILED_LOAD_TRIP, tripId, reason };
}
