import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonContent,
  IonRouterLink,
  IonImg,
  IonText,
  IonCard,
  IonCardContent,
  isPlatform,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { FullAppState } from "../../store/state";
import { dismissError, performLogin } from "../../store/login/actions/login";
import { getUsername, setUsername } from "../../system/StorageManager";
import { useHistory } from "react-router";
import i18n from "../../i18n";
import CustomInput from "../CustomInput";

import "./MainLogin.css";
import LoginError from "./Error";
import { useIsIOS } from "../../hooks/useIsIOS";
import { useIsMobile } from "../../hooks/useIsMobile";
import { LoginErrorType } from "../../store/login/reducers/loginReducer";

export const MainLogin: React.FC = () => {
  const [userName, setUserName] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");
  const loading = useSelector((state: FullAppState) => state.login.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [overrideError, setOverrideError] = useState<string | null>();

  useEffect(() => {
    const fetchStorageUserName = async (): Promise<void> => {
      if (userName !== null) {
        setUsername(userName);
      }

      const storedUserName = await getUsername();
      if (storedUserName !== null && storedUserName.length > 0) {
        setUserName(storedUserName);
      }
    };

    if (userName === null) {
      fetchStorageUserName();
    }
  }, [dispatch]);

  const doLogin = () => {
    setOverrideError(null);
    if (!userName || userName.length == 0) {
      dispatch(dismissError());
      setOverrideError(LoginErrorType.UsernameCannotBeEmpty);
      return;
    }

    if (!password || password.length == 0) {
      dispatch(dismissError());
      setOverrideError(LoginErrorType.PasswordCannotBeEmpty);
      return;
    }

    dispatch(performLogin(userName!, password!));
    setOverrideError(null);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      doLogin();
    }
  };

  const isIOS = useIsIOS();
  return (
    <IonContent tw="block w-full h-full" className="slidePage">
      {isMobile && (
        <IonGrid className="login-grid">
          <IonRow>
            <IonCol className="p-0 login-bg">
              <div className="img-container">
                <IonImg
                  src="assets/loginRect.svg"
                  className="login-rectangle"
                  alt="Background gradient"
                ></IonImg>
                <IonImg
                  src="assets/loginbranding.svg"
                  className="top-img"
                  alt="Logo branding"
                ></IonImg>
                <h1>
                  <IonText
                    className="welcome-text"
                    role="heading"
                    aria-required="false"
                  >
                    Welcome to
                  </IonText>
                  <IonText
                    aria-required="false"
                    className="FairMile-text"
                    role="heading"
                  >
                    FairMile<span className="header-span">®</span>
                  </IonText>
                </h1>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol style={{ display: "flex" }}>
              <IonCard className="login-card">
                <IonItem
                  className="custom-input-item mb-ios"
                  lines="none"
                  style={{ marginTop: "15px" }}
                >
                  <CustomInput
                    ariaLabel={"Email Address"}
                    label="Email address"
                    value={userName || ""}
                    disabled={loading}
                    autoCapitalize={false}
                    type="text"
                    id="email"
                    onChange={(e) => {
                      setOverrideError(null);
                      setUserName(e.target.value);
                    }}
                  />
                </IonItem>
                <IonItem className="custom-input-item" lines="none">
                  <CustomInput
                    ariaLabel={"Password"}
                    label="Password"
                    value={password}
                    disabled={loading}
                    type="password"
                    id="password"
                    onChange={(e) => {
                      setOverrideError(null);
                      setPassword(e.target.value!.trim());
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                  />
                </IonItem>

                <LoginError error={overrideError}></LoginError>

                <IonItem lines="none" className="mt-9 custom-input-item">
                  <IonButton
                    style={{ width: "100%" }}
                    className={
                      isIOS ? "custom-button" : "custom-button signin-btn-color"
                    }
                    disabled={loading}
                    onClick={() => doLogin()}
                  >
                    {i18n.t("button.signin")}
                  </IonButton>
                </IonItem>
                <IonItem
                  lines="none"
                  className="link-item"
                  style={{ marginBottom: "-15px" }}
                >
                  <IonRouterLink
                    href="#"
                    className="link"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({ pathname: "/forgotpassword" });
                    }}
                  >
                    {isIOS ? (
                      <IonText>{i18n.t("login.forget")}</IonText>
                    ) : (
                      <IonText>{i18n.t("login.androidforgot")}</IonText>
                    )}
                  </IonRouterLink>
                </IonItem>
                <IonItem lines="none" className="link-item">
                  <IonRouterLink
                    href="#"
                    className="link"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({ pathname: "/register" });
                    }}
                  >
                    {i18n.t("login.register")}
                  </IonRouterLink>
                </IonItem>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {!isMobile && (
        <IonGrid className="no-padding">
          <IonRow>
            <IonCol
              className="no-padding"
              style={{ marginTop: "48px", marginBottom: "20px" }}
            >
              <IonItem lines="none" className="no-background-desktop">
                <IonText
                  className={
                    "Welcome-desktop " + (isPlatform("ipad") ? "ml-95" : "")
                  }
                >
                  WELCOME TO FAIRMILE
                </IonText>
                <span className="sm-span-desktop">®</span>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="no-padding">
              <IonCard
                className={
                  "no-padding login-card-desktop " +
                  (isPlatform("ipad") ? "w-520" : "")
                }
              >
                <IonCardContent className="no-padding">
                  <IonItem style={{ marginTop: "10px" }} lines="none">
                    <IonText className="login-title-desktop">Login</IonText>
                  </IonItem>
                  <IonItem
                    className="email-box"
                    lines="none"
                    style={{
                      marginBottom: "24px",
                      marginTop: "10px",
                    }}
                  >
                    <CustomInput
                      placeholder="Email address"
                      value={userName || ""}
                      disabled={loading}
                      autoCapitalize={false}
                      type="text"
                      id="email"
                      onChange={(e) => {
                        setOverrideError(null);
                        setUserName(e.target.value);
                      }}
                    />
                  </IonItem>
                  <IonItem lines="none" className="password-box">
                    <CustomInput
                      placeholder="Password"
                      value={password}
                      disabled={loading}
                      type="password"
                      id="password"
                      onChange={(e) => {
                        setOverrideError(null);
                        setPassword(e.target.value!.trim());
                      }}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                  </IonItem>
                  <LoginError error={overrideError}></LoginError>
                  <IonItem lines="none" style={{ marginBottom: "-15px" }}>
                    <IonRouterLink
                      style={{ padding: "0px", margin: "0px" }}
                      href="#"
                      className="link"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push({ pathname: "/forgotpassword" });
                      }}
                    >
                      I forgot my password
                    </IonRouterLink>
                  </IonItem>
                  <IonItem lines="none">
                    <IonRouterLink
                      style={{ padding: "0px", margin: "0px" }}
                      href="#"
                      className="link"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push({ pathname: "/register" });
                      }}
                    >
                      {i18n.t("login.register")}
                    </IonRouterLink>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              className={
                "desktop-login-btn " + (isPlatform("ipad") ? "ml-90" : "")
              }
            >
              <IonItem lines="none" className="no-background-desktop">
                <IonButton
                  style={{ width: "180px" }}
                  className="custom-button"
                  disabled={loading}
                  onClick={() => doLogin()}
                >
                  {i18n.t("button.signin")}
                </IonButton>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </IonContent>
  );
};
