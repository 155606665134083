import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonGrid,
  IonImg,
  IonItem,
  IonRow,
  IonText,
  IonRouterLink,
  IonCard,
  IonCol,
} from "@ionic/react";
import "./Register.css";
import i18n from "../../../i18n";
import CustomInput from "../../CustomInput";
import { Auth } from "aws-amplify";
import { performLogin } from "../../../store/login/actions/login";
import { useDispatch } from "react-redux";
import LoginError from "../Error";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useIsIOS } from "../../../hooks/useIsIOS";
import { useHistory } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { FullAppState } from "../../../store/state";
import { AnyAction } from "redux";

interface Props {
  setError: (err: string | null) => void;
  error?: string;
  email?: string | null;
  password?: string | null;
  goBack: () => void;
  isUnfinishedSignin?: boolean;
}

// eslint-disable-next-line react/prop-types
export const ConfirmSignup: React.FC<Props> = ({
  email,
  password,
  setError,
  goBack,
  error,
  isUnfinishedSignin,
}: Props) => {
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isIOS = useIsIOS();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const thunkDispatch = useDispatch<
    ThunkDispatch<FullAppState, never, AnyAction>
  >();

  const resendConfirmationCode = (email?: string) => {
    if (!email) {
      return;
    }

    Auth.resendSignUp(email)
      .then(() => {
        console.log("code resent successfully");
      })
      .catch((err) => {
        console.log("error resending code: ", err);
      });
  };

  const verify = async (
    email: string | undefined,
    code: string | undefined
  ): Promise<void> => {
    if (!code || !email || code.length < 4) {
      setError("Invalid code");
      return;
    }

    let checked = false;
    setTimeout(async () => {
      if (!checked) {
        checked = true;
        try {
          await Auth.confirmSignUp(email, code);
          if (password) {
            await thunkDispatch(performLogin(email, password));
            history.push("/login");
          } else {
            goBack();
          }
        } catch (err) {
          if (
            err.message ==
            "User cannot be confirmed. Current status is CONFIRMED"
          ) {
            if (password) {
              await thunkDispatch(performLogin(email, password));
              history.push("/login");
            } else {
              goBack();
            }
          } else {
            setError(err);
          }
          console.log(err);
        }
      }
    }, 10000);

    try {
      setLoading(true);
      // LoginEvent("ForgetPassword");
      await Auth.confirmSignUp(email, code);
      checked = true;
      if (password) {
        await thunkDispatch(performLogin(email, password));
        history.push("/login");
      } else {
        goBack();
      }
    } catch (err) {
      setLoading(false);
      if (err.message) {
        if (
          err.message != "User cannot be confirmed. Current status is CONFIRMED"
        ) {
          setError(err.message);
        } else {
          goBack();
        }
      } else {
        setError(err);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setError(null);
    }
  }, [dispatch]);

  return (
    <IonGrid className="login-grid">
      {isMobile && (
        <IonRow>
          <IonCol className="p-0 login-bg">
            <div className="img-container">
              <IonImg
                src="assets/loginRect.svg"
                className="login-rectangle"
                alt="Splash Logo"
              ></IonImg>
              <IonImg
                src="assets/loginbranding.svg"
                className="top-img"
                alt="Logo branding"
              ></IonImg>
              <IonText className="welcome-text" role="heading">
                Welcome to
              </IonText>
              <IonText className="FairMile-text" role="heading">
                FairMile<span className="header-span">®</span>
              </IonText>
            </div>
          </IonCol>
        </IonRow>
      )}

      <IonRow>
        <IonCol style={{ display: "flex" }}>
          <IonCard className={isMobile ? "login-card" : "login-card-desktop"}>
            {isUnfinishedSignin && (
              <IonItem lines="none">
                <IonText style={{ color: "#003087", paddingTop: "10px" }}>
                  <IonText>{`You haven't confirmed your registration please look in your email for a verification code to enter here.`}</IonText>
                </IonText>
              </IonItem>
            )}
            <IonItem
              className="mt-9 custom-input-item"
              lines="none"
              style={{ marginBottom: "20px" }}
            >
              <CustomInput
                label="Verification Code"
                value={code}
                disabled={false}
                type="password"
                id="code"
                onChange={(e) => setCode((e.target as HTMLInputElement).value)}
              />
            </IonItem>
            <IonItem lines="none" className="custom-input-item">
              <IonButton
                tw="w-full mt-4"
                style={{ width: "156px" }}
                className={
                  isIOS ? "custom-button" : "custom-button signin-btn-color"
                }
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  verify(email!, code);
                }}
              >
                {i18n.t("button.submit")}
              </IonButton>
            </IonItem>
            <LoginError error={error}></LoginError>
            <IonItem lines="none" className="link-item">
              <IonRouterLink
                href="#"
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  resendConfirmationCode(email!);
                }}
              >
                {i18n.t("button.resendconfirmation")}
              </IonRouterLink>
            </IonItem>
            <IonItem lines="none" className="link-item">
              <IonRouterLink
                href="#"
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  goBack();
                }}
              >
                {i18n.t("button.backtosign")}
              </IonRouterLink>
            </IonItem>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
