import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
	en: {
		translation: {
			companyName: "Auto Insure",
			mileageReport: "Mileage Reports",
			idle: {
				title: "Your session has timed out please sign in again.",
				button: "Sign in"
			},
			welcomeNewsItem: {
				title: "Welcome",
				body:
					"Welcome to the program. We have developed resources to help you understand the program.",
				callToAction: "Please click here for more info",
			},
			dashboard: {
				companysnapUp: "COMPANY OVERVIEW",
				companysnap: "Company Overview",
			},
			termsAndConditions: {
				declineMessage: "You must accept the terms to use the application",
			},
			datepick: {
				start: "Start Date:",
				end: "End Date:",
			},
			card: {
				noData: "No Data",
				start: "Start:",
				end: "End:",
				estimatedMil: "Estimated Mileage",
				miles: "miles",
				ActualMil: "Actual Mileage",
				diff: "Difference",
				odStart: "Odometer Start",
				odEnd: "Odometer End",
				mi: "mi",
				driDura: " Driving Duration:",
				distance: "Distance:",
				stopDura: "Stop Duration:",
			},
			snapshot: {
				totalMil: "Total Mileage",
				lastMil: "Last Month's Mileage",
				avg: "Average for Last 3 Months",
			},
			newsfeed: {
				title: "News Feed",
				newmsg: "New Message",
				unread: "Unread",
			},
			mileage: {
				odo: "Odometer:",
				sinceProgramStart: "Mileage Since Program Start",
				lastMil: "Billing Period Distance Driven",
				info: "Mileage reports allow you to compare actual mileage to estimated mileage to determine if you are on track to receive a premium credit or debit that month.  You can select the Mileage Report for individual vehicles or all vehicles using the drop down menu at the top of this screen.  Visit the More screen for more information on how Mileage Reports are used for billing purposes."
			},
			vehicle: {
				billingperiod: "Billing Period Distance Driven",
				seeTripsAndAlerts: "See trips and Alerts",
				currentLocation: "Current Location",
				mileageReports: "Mileage Reports",
				title: "VEHICLES & DEVICES",
				search: "Type the name here",
				odometer: "Odometer",
				monthdate: "Month to Date:",
				viewmap: "View All On Map",
				miles: "miles",
				uninstalled: "Uninstalled",
				status: "Delivery Status",
				track: "Track Package",
				lastTrip: "Last trip",
				all: "All vehicles & devices",
				edit: {
					error: "Couldn't save the device name",
					title: "Please enter the name you want for this vehicle: ",
					placeholder: "My Car",
				},
				tabs: {
					all: "ALL",
					active: "VEHICLES VIEW",
					notactive: "DEVICES VIEW",
				},
				info: {
					fault: "Vehicle Faults alert you of any engine fault codes reported by your vehicle’s diagnostic port. Knowing how your vehicles are performing may allow you to deal with minor maintenance before it becomes a major expense. Visit the More screen to learn more about this feature.",
					exception: "Driving Alerts may help you ensure your employees are driving safely by alerting you whenever one of the following actions occurs on a trip: excessive speeding, harsh braking, hard acceleration, harsh corning, driving without a seatbelt. Driving Alerts also alert you anytime a device is unplugged from a vehicle. Visit the More screen to learn more about this feature.",
					missingvin: "The device did not connect to your vehicle.  This may happen when driving time is short, or it may indicate an installation or other technical issue.  If this persists, please visit the Support screen for more information."
				}
			},
			menu: {
				dashboard: "Dashboard",
				insights: "Insights",
				vehicles: "Vehicles",
				more: "More",
			},
			Register: {
				title: "Welcome to FairMile",
				message:
					"Please enter the email you submitted to your Agent when enrolling",
			},
			confirmsignup: {
				title: "Enter the code you recieved",
				verify: "Verify new password",
			},
			forgetpassword: {
				title: "Forgot your Password",
				message: "Enter your email address and we will send you a verification code to reset your password.",
			},
			newpassword: {
				error: "Passwords do not match please try again",
				changepw: "Please Change Your Password",
			},
			submitforget: {
				title: "Enter the code you recieved",
				newpassword: "New password",
				verify: "Verify new password",
			},
			login: {
				forget: "I forgot my password",
				register: "New User? Register Now",
				androidforgot: "Forgot username / password",
				errors: {
					noUser: 'The email address or password you entered does not match our records. Try again or tap "I forgot my password.',
					noPolicy: 'The email address entered does not match our records.  To sign up for the FairMile program or change your email address, please contact a Farmers® agent.',
					unknownError: "Unknown error has occurred. Please contact support",
					accountExists: 'An account with that email address already exists.  Sign In or reset your password using Forgot username / password.',
					passwordNoMatch: 'Passwords do not match.',
					forgotPasswordNoExist: "The email address entered does not match our records. To sign up for FairMile, please contact a Farmers agent.",
					tooManyAttempts: "Too many attempts.  Please retry later.",
					invalidVerificationCode: "Invalid verification code.  Please try again using the code emailed to you.",
					passwordCannotBeEmpty: "Password must not be empty.",
					usernameCannotBeEmpty: "Email must not be empty."
				}
			},
			terms: {
				title: "Terms And Conditions",
				termsOfUse: "Terms of Use"
			},
			more: {
				support: "Support",
				privacy: "Privacy",
				webview: "WebView",
				feedback: "Feedback",
				debug: "Debug Screen",
				help: "HOW CAN WE HELP?",
				givefeedback: "Give Feedback",
				tos: "Terms & Conditions",
				privpolicy: "Privacy Policy",
				logout: "Logout",
			},
			debug: {
				svusername: "Saved Username:",
				termsacc: "Terms Accepted",
				testnot: "Test Notification",
			},
			button: {
				resendconfirmation: "Resend Confirmation Code",
				cancel: "Cancel",
				save: "Save",
				edit: "Edit",
				submit: "Submit",
				backtosign: "Back to Sign In",
				signin: "LOG IN",
				changepw: "Change Password",
				more: "See More",
				logout: "Logout",
				accept: "ACCEPT",
				decline: "DECLINE",
				mor: "More",
				gotIt: "OK, GOT IT",
			},
			insights: {
				title: "INSIGHTS",
				allvehicles: "All vehicles",
				lastmonths: "Last 6 months",
			},
		},
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: "en",
});
export default i18n;
