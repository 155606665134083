import React from "react";
import "twin.macro";

const NoDataRow: React.FC = () => {
  return (
    <div>
      <dl tw="grid grid-cols-1 gap-2 sm:grid-cols-3">
        {/* <div tw="px-4 py-5 sm:p-6"> */}
        <div tw="ml-4 my-8">
          <dt tw="text-sm font-medium text-gray-500 truncate">No Data</dt>
        </div>
      </dl>
    </div>
  );
};

export default NoDataRow;
