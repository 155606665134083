import geotab from "mg-api-js";
import { clearLocalCredentials } from "../actions/geotab/authenticate";
import { GeotabCredentials } from "../types/geotab/auth";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type anyObject = { [name: string]: any };
export interface params {
    [name: string]: string | anyObject | number | boolean | undefined;
    typeName: string;
    resultsLimit?: number;
    search?: {
        includeOverlappedTrips?: boolean
        fromDate?: string;
        toDate?: string;
        deviceSearch?: {
            id: string;
        };
        diagnosticSearch?: {
            id: string
        }
        // device?: {
        //     id: string;
        // };
        id?: string;
        name?: string;
        entity?: anyObject;
    };
}
export type multiParamSingle = [string, params];
export type multiParams = multiParamSingle[];
export type onSuccess = (results: Array<anyObject>) => void;
export type onFailed = (errors: string | anyObject) => void;

class Geotab {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geotabLib: any | null;
    isConnected: boolean;
    authenticatingPromise: Promise<unknown> | null = null;
    constructor() {
        this.geotabLib = null;
        this.isConnected = false;
    }
    connect(
        credentials: GeotabCredentials
    ): Promise<unknown> {
        this.authenticatingPromise = new Promise((resolve) => {
            if (this.geotabLib) {
                resolve(this.geotabLib);
            }
            this.isConnected = false;
            const authenticatingLib = new geotab({
                credentials: credentials.credentials,
                // path: credentials.path || "my.geotab.com"
                path: "my.geotab.com"
            });
            this.isConnected = true;
            this.geotabLib = authenticatingLib;
            resolve(this.geotabLib);
            // authenticatingLib.authenticate(
            //     () => {
            //         console.log("Success auth");
            //         this.isConnected = true;
            //         this.geotabLib = authenticatingLib;
            //         resolve(this.geotabLib);
            //     },
            //     (error: string) => {
            //         console.log(error);
            //         reject(error);
            //     }
            // );
        });

        return this.authenticatingPromise;
    }

    async logout(): Promise<void> {
        this.isConnected = false;
        clearLocalCredentials();
        if (!this.geotabLib && this.authenticatingPromise) {
            await this.authenticatingPromise;
        }

        if (this.geotabLib) {
            const lib = this.geotabLib;
            this.geotabLib = null;
            await lib.forget();
        }
    }

    async call(
        method: string,
        params: params,
        onSuccess: onSuccess,
        onFailed: onFailed
    ) {
        try {
            if (!this.geotabLib) {
                await this.authenticatingPromise;
            }
            const data = await new Promise((resolve, reject) => {
                this.geotabLib.call(method, params, resolve, reject);
            });

            onSuccess(data as anyObject[]);
        } catch (err) {
            onFailed(err);
        }
    }

    multiCall(params: multiParams, onSuccess: onSuccess, onFailed: onFailed) {
        if (this.geotabLib) {
            this.geotabLib.multiCall(params, onSuccess, onFailed);
        } else {
            this.authenticatingPromise?.then(() => {
                this.geotabLib.multiCall(params, onSuccess, onFailed);
            }).catch(e => {
                console.log(e);
            });
        }
    }
}

const geotabHelper = new Geotab();

export default geotabHelper;
