import React, { useState, useEffect } from "react";

import "./Trips.css";
import { useSelector, useDispatch } from "react-redux";
import { FullAppState } from "../../store/state";
import { Device, Trip } from "../../geotab/helpers";
import { PageView } from "../../telemetry/TelemetryManager";
import { toMilesNoDiv } from "../../util/mileage";
import { loadTrips } from "../../store/trips/actions/trips";
import dayjs from "dayjs";
import { DateUtil } from "../../util/date";

import Page from "../Page";
import Notifications from "../../components/Notifications/Notifications";
import DatePicker from "../../components/DatePicker";
import SystemCard from "../../components/SystemCard/SystemCard2";

import tw from "twin.macro";
import { useDevice } from "../../hooks/useDevice";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import useDateFilter from "../../hooks/useDateFilter";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useInitialStoredDates } from "../../hooks/useInitialStoredDates";
import { useStoreInitialDates } from "../../hooks/useStoreInitialDates";

export interface TelematicsCard {
  id: string;
  typeName: number;
  activeFrom: string;
  activeTo: string;
  distance: string;
  stopDuration: plugin.Duration;
  drivingDuration: plugin.Duration;
  deviceId: string;
  callToAction: string;
}

const initialStartStorageKey = "TripScreenInitialStartDate";
const initialEndStorageKey = "TripScreenInitialEnd";
const Trips: React.FC = () => {
  const trips = useSelector(
    (state: FullAppState) => state.trips.items
  ) as Trip[];

  const [device, setDevice] = useState<Device | null>();

  const initialDevice = useDevice("device");

  const mapToCard = (list: Trip[]): TelematicsCard[] => {
    const mapped = list.map((x) => {
      return {
        id: x.id,
        typeName: 2,
        activeFrom: x.startTime,
        activeTo: x.endTime,
        distance: toMilesNoDiv(x.distance).toFixed(2).toString(),
        stopDuration: x.stopDuration,
        drivingDuration: x.drivingDuration,
        deviceId: x.device,
        callToAction: "/map?trip=" + x.id,
      };
    });
    return mapped;
  };

  const [showLoading, setShowLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    if (initialDevice && !device) {
      setDevice(initialDevice);
      return;
    }
    PageView("Trips", { deviceId: device?.id });
  }, [dispatch]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dateFilter = (
    e: Trip,
    index: number,
    fromDate: dayjs.Dayjs,
    toDate: dayjs.Dayjs
  ) => {
    return (
      DateUtil.utc(e.startTime) >= fromDate &&
      DateUtil.utc(e.startTime) <= toDate &&
      (!device || device.id == e.device)
    );
  };

  const thunkDispatch = useDispatch<
    ThunkDispatch<FullAppState, never, AnyAction>
  >();

  const [initialStart, initialEnd] = useInitialStoredDates(
    initialStartStorageKey,
    initialEndStorageKey
  );

  const [filteredList, fromDate, setFrom, toDate, setTo] = useDateFilter(
    trips,
    dateFilter,
    mapToCard,
    setShowLoading,
    (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => {
      return thunkDispatch(
        loadTrips(fromDate.toISOString(), toDate.toISOString())
      );
    },
    device,
    initialStart,
    initialEnd
  );

  useStoreInitialDates(
    initialStartStorageKey,
    fromDate,
    initialEndStorageKey,
    toDate
  );

  const isMobile = useIsMobile();
  return (
    <Page
      showLoading={showLoading}
      loadingTimeout={() => setShowLoading(false)}
      defaultDevice={device || undefined}
      setDevice={setDevice}
      title="TRIPS"
      showVehicleSelector={true}
      showBack={true}
    >
      <div tw="pb-4" style={{ maxWidth: "1036px" }}>
        <Notifications />
        <DatePicker
          style={!isMobile ? { paddingLeft: "28px", paddingRight: "28px" } : {}}
          from={fromDate}
          to={toDate}
          settingfrom={setFrom}
          settingto={setTo}
        />
        <div tw="flex flex-wrap">
          {filteredList.map((item, index) => {
            return (
              <div css={isMobile ? tw`w-full mr-8` : ""} key={index}>
                <SystemCard card={item} />
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default Trips;
