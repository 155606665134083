import React from "react";
import "./SystemCard.css";
import { DateFormat } from "../../constants";
import { DateUtil } from "../../util/date";

import "twin.macro";

interface Props {
  start: string;
  end: string;
  drivingDuration?: plugin.Duration;
  stopDuration?: plugin.Duration;
  distance: string;
}

const TripContainer: React.FC<Props> = (props: Props) => {
  return (
    <div tw="flex">
      <div tw="m-4 grid grid-rows-4 grid-cols-2 text-sm">
        <div>Start:</div>
        <div>{DateUtil.utc(props.start).local().format(DateFormat)}</div>
        <div>End:</div>
        <div>{DateUtil.utc(props.end).local().format(DateFormat)}</div>
        <div>Driving Duration:</div>
        <div>{DateUtil.durationShortFormat(props.drivingDuration)}</div>
        <div>Stop Duration</div>
        <div>{DateUtil.durationShortFormat(props.stopDuration)}</div>
      </div>
    </div>
  );
};

export default TripContainer;
