import React, { useState } from "react";
import "./ForgotPassword.css";

import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonRouterLink,
  IonRow,
  IonText,
} from "@ionic/react";
import { useHistory } from "react-router";
import i18n from "../../i18n";
import CustomInput from "../CustomInput";
import { useViewport } from "../../hooks/useViewport";
import { isMobileViewport } from "../../util/platform";
import LoginError from "./Error";
import { LoginErrorType } from "../../store/login/reducers/loginReducer";
import PasswordPolicy from "./PasswordPolicy";
import { useCheckPassword } from "../../hooks/useCheckPassword";
import "twin.macro";

interface Props {
  submitForgetPassword: (email: string, code: string, password: string) => void;
  email: string;
  setError: (err: string) => void;
  error?: string;
}
const validate = (email: string, password: string, confirmPassword: string) => {
  if (
    email != null &&
    password != null &&
    confirmPassword != null &&
    password === confirmPassword
  ) {
    return true;
  }

  return false;
};

// eslint-disable-next-line react/prop-types
export const SubmitForgotPassword: React.FC<Props> = ({
  submitForgetPassword,
  email,
  setError,
  error,
}: Props) => {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const history = useHistory();
  const { all } = useCheckPassword(password, confirmation);

  const viewport = useViewport();

  return (
    <div tw="h-full overflow-y-auto">
      <IonGrid className="login-grid">
        {isMobileViewport(viewport) && (
          <IonRow>
            <IonCol className="p-0 login-bg">
              <div className="img-container">
                <IonImg
                  src="assets/loginRect.svg"
                  className="login-rectangle"
                  alt="Background gradient"
                ></IonImg>
                <IonImg
                  src="assets/loginbranding.svg"
                  className="top-img"
                  alt="Logo branding"
                ></IonImg>
                <IonText className="welcome-text" role="heading">
                  Welcome to
                </IonText>
                <IonText className="FairMile-text" role="heading">
                  FairMile
                  <span className="header-span">®</span>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        )}

        <IonRow>
          <IonCol style={{ display: "flex" }}>
            <IonCard
              className={
                isMobileViewport(viewport) ? "login-card" : "login-card-desktop"
              }
            >
              <IonItem className="mt-9 custom-input-item mb-ios" lines="none">
                <CustomInput
                  label="Verification Code"
                  id="email"
                  value={code}
                  disabled={false}
                  type="text"
                  onChange={(e) =>
                    setCode((e.target as HTMLInputElement).value)
                  }
                />
              </IonItem>
              <IonItem
                className="mt-9 custom-input-item"
                lines="none"
                style={{ marginBottom: "20px" }}
              >
                <CustomInput
                  label="Password"
                  id="password"
                  value={password}
                  disabled={false}
                  type="password"
                  onChange={(e) =>
                    setPassword((e.target as HTMLInputElement).value)
                  }
                />
              </IonItem>
              <IonItem className="mt-9 custom-input-item" lines="none">
                <CustomInput
                  label="Confirm Password"
                  id="confirm"
                  value={confirmation}
                  disabled={false}
                  type="password"
                  onChange={(e) =>
                    setConfirmation((e.target as HTMLInputElement).value)
                  }
                />
              </IonItem>

              <PasswordPolicy password={password} confirmation={confirmation} />
              <LoginError error={error} />

              <IonItem lines="none" className="custom-input-item">
                <IonButton
                  style={{ width: "100%" }}
                  class="mt-20 custom-button signin-btn-color"
                  disabled={!all || email == null}
                  onClick={() => {
                    if (validate(code, password, confirmation)) {
                      submitForgetPassword(email, code, password);
                    } else {
                      if (password != confirmation) {
                        setError(LoginErrorType.PasswordNoMatch);
                      }
                      console.log("Invalid Credentials");
                    }
                  }}
                >
                  {i18n.t("button.submit")}
                </IonButton>
              </IonItem>
              <IonItem lines="none" className="link-item">
                <IonRouterLink
                  href="#"
                  className="link"
                  onClick={(e) => {
                    e.preventDefault();
                    history.replace("/login");
                  }}
                >
                  {i18n.t("button.backtosign")}
                </IonRouterLink>
              </IonItem>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};
