/* eslint-disable react/prop-types */
import { IonLoading } from "@ionic/react";
import React from "react";
import Notifications from "../components/Notifications/Notifications";
import PageHeader from "../components/PageHeader";
import "./Page.css";

import { isMobileViewport } from "../util/platform";
import { useViewport } from "../hooks/useViewport";
import VehicleSelector from "../components/VehicleSelector/VehicleSelector2";
import { Device } from "../geotab/helpers";

import tw from "twin.macro";

interface Props {
  title: string;

  //Vehicle Selector Settings
  showVehicleSelector?: boolean;
  setDevice?: (device: Device | null) => void;
  defaultDevice?: Device;
  hideAllVehicles?: boolean;

  children?: React.ReactNode;
  showLoading?: boolean;
  loadingTimeout?: () => void;
  showBack: boolean;
  renderHeader?: () => React.ReactNode;
  canScrollY?: boolean;
  renderChildren?: (children: React.ReactNode) => React.ReactNode;
  removeBottomMargin?: boolean;
}

const Page: React.FC<Props> = (props: Props) => {
  const viewport = useViewport();
  const isMobile = isMobileViewport(viewport);

  return (
    <div
      id="page-root"
      css={
        props.canScrollY == true || props.canScrollY == undefined
          ? tw`overflow-y-auto`
          : tw``
      }
      tw="h-full w-full flex flex-col"
    >
      <IonLoading
        cssClass="my-custom-class"
        isOpen={props.showLoading || false}
        onDidDismiss={() => {
          if (props.loadingTimeout) {
            props.loadingTimeout();
          }
        }}
        message={"Please wait..."}
        duration={5000}
      />
      <PageHeader
        title={props.title}
        subtitle={props.title}
        showBack={props.showBack}
      />

      {props.renderHeader && <div>{props.renderHeader()}</div>}

      {!isMobile && (
        <div tw="uppercase" className="snapShot-desktop">
          <div tw="ml-8 mb-8">
            <h1>{props.title}</h1>
          </div>
        </div>
      )}

      <Notifications />
      {props.showVehicleSelector && props.setDevice != null && (
        <div css={isMobile ? tw`mt-4` : ""} style={{ maxWidth: "1036px" }}>
          <VehicleSelector
            defaultDevice={props.defaultDevice?.id}
            setDevice={props.setDevice}
            hideAll={props.hideAllVehicles}
          />
        </div>
      )}

      {!props.renderChildren && (
        <div
          id="regular-rendered-children"
          css={props.removeBottomMargin ? tw`pb-0` : tw`pb-4`}
        >
          {props.children || ""}
        </div>
      )}

      {props.renderChildren && props.renderChildren(props.children)}
    </div>
  );
};

export default Page;
