import IAction from "../../actions";

export const LOAD_SNAPSHOT  = "LOAD_SNAPSHOT";

export type ISnapshotAction = IAction;

export function loadSnapshot() : ISnapshotAction{
	return {
		type: LOAD_SNAPSHOT
	};
}