import { IonRouterOutlet } from "@ionic/react";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { Login } from "../Login/Login";
import { Logout } from "../Login/Logout";
import ProtectedRouter, {
  ProtectedRouteProps,
} from "../Router/ProtectedRouter";
import Dashboard from "../../pages/Dashboard";
import Insights from "../../pages/Insights2";
import Vehicle from "../../pages/vehicle/Vehicle2";
import Vehicles from "../../pages/Vehicles2";
import Trips from "../../pages/trips/Trips2";
import Mileage from "../../pages/mileage/Mileage2";
import Exceptions from "../../pages/trips/Exceptions2";
import Faults from "../../pages/trips/Faults2";
import More from "../../pages/More2";
import Map from "../../pages/map/Map2";
import { WebView } from "../Webview";
import DebugScreen from "../../pages/debug";
import { ForgotPassword } from "../Login/ForgotPassword";
import { Register } from "../Login/Register/Register";
import NewsfeedPage from "../../pages/newsfeed";
import { TermsAndConditions } from "../../components/Login/TermsAndConditions2";
interface IProps {
  id?: string;
  isLoggedIn: boolean;
  removeOutlet?: boolean;
}

const Routes: React.FC<IProps> = ({ id, isLoggedIn, removeOutlet }: IProps) => {
  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isLoggedIn: isLoggedIn,
    authenticationPath: "/login",
  };

  const debugRoute = () => {
    if (process.env.NODE_ENV == "development") {
      return (
        <ProtectedRouter
          {...defaultProtectedRouteProps}
          path="/debug-screen"
          exact
          component={DebugScreen}
        />
      );
    } else {
      return <></>;
    }
  };
  const routes = (
    <Switch>
      <Route path="/login" component={Login} exact={true} />
      <Route path="/logout" component={Logout} exact={true} />
      <Route path="/forgotpassword" component={ForgotPassword} exact={true} />
      <Route path="/register" component={Register} exact={true} />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/dashboard"
        exact
        component={Dashboard}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/insights"
        exact
        component={Insights}
      />

      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/vehicle/:id"
        exact
        component={Vehicle}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/vehicles"
        exact
        component={Vehicles}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/trips"
        exact
        component={Trips}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/trips/:id"
        exact
        component={Trips}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/mileage"
        exact
        component={Mileage}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/mileage/:id"
        exact
        component={Mileage}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/exceptions"
        exact
        component={Exceptions}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/exceptions/:id"
        exact
        component={Exceptions}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/faults"
        exact
        component={Faults}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/faults/:id"
        exact
        component={Faults}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/more"
        exact
        component={More}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/map"
        exact
        component={Map}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/webview"
        exact
        component={WebView}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/newsfeed"
        exact
        component={NewsfeedPage}
      />
      <ProtectedRouter
        {...defaultProtectedRouteProps}
        path="/termsandconditions"
        exact
        component={TermsAndConditions}
      />
      {debugRoute()}
    </Switch>
  );
  return removeOutlet ? (
    <>
      {routes}
      <Redirect exact from="/" to={isLoggedIn ? "/dashboard" : "/login"} />{" "}
    </>
  ) : (
    <IonRouterOutlet id={id}>
      {routes}
      <Redirect exact from="/" to={isLoggedIn ? "/dashboard" : "/login"} />
    </IonRouterOutlet>
  );
};

export default Routes;
