import { IonIcon } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import React from "react";
import "twin.macro";
import { useCheckPassword } from "../../hooks/useCheckPassword";

interface Props {
  password?: string;
  confirmation?: string;
}
const PasswordPolicy: React.FC<Props> = ({ password, confirmation }: Props) => {
  const { length, match, uppercase, lowercase, number } = useCheckPassword(
    password,
    confirmation
  );

  return (
    <div tw="ml-4 mt-4 flex flex-col items-start w-full">
      <span tw="text-farmersblue">Password Policy:</span>
      <ol tw="ml-4 text-left">
        <li tw="grid grid-cols-2 grid-rows-1">
          Passwords match
          {match && (
            <IonIcon
              tw="ml-4 text-green-500 text-lg"
              icon={checkmark}
            ></IonIcon>
          )}
        </li>
        <li tw="grid grid-cols-2 grid-rows-1 text-sm">
          At 8 characters long
          {length && (
            <IonIcon
              tw="ml-4 text-green-500 text-lg"
              icon={checkmark}
            ></IonIcon>
          )}
        </li>
        <li tw="grid grid-cols-2 grid-rows-1">
          Contains uppercase letter
          {uppercase && (
            <IonIcon
              tw="ml-4 text-green-500 text-lg"
              icon={checkmark}
            ></IonIcon>
          )}
        </li>
        <li tw="grid grid-cols-2 grid-rows-1">
          Contains lowercase letter
          {lowercase && (
            <IonIcon
              tw="ml-4 text-green-500 text-lg"
              icon={checkmark}
            ></IonIcon>
          )}
        </li>
        <li tw="grid grid-cols-2 grid-rows-1">
          Contains a number
          {number && (
            <IonIcon
              tw="ml-4 text-green-500 text-lg"
              icon={checkmark}
            ></IonIcon>
          )}
        </li>
      </ol>
    </div>
  );
};

export default PasswordPolicy;
