import { FaultData, getFaultData, getLogRecordForDevice } from "../../../geotab/helpers";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { CLEAR_STORE } from "../../login/actions/login";
import { FullAppState } from "../../state";
import whiteListFilter from '../util/faultFilter';
import { DateUtil } from "../../../util/date";

export const LOAD_FAULT_DATA = "load_fault_data";
export const LOAD_FAULT_DATA_SINGLE = "load_fault_data_single";
export const SET_WARNING_LIGHT_LOADING_TIME = "set_warning_light_loading_time";

interface ILoadFaultData {
    type: typeof LOAD_FAULT_DATA | typeof CLEAR_STORE;
    faultData: FaultData[];
}

interface ILoadSingleFaultData {
    type: typeof LOAD_FAULT_DATA_SINGLE;
    faultData: FaultData;
}

interface IWarningLightLoadingTime {
    type: typeof SET_WARNING_LIGHT_LOADING_TIME;
}

export type LoadFaultDataType = ILoadFaultData;
export type LoadSingleFaultData = ILoadSingleFaultData;
export type SetWarningLightLoadingTime = IWarningLightLoadingTime;

export function loadFaultData(
    fromDate?: string,
    toDate?: string,
    limit = 1000,
    diagnosticId: string | null | undefined = null
): ThunkAction<
    Promise<void | ILoadFaultData>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch) {
        try {
            const faultData = whiteListFilter(await getFaultData(limit, fromDate, toDate, diagnosticId));
            dispatch(load(faultData));
        } catch (error) {
            console.log(error);
        }
    };
}

export function loadWarningLightData(
): ThunkAction<
    Promise<void | ILoadFaultData>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch, getState: () => FullAppState) {
        try {
            const state = getState();
            const now = DateUtil.utc();
            if (now.diff(state.faultData.lastLoadedWarningLight, "minutes") < 60) {
                return;
            }
            dispatch(loadFaultData(
                DateUtil.utc().add(-1, "week").toISOString(),
                DateUtil.utc().toISOString(),
                100,
                "DiagnosticGeneralVehicleWarningLightId"
            ));
        } catch (error) {
            console.log(error);
        }
    };
}

export function loadAllFaultDataPoints(): ThunkAction<
    Promise<void>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch, state) {
        const faultData: FaultData[] = [];
        const faults = state().faultData.items;
        for (let i = 0; i < faults.length; i++) {
            const points = await getLogRecordForDevice(faults[i].device.id, faults[i].dateTime, faults[i].dateTime);
            const faultObj = { ...faults[i], points };
            faultData.push(faultObj);
        }
        dispatch(load(whiteListFilter(faultData)));
    };
}

export function loadFaultDataPoints(
    faultData: FaultData
): ThunkAction<Promise<boolean>, FullAppState, AnyAction, AnyAction> {
    return async function (dispatch) {
        const points = await getLogRecordForDevice(faultData.device.id, faultData.dateTime, faultData.dateTime);
        if (points.length > 0) {
            dispatch(doLoadFaultData({ ...faultData, points }));
            return true;
        } else {
            return false;
        }
    };
}

export function load(faultData: FaultData[]): ILoadFaultData {
    return { type: LOAD_FAULT_DATA, faultData };
}

export function doLoadFaultData(faultData: FaultData): ILoadSingleFaultData {
    return { type: LOAD_FAULT_DATA_SINGLE, faultData };
}

export function setWarningLightFetchTime(): IWarningLightLoadingTime {
    return { type: SET_WARNING_LIGHT_LOADING_TIME };
}
