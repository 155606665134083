import { IonIcon } from "@ionic/react";

import { location as mapPinIcon } from "ionicons/icons";
import React from "react";

import "twin.macro";
import { DateUtil } from "../../util/date";

interface Props {
  startTime: string;
  endTime: string;
  distance: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TripRow: React.FC<Props> = (props: Props) => {
  return (
    <button
      onClick={props.onClick}
      tw="flex w-full p-0 m-0 items-center text-left"
    >
      <div className="screen-reader-only">Trip</div>
      <dl tw="grid grid-cols-3 gap-2 sm:grid-cols-3 flex-grow">
        <div>
          <dt className="trip-data-label" tw="text-headinggray">
            Start Time
          </dt>
          <dd
            tw="mt-1"
            style={{ fontSize: "0.9em", paddingTop: "4px" }}
            className="trip-data-label"
          >
            <div tw="grid grid-cols-1 grid-rows-2">
              <div>
                {DateUtil.dateOnlyFormat(DateUtil.utc(props.startTime).local())}
              </div>
              <div>
                {DateUtil.timeOnlyFormat(DateUtil.utc(props.startTime).local())}
              </div>
            </div>
          </dd>
        </div>

        <div>
          <dt className="trip-data-label" tw="text-headinggray">
            End Time
          </dt>
          <dd
            tw="mt-1"
            style={{ fontSize: "0.9em", paddingTop: "4px" }}
            className="trip-data-label"
          >
            <div tw="grid grid-cols-1 grid-rows-2">
              <div>
                {DateUtil.dateOnlyFormat(DateUtil.utc(props.endTime).local())}
              </div>
              <div>
                {DateUtil.timeOnlyFormat(DateUtil.utc(props.endTime).local())}
              </div>
            </div>
          </dd>
        </div>

        <div tw="flex flex-col w-full">
          <dt className="trip-data-label" tw="text-headinggray">
            Distance
          </dt>
          <dd
            tw="flex items-center flex-grow"
            style={{ fontSize: "0.9em" }}
            className="trip-data-label"
          >
            <span>
              {props.distance} mile{props.distance == "1" ? "" : "s"}
            </span>
          </dd>
        </div>
      </dl>

      <div tw="flex justify-center items-center">
        <IonIcon
          tw="mr-2 flex-none text-highlightblue text-xl"
          icon={mapPinIcon}
          ariaLabel="Current Location"
        />
      </div>
    </button>
  );
};

export default TripRow;
