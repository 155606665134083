import { CLEAR_STORE } from "../../login/actions/login";
import { LOAD_COMPANY, ILoadCompanyAction } from "../actions/company";
import ICompanyState from "../state/ICompanyState";

const initialState: ICompanyState = {
	data: {},
	loading: false
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function (state: ICompanyState = initialState, action: ILoadCompanyAction): ICompanyState {
	switch (action.type) {
		case LOAD_COMPANY:
			return { ...state, data: action.data };
		case CLEAR_STORE:
			return initialState;
		default:
			return state;
	}
}