import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Login.css";
import { clearRedux, logout } from "../../store/login/actions/login";
import { logout as authLogout } from "../../aws/auth";
import { Redirect } from "react-router";
import geotabHelper from "../../geotab";

export const Logout: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    geotabHelper.logout();
    authLogout().then(() => {
      dispatch(logout());
      dispatch(clearRedux());
    });
  });

  return <Redirect to="/login" />;
};
