import { getLogRecordForEntity, getRules, Rule } from "./../../../geotab/helpers/index";
import { Exception, getExceptions } from "../../../geotab/helpers";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import IExceptionsState from "../state/exceptionsState";
import { CLEAR_STORE } from "../../login/actions/login";
import { FullAppState } from "../../state";

export const LOAD_EXCEPTIONS = "load_exceptions";
export const LOAD_EXCEPTION = "load_exception";
export const SET_RULES = "set_rules";

interface ILoadExceptions {
    type: typeof LOAD_EXCEPTIONS | typeof CLEAR_STORE;
    exceptions: Exception[];
    fetchedTimes: { fromDate?: string, toDate?: string } | null
}

interface ILoadException {
    type: typeof LOAD_EXCEPTION;
    exception: Exception;
}

interface ISetRules {
    type: typeof SET_RULES;
    rules: Record<string, Rule>
}

export type LoadExceptionsType = ILoadExceptions;
export type LoadExceptionTyper = ILoadException;
export type SetRulesType = ISetRules;

export function loadRules(): ThunkAction<
    Promise<void | ILoadExceptions>,
    IExceptionsState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch) {
        try {
            const rules = await getRules();
            dispatch(setRules(rules));
        } catch (err) {
            console.log(err);
        }
    };
}

export function setRules(rules: Rule[]): ISetRules {
    const ruleMap: Record<string, Rule> = {};

    for (const rule of rules) {
        ruleMap[rule.id] = rule;
    }

    return { type: SET_RULES, rules: ruleMap };
}

export function loadExceptions(
    fromDate?: string,
    toDate?: string,
    limit = 1000
): ThunkAction<
    Promise<void | ILoadExceptions>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch, getState) {
        try {
            const state = getState();
            const rules = state.exceptions.rules;
            if (!rules || Object.keys(rules).length == 0) {
                const rules = await getRules();
                dispatch(setRules(rules));
            }

            const exceptions = await getExceptions(limit, fromDate, toDate);
            dispatch(load(exceptions, { fromDate, toDate }));
        } catch (err) {
            console.log(err);
        }
    };
}

export function loadExceptionsPoints(): ThunkAction<
    Promise<void>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch, state) {
        const exceptionsList: Exception[] = [];
        const exceptions = state().exceptions.items;
        for (let i = 0; i < exceptions.length; i++) {
            const points = await getLogRecordForEntity(exceptions[i]);
            const exceptionObj = { ...exceptions[i], points };
            exceptionsList.push(exceptionObj);
        }
        dispatch(load(exceptionsList, null));
    };
}

export function loadExceptionPoints(
    exception: Exception
): ThunkAction<Promise<boolean>, FullAppState, AnyAction, AnyAction> {
    return async function (dispatch) {
        const points = await getLogRecordForEntity(exception);
        if (points.length > 0) {
            dispatch(doLoadException({ ...exception, points }));
            return true;
        } else {
            return false;
        }
    };
}

export function load(exceptions: Exception[], fetchedTimes: { fromDate?: string, toDate?: string } | null): ILoadExceptions {
    return { type: LOAD_EXCEPTIONS, exceptions, fetchedTimes };
}

export function doLoadException(exception: Exception): ILoadException {
    return { type: LOAD_EXCEPTION, exception };
}
