export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const bgColors = [
    "#003087",
    "#0073CF",
    "#B4C4EA"
];

export const annConf = [
    {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: "25",
        borderColor: "tomato",
        borderWidth: 1,
    },
];