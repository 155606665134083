import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";

import "./Dashboard.css";
import SnapshotCard from "../components/SnapshotCard/Snapshot3";
import { PageView } from "../telemetry/TelemetryManager";
import { FullAppState } from "../store/state";
import { useSelector } from "react-redux";
import "../App.css";
import { useViewport } from "../hooks/useViewport";
//import DesktopHeader from "../components/DesktopHeader/DesktopHeader";
// Leaflet.Icon.Default.imagePath =
// 	"//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/";

import MileageBarGraph from "../components/MileageBarGraph";
import { Device } from "../geotab/helpers";
import Card from "../components/Card/Card";

import tw from "twin.macro";
import Page from "./Page";
import { useIsMobile } from "../hooks/useIsMobile";

const Insights: React.FC<RouteComponentProps<{ name: string }>> = () => {
  const history = useHistory();

  const policyNumber = useSelector(
    (state: FullAppState) => state.policy.policy?.PolicyNumber
  );

  const [pageViewthrown, setPageViewThrown] = useState(false);
  if (!pageViewthrown) {
    setPageViewThrown(true);
    PageView("Dashboard");
  }

  const viewport = useViewport();

  useEffect(() => {
    document.title = "FairMile® | Dashboard";
  }, []);

  useEffect(() => {
    if (policyNumber != null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).lastUrl) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        history.push((window as any).lastUrl);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (window as any).lastUrl;
      }
    }
  }, [policyNumber]);

  const [device, setDevice] = useState<Device | null>();
  const isMobile = useIsMobile();

  return (
    <Page
      showBack={false}
      title="Insights"
      showVehicleSelector={true}
      setDevice={setDevice}
      defaultDevice={device || undefined}
    >
      <div style={{ maxWidth: "1036px" }}>
        <div tw="flex flex-wrap">
          <div css={isMobile ? tw`w-full mr-8` : ""} tw="">
            <SnapshotCard title="Vehicle Mileage" showAverage={true} />
          </div>

          <div css={isMobile ? tw`w-full pr-8` : ""} tw="">
            {viewport.width > 200 && (
              <Card title="Last 6 Months">
                <MileageBarGraph selectedDevice={device?.id || ""} />
              </Card>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Insights;
