import { IonIcon, IonPopover, IonText } from "@ionic/react";
import React, { useState } from "react";

import tw from "twin.macro";

import { useHistory } from "react-router";

import { Plugins } from "@capacitor/core";
import { informationCircle } from "ionicons/icons";
import { useIsMobile } from "../../hooks/useIsMobile";
const { Browser } = Plugins;
const openUrl = async (url: string) => {
  await Browser.open({ url });
};

interface Props {
  title?: string;
  subtitle?: string;
  callToAction?: string;
  callToActionLabel?: string;
  ariaLabel?: string;
  children?: React.ReactNode;
  leftColumnColor?: string;
  hideHeader?: boolean;
  renderCallToAction?: () => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  //Set the size
  cardSize?: string;
  //if this is set then the cardSize becomes maxWidth
  useMaxSize?: boolean;
  infoText?: string;
  infoTextTitle?: string;
  blueTitle?: boolean;
  externalAria?: boolean;
  ariaTitle?: string;
  isTabIndex?: boolean;
}

const Card: React.FC<Props> = ({
  title,
  ariaTitle,
  subtitle,
  callToAction,
  ariaLabel,
  children,
  callToActionLabel,
  leftColumnColor,
  hideHeader,
  renderCallToAction,
  renderHeader,
  cardSize,
  useMaxSize,
  infoText,
  infoTextTitle,
  blueTitle,
  externalAria,
  isTabIndex,
}: Props) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const cardStyle = isMobile
    ? {}
    : useMaxSize
    ? {
        maxWidth: cardSize || "454px",
      }
    : { width: cardSize || "454px" };

  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any;
  }>({ showPopover: false, event: undefined });

  return (
    <div
      aria-label={externalAria ? undefined : ariaLabel}
      role="region"
      tabIndex={isTabIndex == false ? undefined : 0}
    >
      {infoText && (
        <IonPopover
          isOpen={popoverState.showPopover}
          event={popoverState.event}
          onDidDismiss={() => {
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          <div tw="flex flex-col leading-6 p-2">
            <div tw="text-headinggray mb-2">{infoTextTitle || "More Info"}</div>
            <span tw="text-sm">
              <IonText>{infoText}</IonText>
            </span>
          </div>
        </IonPopover>
      )}

      {renderHeader != null && renderHeader()}
      <div
        tw="bg-white shadow rounded-md"
        css={isMobile ? tw`w-full mx-4 mt-4` : tw`mx-8 mt-4`}
        style={cardStyle}
      >
        <div tw="flex">
          {leftColumnColor && (
            <div
              id="left-color-col"
              tw="rounded-l-md"
              style={{ backgroundColor: leftColumnColor, width: "30px" }}
            ></div>
          )}
          <div tw="flex flex-col items-stretch items-start w-full">
            <div tw="flex-grow w-full">
              {!hideHeader && (
                <div tw="flex items-center px-4 py-4 border-b border-gray-200">
                  <div>
                    <h2
                      aria-hidden={ariaTitle != null}
                      css={blueTitle ? tw`text-farmersblue` : ""}
                      className={
                        "desktop-card-title " + (isMobile ? " mobile" : "")
                      }
                      id={title}
                    >
                      {title}
                    </h2>
                    {ariaTitle && (
                      <div className="screen-reader-only">{ariaTitle}</div>
                    )}
                    <p tw="mt-1 max-w-2xl text-sm text-headinggray">
                      {subtitle}
                    </p>
                  </div>

                  {infoText && (
                    <button
                      tw="ml-4 h-full p-0 flex items-center"
                      onClick={(e) => {
                        setShowPopover({ showPopover: true, event: e });
                      }}
                    >
                      <IonIcon
                        className="alert-circle"
                        aria-label={title + " more information button"}
                        icon={informationCircle}
                        title={title}
                      ></IonIcon>
                    </button>
                  )}
                </div>
              )}

              <div tw="flex flex-col flex-grow">
                <div tw="w-full h-full">{children}</div>

                <div hidden={!callToAction && !renderCallToAction}>
                  {renderCallToAction != null && (
                    <div tw="border-t">{renderCallToAction()}</div>
                  )}

                  {callToAction && (
                    <div
                      tw="border-t"
                      onClick={() => {
                        if (callToAction) {
                          if (callToAction.indexOf("http") >= 0) {
                            openUrl(callToAction);
                          } else {
                            history.push(callToAction);
                          }
                        }
                      }}
                    >
                      <button tw="cursor-pointer block px-4 py-4">
                        <IonText
                          tw="text-highlightblue"
                          className={"seeMore-desktop"}
                        >
                          {callToActionLabel || "SEE MORE"}
                        </IonText>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
