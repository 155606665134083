import { LoadingStatus } from "../../../types/Loading";
import { CLEAR_STORE } from "../../login/actions/login";
import { GEOTAB_AUTHENTICATION_ACTION, IGeotabAuthAction, LOAD_GEOTAB_CREDENTIALS_ACTION } from "../actions/types";
import { GeotabAuthState } from "../state/geotabAuthState";

const initialState: GeotabAuthState = {
    LoadingStatus: LoadingStatus.unkown,
    isAuthenticated: false,
};

export default function geotabAuthReducer(
    state: GeotabAuthState = initialState,
    action: IGeotabAuthAction
): GeotabAuthState {
    switch (action.type) {
        case GEOTAB_AUTHENTICATION_ACTION:
            return {
                ...state,
                GeotabSAMLCredentials: action.geotabCredentials,
                isAuthenticated: action.isAuthenticated,
                LoadingStatus: action.LoadingStatus
            };
        case LOAD_GEOTAB_CREDENTIALS_ACTION:
            return {
                ...state,
                LoadingStatus: action.LoadingStatus
            };
        case CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
}