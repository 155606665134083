import React from "react";
import { History } from "history";
import "twin.macro";

const cache: Record<string, React.ReactNode[]> = {};

export type LinkDescription = { text: string; path: string };
export type Links = Record<string, LinkDescription>;

export const renderDescription = (
  history: History<unknown> | string[],
  description?: string,
  links?: Links
): React.ReactNode => {
  if (!description) {
    return <div></div>;
  }

  if (cache[description]) {
    return cache[description];
  }

  if (!links) {
    return [<span key={0}>{description}</span>];
  }

  const linkKeys = links ? Object.keys(links) : [];
  const elements: React.ReactNode[] = [];
  let key = 0;
  for (const l of linkKeys) {
    const sp = description?.split(`{${l}}`);
    if (sp && links) {
      for (let i = 0; i < sp.length; i++) {
        elements.push(<span key={key++}>{sp[i]}</span>);
        if (i != sp.length - 1 || sp.length == 1) {
          elements.push(
            <button
              key={key++}
              onClick={() => history.push(links[l].path)}
              tw="text-farmersblue font-bold cursor-pointer"
            >
              {links[l].text}
            </button>
          );
        }
      }
    } else {
      elements.push(<span key={key++}>{description}</span>);
    }
  }

  cache[description] = elements;
  return elements;
};
