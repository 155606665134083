
export enum NewsFeedItemType {
    Info,
    Alert,
    Report,
    Warning
}

export interface INewsFeedItem {
    id: string;
    type: NewsFeedItemType;
    time: string;
    title?: string;
    message?: string;
    callToAction?: string;
}

export const getNewsFeedItemType = (type: string): NewsFeedItemType => {
    if (type === "info") {
        return NewsFeedItemType.Info;
    } else if (type === "warning") {
        return NewsFeedItemType.Warning;
    } else if (type === "alert") {
        return NewsFeedItemType.Alert;
    } else {
        return NewsFeedItemType.Report;
    }
};

export default interface INewsFeedState {
    items: INewsFeedItem[];
    loading: boolean;
    loaded: boolean;
}
