import { setNotificationToken } from "./../system/StorageManager";
import {
    Plugins,
    PushNotificationToken,
    PushNotificationActionPerformed,
    PushNotification,
    LocalNotificationActionPerformed,
} from "@capacitor/core";

const { PushNotifications, LocalNotifications } = Plugins;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function init(navigate: (url: string) => void): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as Record<string, any>).registered) {
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as Record<string, any>).registered = true;

    // Request permission to use push notifications

    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
        } else {
            // Show some error
            console.log("Permission not granted");
        }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener(
        "registration",
        async (token: PushNotificationToken) => {
            console.log("Push registration success, token: " + token.value);
            setNotificationToken(token.value);
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener(
        "registrationError",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
            console.log("Error on registration: " + JSON.stringify(error));
        }
    );

    const showNotification = async (notification: PushNotification) => {
        console.log("PushNotifications: Got a notification");
        console.log(notification);
        /**
                pinpoint.campaign.campaign_id: "_DIRECT"
                pinpoint.notification.silentPush: "0"
                pinpoint.notification.title: "New Billing Report For December"
                pinpoint.openApp: "true"
             */
        const body = notification.data["pinpoint.notification.body"]
            ? notification.data["pinpoint.notification.body"]
            : (notification.data.body || notification.body);

        const title = notification.data["pinpoint.notification.title"]
            ? notification.data["pinpoint.notification.title"]
            : (notification.data.title || notification.title);
        if (
            (!body && !title) ||
            notification.data["pinpoint.notification..silentPush"] == "1"
        ) {
            console.log("No info to display");
            return;
        }

        const url = notification.data.url ||
            notification.data?.data?.url ||
            notification.data?.pinpoint?.url ||
            notification.data?.data?.jsonBody?.url;
        await LocalNotifications.schedule({
            notifications: [
                {
                    title: title,
                    body: body,
                    id: Math.floor(Math.random() * 200) + 1,
                    schedule: { at: new Date(Date.now() + 1000) },
                    actionTypeId: "",
                    extra: {
                        url: url
                    },
                    // icon: "res://farmers_logo_main.png",
                    smallIcon: "ic_notification",
                },
            ],
        });
    };

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
        "pushNotificationReceived",
        async (notification: PushNotification) => {
            console.log(JSON.stringify(notification));
            if (notification.data?.data?.jsonBody?.url) {
                return;
            }

            await showNotification(notification);
        }
    );

    LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction: LocalNotificationActionPerformed) => {
        const url = notificationAction.notification.extra.url;
        const slug = url.split("fairmile://app").pop();
        if (slug) {
            navigate(slug);
        }

        console.log(JSON.stringify(notificationAction));
    });

    // Method called when tapping on a notification
    PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: PushNotificationActionPerformed) => {
            // Android vs iOS
            const url = notification.notification?.data?.url || notification.notification?.data?.data?.jsonBody?.url;
            if (!url) {
                return;
            }

            const slug = url
                .split("fairmile://app")
                .pop();

            // If we are already in the app in ios then just navigate
            if (window.location.pathname != "/login") {
                navigate(slug);
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!(window as any).lastUrl && slug) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).lastUrl = slug;
            }

            console.log(JSON.stringify(notification));
        }
    );
}
