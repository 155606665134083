import { useSelector } from "react-redux";
import { FullAppState } from "../store/state";
import { PolicyRecord } from "../entities/policy";

export const usePolicy = (): PolicyRecord | null => {
    const policy = useSelector((state: FullAppState) => {
        return state.policy.policy;
    });

    return policy;
};