import React, { useEffect, useMemo, useState } from "react";
import "./SnapshotCard.css";

import { useSelector, useDispatch } from "react-redux";
import { FullAppState } from "../../store/state";
import { getMonthKey, getTotalMileageStatsPerMonth } from "../../util/mileage";
import {
  loadCurrentGeotab,
  loadCurrentMileage,
} from "../../store/mileage/actions/mileage";
import { DateUtil } from "../../util/date";

import Card from "../Card/Card";

import "twin.macro";
import ThreeRowCard from "../ThreeRowCard/ThreeRowCard";
import BeforePolicy from "../BeforePolicy/BeforePolicy";
import { useBeforePolicy } from "../../hooks/useBeforePolicy";
import { useDebugManager } from "../../hooks/useDebugManager";

interface Props {
  title: string;
  selectedDevice?: string;
  showAverage?: boolean;
  seeMore?: boolean;
  mileageReports?: boolean;
  currentLocation?: boolean;
  itemGap?: string;
}

const SnapshotCard: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const debugManager = useDebugManager();

  const historicMileageStore = useSelector(
    (state: FullAppState) => state.mileage.historicMileage
  );
  const currentMileage = useSelector(
    (state: FullAppState) => state.mileage.currentMileage
  );

  const policyNumber = useSelector(
    (state: FullAppState) => state.policy.policy?.PolicyNumber
  );

  const devices = useSelector((state: FullAppState) => state.vehicles.items);

  const [intervalKey, setIntervalKey] = useState<NodeJS.Timeout | null>(null);

  const currentMonth = DateUtil.local();
  const currentMonthKey = getMonthKey(currentMonth);
  const lastMonth = currentMonth.subtract(1, "month");
  const lastMonthKey = getMonthKey(lastMonth);

  const decimalPlaces = 0;

  const totalMileagePerMonth = useMemo(() => {
    if (
      !historicMileageStore ||
      Object.keys(historicMileageStore).length == 0 ||
      !currentMileage
    ) {
      return;
    }

    const mileageStats = getTotalMileageStatsPerMonth(
      historicMileageStore,
      currentMileage
    );

    return mileageStats;
  }, [historicMileageStore, currentMileage, devices]);

  const average = useMemo(() => {
    if (!totalMileagePerMonth) {
      return "--";
    }

    let total = 0;
    let count = 0;
    for (const stat of Object.keys(totalMileagePerMonth)) {
      if (totalMileagePerMonth[stat].total > 0) {
        total += totalMileagePerMonth[stat].total;
        count++;
      }
    }

    if (count > 1) {
      return (total / count).toFixed(decimalPlaces);
    }

    return "--";
  }, [totalMileagePerMonth, devices]);

  const periodDriven = useMemo(() => {
    if (debugManager.periodMileage) {
      return debugManager.periodMileage.toFixed(decimalPlaces);
    }
    if (!totalMileagePerMonth || !currentMileage) {
      return;
    }

    return (
      totalMileagePerMonth[currentMonthKey]?.total?.toFixed(decimalPlaces) ||
      "--"
    );
  }, [totalMileagePerMonth, currentMileage, devices]);

  useEffect(() => {
    if (intervalKey || !policyNumber || !devices || devices.length == 0) {
      return;
    }

    const interval = setInterval(() => {
      for (const d of devices) {
        if (currentMileage?.[d.id]) {
          dispatch(loadCurrentGeotab(currentMileage[d.id]));
        }
      }
    }, 30000);

    setIntervalKey(interval);

    return () => clearInterval(interval);
  }, [devices, devices.length, policyNumber, dispatch]);

  useEffect(() => {
    if (devices && devices.length > 0 && policyNumber) {
      for (const d of devices) {
        dispatch(loadCurrentMileage(d.id, policyNumber));
      }
    }
  }, [dispatch, devices, policyNumber, devices.length]);

  const lastMonthDriven =
    (totalMileagePerMonth?.[lastMonthKey] &&
      totalMileagePerMonth[lastMonthKey].total.toFixed(decimalPlaces)) ||
    "--";

  const beforePolicy = useBeforePolicy();

  return (
    <Card
      title={props.title}
      ariaLabel="Overall Mileage Card"
      callToAction="/mileage"
    >
      <BeforePolicy />
      <ThreeRowCard
        row1Title="This Month"
        row1Value={beforePolicy ? "--" : periodDriven || "--"}
        row1Unit="miles"
        row2Title="Last Month"
        row2Value={beforePolicy ? "--" : lastMonthDriven || "--"}
        row2Unit="miles"
        row3Title={props.showAverage ? "Average (3 Months)" : null}
        row3Value={beforePolicy ? "--" : average || "--"}
        row3Unit="miles"
        itemGap={props.itemGap}
      />
    </Card>
  );
};

export default SnapshotCard;
