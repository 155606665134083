import { IonIcon } from "@ionic/react";
import { warning } from "ionicons/icons";
import React from "react";
import { useBeforePolicy } from "../../hooks/useBeforePolicy";

import tw from "twin.macro";
import { useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { useAfterPolicy } from "../../hooks/useAfterPolicy";
import { useHistory } from "react-router";

interface Props {
  message?: string;
  override?: boolean;
}

const WarningLight: React.FC<Props> = (props: Props) => {
  const beforePolicy = useBeforePolicy();
  const afterPolicy = useAfterPolicy();
  const history = useHistory();

  const warningFaults = useSelector((state: FullAppState) => {
    if (!state.faultData.items) {
      return [];
    }

    return state.faultData.items;
  });

  const show =
    props.override ||
    (!beforePolicy && !afterPolicy && warningFaults.length > 0);

  return (
    <div css={show ? tw`pt-2 pr-4` : ""}>
      {show && (
        <button
          tw="flex m-1 cursor-pointer text-left"
          onClick={() => {
            history.push("/faults");
          }}
        >
          <IonIcon
            tw="ml-4 mr-2"
            style={{ color: "#ffae00" }}
            className="alert-circle"
            aria-label="Warning Light"
            icon={warning}
          />
          <p tw="text-sm text-headinggray">
            {`A vehicle warning light fault has been detected ${warningFaults.length} times this week. Click to see more info.`}
          </p>
        </button>
      )}
    </div>
  );
};

export default WarningLight;
