import { ThunkAction } from "redux-thunk";
import {
    getDevices,
    Device,
    saveEntity,
    LogRecord,
    getFullLogRecord,
} from "./../../../geotab/helpers";
import { AnyAction } from "redux";
import { FullAppState } from "../../state";
import { CLEAR_STORE } from "../../login/actions/login";
import { DateUtil } from "../../../util/date";

export const LOAD_VEHICLES = "LOAD_VEHICLES";
export const SET_POSITIONS = "SET_POSITIONS ";
export const CHANGE_NAME = "CHANGE_NAME";
export const LOADING_DEVICES = "LOADING_DEVICES";
export const DEVICE_ERROR = "DEVICE_ERROR";

interface ILoadVehicles {
    type: typeof LOAD_VEHICLES | typeof CLEAR_STORE;
    devices: Device[];
}

interface ISetPositions {
    type: typeof SET_POSITIONS
    positions: Record<string, LogRecord>
}

interface IChangeName {
    type: typeof CHANGE_NAME;
    device: Device;
}

interface ILoadingDevices {
    type: typeof LOADING_DEVICES;
    loading: boolean
}

interface IDeviceError {
    type: typeof DEVICE_ERROR;
    error: boolean
}

export type DeviceErrorType = IDeviceError;
export type LoadingDevicesType = ILoadingDevices;
export type SetPositionsType = ISetPositions;
export type VehiclesActionTypes = ILoadVehicles;
export type VehicleNameChange = IChangeName;

export function loadDeviceLocation(): ThunkAction<
    Promise<void | ILoadVehicles>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch, getState) {
        try {
            const state = getState();
            const devices = state.vehicles.items;
            if (devices == null || devices.length == 0) {
                return;
            }

            const promises: Promise<LogRecord[]>[] = [];
            for (const d of devices) {
                const p = getFullLogRecord(d.id, DateUtil.utc().toISOString(), DateUtil.utc().toISOString());
                promises.push(p);
            }

            const res: LogRecord[][] = (await Promise.all(promises));
            const map: Record<string, LogRecord> = {};

            for (const r of res) {
                if (r[0]) {
                    map[r[0].device.id] = r[0];
                }
            }

            dispatch(setPosition(map));
        } catch (error) {
            console.log("Error in getting devices ", JSON.stringify(error));
        }
    };
}

export function loadDevices(): ThunkAction<
    Promise<void | ILoadVehicles>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return async function (dispatch, getState) {
        try {
            dispatch(setLoading(true));
            const state = getState();
            let p: Promise<Device[]> | null = null;
            if (state && state.vehicles.items && state.vehicles.items.length > 0) {
                p = new Promise((resolve) => resolve(state.vehicles.items));
            } else {
                p = getDevices();
            }

            const devices = await p;
            dispatch(setError(false));
            dispatch(load(devices));
        } catch (error) {
            console.log("Error in getting devices ", JSON.stringify(error));
            dispatch(setError(true));
        }
    };
}

export function setPosition(positions: Record<string, LogRecord>): ISetPositions {
    return { type: SET_POSITIONS, positions };
}

export function load(devices: Device[]): VehiclesActionTypes {
    return { type: LOAD_VEHICLES, devices };
}

export function changeVehicleName(
    deviceId: string,
    newName: string
): ThunkAction<
    Promise<void | IChangeName>,
    FullAppState,
    AnyAction,
    AnyAction
> {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            const state = getState();
            const devices = state.vehicles.items;
            const policyNumber = state.policy.policy?.PolicyNumber;
            const device = devices.find((device) => device.id === deviceId);
            if (device && policyNumber) {
                saveEntity(policyNumber, deviceId, newName)
                    .then((saved) => {
                        if (saved) {
                            dispatch(doChangeVehicleName({ ...device, name: newName }));
                            resolve();
                        }
                    })
                    .catch((error) => reject(error));
            }
        });
    };
}

export function doChangeVehicleName(device: Device): VehicleNameChange {
    return { type: CHANGE_NAME, device };
}

export function setLoading(loading: boolean): LoadingDevicesType {
    return { type: LOADING_DEVICES, loading };
}

export function setError(error: boolean): DeviceErrorType {
    return { type: DEVICE_ERROR, error };
}
