import { IonRow, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewsfeedItem from "../../components/NewsFeed/newsfeedItem";
import { useViewport } from "../../hooks/useViewport";
import i18n from "../../i18n";
import { loadNewsFeed } from "../../store/newsfeed/actions/newsfeed";
import { INewsFeedItem } from "../../store/newsfeed/state/newsfeedState";
import { FullAppState } from "../../store/state";
import { isMobileViewport } from "../../util/platform";

import "twin.macro";

const NewsfeedPage: React.FC = () => {
  const viewport = useViewport();
  const [intervalKey, setIntervalKey] = useState<NodeJS.Timeout | null>(null);
  const newsFeedList = useSelector((state: FullAppState) => state.newsfeed) as {
    items: INewsFeedItem[];
  };
  const dispatch = useDispatch();

  const policyNumber = useSelector(
    (state: FullAppState) => state?.policy?.policy?.PolicyNumber
  );

  const isLoggedIn = useSelector((state: FullAppState) => {
    return state.login.isLoggedIn;
  });
  useEffect(() => {
    if (intervalKey || !policyNumber) {
      return;
    }

    const interval = setInterval(() => {
      if (isLoggedIn) {
        dispatch(loadNewsFeed());
      }
    }, 17000);

    if (isLoggedIn && newsFeedList.items.length === 0) {
      dispatch(loadNewsFeed());
    }

    setIntervalKey(interval);

    return () => clearInterval(interval);
  }, [policyNumber, dispatch]);
  return (
    <div style={{ height: "100%" }} tw="overflow-y-scroll">
      {!isMobileViewport(viewport) && (
        <IonRow>
          <IonText className="snapShot-desktop">
            <div role="heading" aria-level={3} tw="ml-8 mb-8">
              {i18n.t("newsfeed.title")}
            </div>
          </IonText>
        </IonRow>
      )}
      <div tw="mx-16 mb-4 rounded-md bg-white">
        {newsFeedList.items.map((item, index) => (
          <div key={index} tw="border-b border-dashed">
            <NewsfeedItem
              fullPage={true}
              item={item}
              mobile={isMobileViewport(viewport)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsfeedPage;
