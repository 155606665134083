import { DateUtil } from "../util/date";
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { FullAppState } from "../store/state";
import { useDebugManager } from "./useDebugManager";

export const useBeforePolicy = (): boolean => {
    const dm = useDebugManager();
    const policy = useSelector((state: FullAppState) => {
        return state.policy.policy;
    });

    if (!policy) {
        return false;
    }

    if (dm.policyState != null && dm.policyState == 0) {
        return true;
    }

    return dayjs().diff(DateUtil.local(policy?.PolicyEffectiveDate), "seconds") < 0;
};