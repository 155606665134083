import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { Map as AdvMap } from "@seven-fields-software-ltd/geotab-map-js";
import Leaflet from "leaflet";

import { Trip, Device, Exception, FaultData } from "../../geotab/helpers";
import { IonSpinner } from "@ionic/react";
import dayjs from "dayjs";
import { useLocation } from "react-router";
import { PageView } from "../../telemetry/TelemetryManager";

import "./map.css";
import { useDispatch } from "react-redux";
import {
  loadSingleTrip,
  loadTripByDeviceAndTime,
  loadTripData,
  loadTrips,
  loadTripsData,
} from "../../store/trips/actions/trips";
import { loadExceptionPoints } from "../../store/exceptions/actions/exceptions";
import { loadFaultDataPoints } from "../../store/faultData/actions/faultData";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

import { loadDeviceLocation } from "../../store/vehicles/actions/vehicles";
import Page from "../Page";
import { useDevice } from "../../hooks/useDevice";
import { useIsMobile } from "../../hooks/useIsMobile";

import tw from "twin.macro";
import CurrentLocationInfo from "./CurrentLocationInfo";
import TripInfo from "./TripInfo";
import { useIsIOS } from "../../hooks/useIsIOS";
import { useDebugManager } from "../../hooks/useDebugManager";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const L = Leaflet as any;
const redMarker = L.AwesomeMarkers.icon({
  icon: "coffee",
  markerColor: "red",
}) as Leaflet.Icon;

const Map: React.FC = () => {
  const debugManager = useDebugManager();
  const vehicles = useSelector<FullAppState, Device[]>(
    (state: FullAppState) => state.vehicles.items
  );
  const trips = useSelector<FullAppState, Trip[]>((state) => state.trips.items);
  const failedTrips = useSelector(
    (state: FullAppState) => state.trips.failedItems
  );
  const exceptions = useSelector<FullAppState, Exception[]>(
    (state) => state.exceptions.items
  );
  const faults = useSelector<FullAppState, FaultData[]>(
    (state) => state.faultData.items
  );

  const [selectedVehicles, setSelectedVehicles] = useState<Device[]>([]);
  const [selectedTrips, setSelectedTrips] = useState<Trip[]>([]);
  const [, setVehicleState] = useState<Device | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const mounted = useRef(true);
  const thunkDispatch = useDispatch<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ThunkDispatch<FullAppState, any, AnyAction>
  >();
  const [interval, setIntervalKey] = useState<NodeJS.Timeout | null>(null);

  const [tripId, setTripId] = useState<string | null>(null);

  const [, setError] = useState<string | null>(null);

  const [
    lastLocationUpdate,
    setLastLocationUpdate,
  ] = useState<dayjs.Dayjs | null>(null);

  const refreshVehiclesLocations = () => {
    if (interval) {
      //   clearInterval(interval);
      return;
    }
    setIntervalKey(
      setInterval(
        () => {
          console.log("refreshing devices locations");
          const now = dayjs.utc();
          if (
            lastLocationUpdate != null &&
            now.diff(lastLocationUpdate, "second") < 15
          ) {
            return;
          }

          setLastLocationUpdate(now);
          thunkDispatch(loadDeviceLocation());

          if (debugManager.moveDeviceInterval && selectedVehicles.length > 0) {
            setSelectedVehicles([...selectedVehicles]);
          }
        },
        debugManager.moveDeviceInterval ? 2000 : 15000
      )
    );
  };

  const [tripContext, setTripContext] = useState<{
    deviceId: string;
    tripStart: string;
    tripStop: string;
  } | null>(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const loadPointsForException = async (exception: Exception) => {
    if (exception.points?.length > 0) {
      return;
    }
    await thunkDispatch(loadExceptionPoints(exception));
  };

  const loadPointsForFault = async (fault: FaultData) => {
    if (fault.points?.length > 0) {
      return;
    }
    await thunkDispatch(loadFaultDataPoints(fault));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tid = params.get("trip");
    if (!tid) {
      const deviceId = params.get("deviceId");
      const tripStart = params.get("tripStart");
      const tripStop = params.get("tripStop");

      if (deviceId && tripStart && tripStop) {
        setTripContext({ deviceId, tripStart, tripStop });
      }
    } else {
      setTripId(tid);
    }
  }, [location, vehicles]);

  const deviceId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("device");
  }, [location]);

  const exceptionId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("exception");
  }, [location]);

  const faultId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("fault");
  }, [location]);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    if (!vehicles || vehicles.length == 0) {
      return;
    }

    if (!tripId && tripContext) {
      const device = vehicles.find((v) => v.id == tripContext.deviceId);
      if (!device) {
        return;
      }
      const selectedTrip = trips.find(
        (t) =>
          t.startTime === tripContext.tripStart &&
          t.device == tripContext.deviceId
      );

      if (selectedTrip) {
        setTripId(selectedTrip.id);
        return;
      }

      if (!isLoading) {
        setIsLoading(true);
        thunkDispatch(
          loadTripByDeviceAndTime(
            tripContext.deviceId,
            tripContext.tripStart,
            tripContext.tripStop
          )
        ).catch((c) => {
          alert(c);
        });
      }
    }

    if (tripId) {
      if (tripId === "all") {
        PageView("Map", { pageType: "Trips" });
        let loadData = false;
        trips.forEach((trip) => {
          if (trip.points.length === 0 || !trip.loadedExceptions)
            loadData = true;
        });
        if (loadData) {
          setIsLoading(true);
          thunkDispatch(loadTripsData()).then(() => {
            setIsLoading(false);
            setSelectedTrips(trips);
          });
        } else {
          setSelectedTrips(trips);
          setIsLoading(false);
        }
      } else {
        if (failedTrips[tripId]) {
          setIsLoading(false);
          setError("Error loading trip");
          return;
        }
        const selectedTrip = trips.find((t) => t.id === tripId);
        if (!selectedTrip) {
          thunkDispatch(loadSingleTrip(tripId));
          return;
        } else {
          if (
            !selectedTrip.loadedExceptions ||
            selectedTrip?.points?.length === 0
          ) {
            setIsLoading(true);
            thunkDispatch(loadTripData(selectedTrip));
            return;
          } else {
            setSelectedTrips([selectedTrip]);
            setIsLoading(false);
          }
        }
      }
    }
  }, [trips, tripId, tripContext, failedTrips, mounted.current]);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    if (!deviceId || !vehicles || vehicles.length == 0) {
      return;
    }

    const vehicleWithNoPosition = vehicles.find((v) => !v.position);
    if (!interval || !vehicleWithNoPosition) {
      for (const v of vehicles) {
        if (!v.position) {
          v.position = { x: 0, y: 0 };
        }
      }

      refreshVehiclesLocations();
    }

    if (deviceId === "all") {
      if (debugManager.showOnlyDebugDevices) {
        setSelectedVehicles(debugManager.devices || []);
      } else {
        setSelectedVehicles([...vehicles, ...(debugManager.devices || [])]);
      }
      PageView("Map", { pageType: "currentLocationAll" });
    } else {
      const selectedVehicle = vehicles.find(
        (vehicle) => vehicle.id === deviceId
      );
      if (selectedVehicle) {
        PageView("Map", { pageType: "Vehicle" });
        setVehicleState(selectedVehicle);
        setSelectedVehicles([selectedVehicle]);
      }
    }
    setIsLoading(false);
  }, [deviceId, vehicles, mounted.current]);

  const setFakeTripForException = (selectedException: Exception) => {
    const newException = exceptions.find((e) => e.id == selectedException.id);
    if (!newException) {
      setError("Unabled to load map");
      return;
    }

    const exceptionTrip: Trip = {
      device: newException.device,
      distance: 0,
      driverName: "",
      id: newException.id,
      startPoint: newException.points[0],
      stopPoint: newException.points[newException.points.length - 1],
      exceptions: [newException],
      points: newException.points,
      startTime: newException.startTime,
      endTime: newException.endTime,
      drivingDuration: dayjs.duration(100, "minute"),
      stopDuration: dayjs.duration(100, "minute"),
    };

    setSelectedTrips([exceptionTrip]);
    setIsLoading(false);
  };
  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    if (exceptionId || faultId) {
      PageView("Map", { pageType: exceptionId ? "Exception" : "Fault" });
      const selectedException = exceptionId
        ? exceptions.find((e) => e.id === exceptionId)
        : null;
      const selectedFault = faultId
        ? faults.find((e) => e.id === faultId)
        : null;

      if (selectedException || selectedFault) {
        const startTime =
          selectedException?.startTime || selectedFault?.dateTime;
        const endTime = selectedException?.startTime || selectedFault?.dateTime;
        const device = selectedException?.device || selectedFault?.device.id;

        if (!startTime || !endTime || !device) {
          return;
        }

        const possibleTrip = trips.find((t) => {
          return (
            t.startTime <= startTime &&
            t.endTime >= endTime &&
            t.device == device
          );
        });

        const loadedTrips =
          selectedException?.loadedTrips || selectedFault?.loadedTrips;
        if (!possibleTrip && !loadedTrips && selectedException) {
          if (selectedFault) {
            selectedFault.loadedTrips = true;
          }

          if (selectedException) {
            selectedException.loadedTrips = true;
          }

          thunkDispatch(loadTrips(startTime, endTime, device, 1));
        } else if ((selectedFault || loadedTrips) && !possibleTrip) {
          const points = selectedException?.points || selectedFault?.points;
          if (!points || points.length == 0) {
            if (selectedException) {
              loadPointsForException(selectedException)
                .then(() => {
                  setFakeTripForException(selectedException);
                })
                .catch((e) => {
                  console.log(e);
                });
            } else if (selectedFault) {
              loadPointsForFault(selectedFault);
            }
          } else {
            if (selectedException) {
              setFakeTripForException(selectedException);
            } else if (selectedFault) {
              setSelectedVehicles([
                {
                  id: selectedFault.device.id,
                  name:
                    selectedFault.diagnostic.name ||
                    selectedFault.diagnostic.id,
                  activeFrom: selectedFault.dateTime,
                  activeTo: selectedFault.dateTime,
                  position: selectedFault.points[0],
                  serialNumber: "00000",
                  vehicleIdentificationNumber: "00000",
                },
              ]);
            }
          }
          return;
        } else if (possibleTrip) {
          setTripId(possibleTrip.id);
        }
      }
    }
  }, [exceptionId, exceptions, trips, faults, faultId, mounted.current]);

  useEffect(() => {
    document.title = "FairMile® | Maps";
  }, []);

  const initialDevice = useDevice("device");
  const [deviceSelector, setDeviceSelector] = useState<Device | null>();

  useEffect(() => {
    if (initialDevice && !deviceSelector) {
      setDeviceSelector(initialDevice);
    }
  }, [initialDevice]);

  const isMobile = useIsMobile();

  const showMap =
    (selectedTrips.length > 0 &&
      selectedTrips[0].points != null &&
      selectedTrips[0].points.length > 0) ||
    selectedVehicles.length > 0;
  const isIOS = useIsIOS();

  return (
    <Page
      showBack={true}
      title="Map"
      showVehicleSelector={tripId == null && selectedTrips.length == 0}
      setDevice={setDeviceSelector}
      defaultDevice={deviceSelector || undefined}
      canScrollY={false}
      renderChildren={(children) => {
        return (
          <div
            id="mapchild"
            css={isIOS ? tw`h-full` : ""}
            tw="w-full flex-grow box-border"
          >
            {children}
          </div>
        );
      }}
    >
      {!showMap && (
        <div tw="w-full h-full flex items-center justify-center text-farmersblue">
          <IonSpinner />
        </div>
      )}
      <div
        tw="h-full"
        id={isMobile ? "mobile-map" : "desktop-map"}
        css={tripId == null && selectedTrips.length == 0 ? tw`pt-4` : ""}
        aria-label={`${
          tripId == null && faultId == null
            ? "Current Location Map"
            : tripId != null
            ? "Trip Map"
            : "Fault Map"
        }`}
        role="application"
      >
        {showMap ? (
          <div tw="h-full" css={selectedVehicles.length > 0 ? tw`pt-4` : ""}>
            <div tw="block" style={{ height: "100%" }}>
              <AdvMap
                center={
                  selectedVehicles[0]
                    ? selectedVehicles[0].position
                    : selectedTrips?.[0].points?.[0] ||
                      selectedTrips[0].stopPoint || { y: 0, x: 0 }
                }
                // eslint-disable-next-line react/no-children-prop
                children={() => {
                  return (
                    <div
                      tw="ml-16 mt-2 bg-white shadow rounded-md"
                      className="leaflet-top leaflet-left"
                    >
                      {tripId == null &&
                        selectedTrips.length == 0 &&
                        faultId == null && (
                          <div tw="pr-4 ml-4">
                            <CurrentLocationInfo device={selectedVehicles[0]} />
                          </div>
                        )}
                      {tripId != null ||
                        (selectedTrips.length > 0 && (
                          <TripInfo trip={selectedTrips[0]} />
                        ))}
                      {faultId != null && <div tw="mt-4"></div>}
                    </div>
                  );
                }}
                maxZoom={18}
                minZoom={4}
                zoom={13}
                devices={selectedVehicles}
                trips={selectedTrips}
                openPopup={true}
                customTileUrl="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY29ubmVjdGVkY292ZXJhZ2UtbWF0dCIsImEiOiJja2owdXhvZTAxeHI2MnFyd256NnplcHNiIn0.f6SfNygN0KcCgtVa2HIcDw"
                customTileAttributation='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                exceptionMarkerIcon={redMarker}
              />
            </div>
          </div>
        ) : (
          <div aria-hidden={true}> </div>
        )}
      </div>
    </Page>
  );
};

export default Map;
