import { Exception, Rule } from "../../../geotab/helpers";
import { CLEAR_STORE } from "../../login/actions/login";
import {
    LoadExceptionsType,
    LoadExceptionTyper as LoadExceptionType,
    LOAD_EXCEPTION,
    LOAD_EXCEPTIONS,
    SET_RULES,
    SetRulesType,
} from "../actions/exceptions";
import IExceptionsState from "../state/exceptionsState";

const initalState: IExceptionsState = {
    items: [],
    fetchedTimes: [],
    rules: {}
};

const dedup = (exceptions: Exception[], newExceptions: Exception[], rules: Record<string, Rule>) => {
    for (const e of newExceptions) {
        const found = exceptions.findIndex(t => t.id == e.id);
        e.name = rules[e.ruleId].name;
        if (found < 0) {
            e.name = rules[e.ruleId].name;
            exceptions.push(e);
        } else {
            if (exceptions[found].points && exceptions[found].points.length > 0) {
                if (e.points == null || e.points.length == 0) {
                    e.points = exceptions[found].points;
                }
            }
            exceptions[found] = e;
        }
    }

    return exceptions;
};

const exceptionSort = (a: Exception, b: Exception) => {
    if (a.startTime < b.startTime) {
        return 1;
    }

    return -1;
};

export default function (
    defaultState = initalState,
    action: LoadExceptionsType | LoadExceptionType | SetRulesType
): IExceptionsState {
    switch (action.type) {
        case LOAD_EXCEPTIONS: {
            const fetchedTimes = defaultState.fetchedTimes;
            if (action.fetchedTimes) {
                fetchedTimes.push(action.fetchedTimes);
            }
            return { ...defaultState, items: dedup(defaultState.items, action.exceptions, defaultState.rules).sort(exceptionSort), fetchedTimes: fetchedTimes };
        }
        case LOAD_EXCEPTION: {
            return { ...defaultState, items: dedup(defaultState.items, [action.exception], defaultState.rules).sort(exceptionSort) };
        }
        case SET_RULES: {
            return { ...defaultState, rules: action.rules };
        }
        case CLEAR_STORE: {
            return initalState;
        }
        default: {
            return defaultState;
        }
    }
}
