import { Action } from "redux";
import ICardState from "../state/cardState";

const initialState: ICardState = {
    items: []
};


export default function (state: ICardState = initialState, action: Action): ICardState {
    switch (action.type) {
        default:
            return state;
    }
}