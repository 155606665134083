import React, { useState, useRef, useEffect } from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { MainLogin } from "./MainLogin";
import { NewPassword } from "./NewPassword";
import "./Login.css";
import { Redirect, useLocation } from "react-router-dom";
import { clearNotification } from "../../store/notifications/actions/notificationsActions";
import { getPolicy } from "../../store/policy/actions/actions";
import {
  getTermsAccepted,
  getUuid,
  setUuid,
} from "../../system/StorageManager";
import { getLoggedUser, checkIfUserLogged } from "../../aws/util";
import {
  showTerms as goToTerms,
  login,
  logout,
} from "../../store/login/actions/login";
import { ConfirmSignup } from "./Register/ConfirmSignup";

import "twin.macro";
import { TermsAndConditions } from "./TermsAndConditions2";

export const Login: React.FC = () => {
  const requireNewPassword = useSelector(
    (state: FullAppState) => state.login.requireNewPassword
  );

  const requireConfirmation = useSelector(
    (state: FullAppState) => state.login.requireConfirmation
  );

  const userName = useSelector((state: FullAppState) => state.login.userName);

  const showTerms = useSelector((state: FullAppState) => state.login.showTerms);
  const isLoggedIn = useSelector(
    (state: FullAppState) => state.login.isLoggedIn
  );
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const slideRef = useRef(null);
  const policyNumber = useSelector(
    (state: FullAppState) =>
      (state.login &&
        state.login.userObject &&
        state.login.userObject.attributes &&
        state.login.userObject.attributes.policyNumber) ||
      null
  );

  const swipeTo = async (index: number) => {
    if (slideRef && slideRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const slides: any = slideRef.current;
      await slides.lockSwipeToNext(false);
      await slides.lockSwipeToPrev(false);
      slides.slideTo(index);
      await slides.lockSwipeToNext(true);
      await slides.lockSwipeToPrev(true);
      setSelectedPage(index);
    }
  };
  const mounted = useRef(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoggedIn && !showTerms) {
      dispatch(clearNotification());
    }
    mounted.current = true;
    document.title = "FairMile® | Login";
    return () => {
      mounted.current = false;
    };
  }, []);

  const location = useLocation();
  let autoLogin = true;
  if (process.env.NODE_ENV == "development") {
    autoLogin = location.search.indexOf("autoLogin=false") < 0;
  }

  useEffect(() => {
    if (!autoLogin) {
      return;
    }
    if (!isLoggedIn && mounted.current) {
      checkIfUserLogged().then((loggedIn) => {
        if (loggedIn) {
          getLoggedUser().then((user) => {
            if (user) {
              getTermsAccepted(
                policyNumber ||
                  user.userObject?.attributes["custom:policyContractNumber"]
              ).then((accepted) => {
                if (accepted) {
                  console.log("Calling logging in");
                  dispatch(login(user, user.email!));
                } else {
                  dispatch(goToTerms(user, user.email!));
                }
              });
              dispatch(
                getPolicy(
                  user.userObject.attributes["custom:policyContractNumber"]
                )
              );
            }
          });
        }
      });
    }

    getUuid().then((uuid) => {
      if (uuid === null || uuid === undefined) setUuid();
    });
  }, [dispatch, isLoggedIn, policyNumber, mounted]);

  useEffect(() => {
    if (!mounted) return;
    // eslint-disable-next-line no-empty
    if (requireNewPassword && selectedPage === 0) {
      console.log("Requires new password");
      swipeTo(1);
    }

    if (requireConfirmation && selectedPage === 0) {
      console.log("Requires confirmation code");
      swipeTo(2);
    }

    if (showTerms && (selectedPage === 0 || selectedPage === 1)) {
      console.log("Showing terms!");
      swipeTo(3);
    }

    if (
      !showTerms &&
      !requireNewPassword &&
      !requireConfirmation &&
      selectedPage !== 0
    ) {
      swipeTo(0);
    }
  }, [
    selectedPage,
    requireNewPassword,
    requireConfirmation,
    showTerms,
    mounted,
  ]);
  if (isLoggedIn && !showTerms) {
    return <Redirect to="/dashboard" />;
  }

  const slideOpts = {
    initialSlide: selectedPage,
    speed: 400,
    allowSlidePrev: false,
    allowSlideNext: false,
  };

  return (
    <IonSlides
      ref={slideRef}
      className="loginPage"
      pager={false}
      options={slideOpts}
    >
      <IonSlide>
        <section tw="w-full h-full" aria-labelledby="Login Form" role="form">
          <MainLogin />
        </section>
      </IonSlide>
      <IonSlide>
        <NewPassword />
      </IonSlide>
      <IonSlide>
        <ConfirmSignup
          isUnfinishedSignin={true}
          setError={(err) => {
            console.log(err);
          }}
          email={userName}
          goBack={() => dispatch(logout())}
        />
      </IonSlide>
      <IonSlide>
        <TermsAndConditions />
      </IonSlide>
    </IonSlides>
  );
};
