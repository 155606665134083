import React from "react";

import { CardItemType, ICardItem } from "../../store/cardmock/state/cardState";
import TripRow from "./TripRow";
import "./ListCard.css";

import NoDataRow from "./NoDataRow";
import FaultExceptionRow from "./FaultExceptionRow";
import Card from "../Card/Card";

import "twin.macro";
import { useHistory } from "react-router";
import BeforePolicy from "../BeforePolicy/BeforePolicy";

interface Props {
  title?: string;
  subtitle?: string;
  callToAction?: string;
  cards: ICardItem[];
  ariaLabel: string;
  leftColumnColor?: string;
  infoText?: string;
}

const ListCard: React.FC<Props> = ({
  title,
  cards,
  callToAction,
  ariaLabel,
  leftColumnColor,
  infoText,
}: Props) => {
  const history = useHistory();

  return (
    <Card
      title={title}
      ariaLabel={ariaLabel}
      leftColumnColor={leftColumnColor}
      infoText={infoText}
      callToAction={callToAction}
    >
      <ul tw="divide-y divide-gray-200">
        <li tw=""></li>
        {cards.length == 0 && <NoDataRow />}

        {title == "Trips" && (
          <div tw="border-none">
            <BeforePolicy message="Trip" />
          </div>
        )}
        {cards.map((item, index) => {
          return (
            <li key={index} tw="px-4 py-4">
              {item.typeName == CardItemType.Trip && (
                <TripRow
                  onClick={() => {
                    if (item.callToAction) {
                      history.push(item.callToAction);
                    }
                  }}
                  startTime={item.activeFrom}
                  endTime={item.activeTo}
                  distance={item.distance || "--"}
                />
              )}

              {item.typeName != CardItemType.Trip && item.title && (
                <FaultExceptionRow
                  cardItemType={item.typeName}
                  links={item.titleLinks}
                  onClick={() => {
                    if (item.callToAction) {
                      history.push(item.callToAction);
                    }
                  }}
                  eventName={item.title}
                  startTime={item.activeFrom}
                  endTime={item.activeTo}
                />
              )}
            </li>
          );
        })}
      </ul>
    </Card>
  );
};

export default ListCard;
