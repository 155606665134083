import { useLocation } from "react-router";
import { isIOS } from "../util/platform";
import { useDebugManager } from "./useDebugManager";

export const useIsIOS = (): boolean => {
    const debugManager = useDebugManager();
    let forceIOS = false;
    if (process.env.NODE_ENV == 'development') {
        const location = useLocation();
        forceIOS = location.search.indexOf("platform=ios") >= 0;
    }

    return isIOS() || (debugManager && debugManager.platformOverride == "ios") || forceIOS;
};