import React from "react";
import { useSelector } from "react-redux";
import { Device } from "../../geotab/helpers";
import { FullAppState } from "../../store/state";
import "./VehicleList.css";
import { VehicleRecordWithGeotab } from "../../entities/policy";
import VehicleCard from "../VehicleCard/VehicleCard2";
import tw from "twin.macro";
import UnpluggedDevice from "./UnpluggedDevice";
import { isMobileViewport } from "../../util/platform";
import { useViewport } from "../../hooks/useViewport";
import PolicyVehicleCard from "../VehicleCard/PolicyVehicleCard";
import { useDebugManager } from "../../hooks/useDebugManager";

interface TabsProps {
  selectedTab: number;
  renderDeviceCard?: boolean;
}

const displayVehicles = (
  policyVehicles: VehicleRecordWithGeotab[],
  selectedTab: number,
  loopRender?: number
): React.ReactNode => {
  if (selectedTab == 0) {
    if (loopRender && loopRender > 1) {
      let elements: React.ReactNode[] = [];
      for (let i = 0; i < loopRender; i++) {
        const e = policyVehicles.map((item, index) => {
          return (
            <div key={(i + 1) * 1000 + index}>
              {selectedTab == 0 &&
                (item.Device ? (
                  <VehicleCard item={item} />
                ) : (
                  <PolicyVehicleCard item={item} />
                ))}
            </div>
          );
        });

        elements = [...elements, ...e];
      }

      return elements;
    }

    return policyVehicles.map((item, index) => {
      return (
        <div key={(index + 1) * 1000 + index}>
          {selectedTab == 0 &&
            (item.Device ? (
              <VehicleCard item={item} />
            ) : (
              <PolicyVehicleCard item={item} />
            ))}
        </div>
      );
    });
  } else {
    return <></>;
  }
};

const VehicleList: React.FC<TabsProps> = (props: TabsProps) => {
  const debugManager = useDebugManager();
  const viewport = useViewport();
  const vehicles = useSelector(
    (state: FullAppState) => state.vehicles.items
  ) as Device[];

  const policyVehicles = useSelector((state: FullAppState) => {
    const policyVehicles: VehicleRecordWithGeotab[] = [];
    const keys = Object.keys(state.policy.vehicles);
    keys.forEach((k) => {
      policyVehicles.push(state.policy.vehicles[k]);
    });

    policyVehicles.forEach((policyVehicle) => {
      vehicles.forEach((v) => {
        if (policyVehicle.VIN == v.vehicleIdentificationNumber) {
          policyVehicle.Device = v;
        }
      });
    });

    //Sort by if there is a device installed
    return policyVehicles.sort((a, b) => {
      if (a.Device && !b.Device) {
        return -1;
      } else if (b.Device && !a.Device) {
        return 1;
      }

      return 0;
    });
  });

  return (
    <div
      tw="flex flex-col flex-grow"
      css={isMobileViewport(viewport) ? tw`mr-8` : ""}
    >
      {displayVehicles(
        policyVehicles,
        props.selectedTab,
        debugManager.loopDeviceNumber
      )}

      {vehicles.map((item, index) => {
        if (props.selectedTab === 1) {
          const policy = policyVehicles.find((v) => {
            return v.VIN == item.vehicleIdentificationNumber;
          });

          return (
            <div
              key={100000 * (index + 1) + index}
              style={isMobileViewport(viewport) ? {} : { width: "454px" }}
            >
              <UnpluggedDevice
                device={item}
                vehicleRecord={policy}
                overrideState={debugManager.deviceCardState}
              />
            </div>
          );
        } else {
          return <div key={100000 * (index + 1) + index}></div>;
        }
      })}
    </div>
  );
};

export default VehicleList;
