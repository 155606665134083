import React from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/react";
import { IActionItem } from "./index";
import "./actionList.css";
import "twin.macro";
interface Props {
  items: IActionItem[];
  iconPosition: "right" | "left";
}

const ActionList: React.FC<Props> = ({ items, iconPosition }: Props) => {
  return (
    <IonGrid>
      <IonRow>
        {items.map<JSX.Element>((item, key) => {
          return (
            <IonCol size="12" key={key}>
              <IonItem
                className="custom-item"
                tw="text-farmersblue"
                button
                onClick={item.onClicked}
              >
                {iconPosition === "left" && item.icon && (
                  <IonIcon
                    className="action-icon"
                    icon={item.icon}
                    aria-label="Action Icon"
                  />
                )}
                <IonLabel>{item.name}</IonLabel>
                {iconPosition === "right" && item.icon && (
                  <IonIcon
                    className="action-icon"
                    icon={item.icon}
                    aria-label="Action Icon"
                  />
                )}
              </IonItem>
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
};

export default ActionList;
