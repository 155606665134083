export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export interface INotification {
  type: "warning" | "danger" | "success";
  message: string;
  duration: number;
}

export interface INotificationAction {
  type: typeof ADD_NOTIFICATION | typeof REMOVE_NOTIFICATION | typeof CLEAR_NOTIFICATION;
  notification?: INotification;
  index?: number;
}

export function addNotification(
  notification: INotification
): INotificationAction {
  return {
    type: ADD_NOTIFICATION,
    notification,
  };
}

export function removeNotification(index: number): INotificationAction {
  return {
    type: REMOVE_NOTIFICATION,
    index,
  };
}

export function clearNotification(): INotificationAction { 
  return {
    type: CLEAR_NOTIFICATION
  };
}