import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { VehicleRecordWithGeotab } from "../../entities/policy";
import i18n from "../../i18n";
import { useHistory } from "react-router";
import { ClickEvent } from "../../telemetry/TelemetryManager";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { AnyAction } from "redux";
import { changeVehicleName } from "../../store/vehicles/actions/vehicles";
import { documentText, location, map } from "ionicons/icons";
import { loadTrips } from "../../store/trips/actions/trips";
import CustomInput from "../CustomInput";

import dayjs from "dayjs";
import { getMonthKey } from "../../util/mileage";
import { useViewport } from "../../hooks/useViewport";
import { isMobileViewport } from "../../util/platform";
import { DateUtil } from "../../util/date";

import Card from "../Card/Card";
import ThreeRowCard from "../ThreeRowCard/ThreeRowCard";
import VehicleCardHeader from "./VehicleCardHeader";

import tw from "twin.macro";
import BeforePolicy from "../BeforePolicy/BeforePolicy";
import AfterPolicy from "../AfterPolicy/AfterPolicy";
import { useAfterPolicy } from "../../hooks/useAfterPolicy";
import { useBeforePolicy } from "../../hooks/useBeforePolicy";
import { loadWarningLightData } from "../../store/faultData/actions/faultData";
import WarningLight from "../WarningLight/WarningLight";
import { useDebugManager } from "../../hooks/useDebugManager";

interface Props {
  item: VehicleRecordWithGeotab;
}

const VehicleCard: React.FC<Props> = ({ item }: Props) => {
  const debugManager = useDebugManager();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const clickToTelemetry = (pageTo: string, id: string) => {
    ClickEvent("VehicleList", { pageTo: pageTo, id: id });
  };
  const [error, setError] = useState("");

  const [newDeviceName, setNewDeviceName] = useState("");
  const [isSavingDevice, setIsSavingDevice] = useState(false);
  const [lastTrip, setlastTrip] = useState("");

  const isAfterPolicy = useAfterPolicy() || item.RequestType == "D";

  const decimalPlaces = 0;

  const thunkDispatch = useDispatch<
    ThunkDispatch<FullAppState, never, AnyAction>
  >();
  const editVehicleName = (deviceId: string) => {
    setIsSavingDevice(true);
    setError("");
    thunkDispatch(changeVehicleName(deviceId, newDeviceName))
      .then(() => {
        setIsSavingDevice(false);
        setShowModal(false);
        setNewDeviceName("");
      })
      .catch((e) => {
        setIsSavingDevice(false);
        console.log(e.message);
        setError(i18n.t("vehicle.edit.error"));
      });
  };

  const trips = useSelector((state: FullAppState) => state.trips.items);
  const historicMileageStore = useSelector(
    (state: FullAppState) => state.mileage.historicMileage
  );

  const currentMileage = useSelector(
    (state: FullAppState) => state.mileage.currentMileage
  );

  useEffect(() => {
    const myTrips = trips.filter((t) => {
      return t.device === item?.Device?.id;
    });

    if (myTrips.length > 0) {
      const lastTrip = myTrips[0].endTime;
      setlastTrip(lastTrip);
    }
  }, [trips, item]);

  const dispatch = useDispatch();
  const viewport = useViewport();

  useEffect(() => {
    const fromDate = new Date();
    fromDate.setHours(fromDate.getHours() - 168);
    dispatch(
      loadTrips(
        fromDate.toISOString(),
        new Date().toISOString(),
        item.Device?.id,
        20
      )
    );

    dispatch(loadWarningLightData());
  }, [dispatch]);

  const odometerValue = useMemo(() => {
    if (item?.Device?.id) {
      const firstRecord = historicMileageStore?.[item.Device.id]?.firstRecord;
      const currentMileageRecord = currentMileage?.[item.Device.id];
      if (!currentMileageRecord || !firstRecord) {
        return "--";
      }
      if (
        currentMileageRecord.diagnosticId == "DiagnosticOdometerAdjustmentId"
      ) {
        return (
          currentMileage[item.Device.id].value - firstRecord.value
        ).toFixed(decimalPlaces);
      } else {
        return currentMileageRecord.value.toFixed(decimalPlaces);
      }
    }

    return "--";
  }, [historicMileageStore, currentMileage]);

  const billingPeriodDriven = useMemo(() => {
    if (!item?.Device?.id) {
      return;
    }

    const monthKey = getMonthKey(DateUtil.utc());

    return (
      debugManager.periodMileage?.toFixed(decimalPlaces) ||
      historicMileageStore?.[item.Device.id]?.historicMileage[
        monthKey
      ]?.mileage?.toFixed(decimalPlaces) ||
      "--"
    );
  }, [historicMileageStore, currentMileage]);

  const actionRowStyle = tw`flex items-center uppercase cursor-pointer`;

  const isBeforePolicy = useBeforePolicy();

  return (
    <Card
      leftColumnColor={isMobileViewport(viewport) ? "#0073cf" : undefined}
      hideHeader={true}
      ariaLabel="Vehicle Card"
      renderCallToAction={() => {
        return (
          <div tw="ml-4">
            <button
              style={{ height: "46px" }}
              css={actionRowStyle}
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: `/mileage/${item.Device?.id}`,
                });
              }}
            >
              <div aria-hidden={true}>
                <IonIcon
                  tw="mr-2 flex-none text-highlightblue"
                  icon={documentText}
                  ariaLabel="Mileage Reports"
                />
              </div>
              <IonText tw="text-highlightblue" className="seeMore-desktop">
                {i18n.t("vehicle.mileageReports")}
              </IonText>
            </button>
            <button
              hidden={isAfterPolicy}
              style={{ height: "46px" }}
              css={actionRowStyle}
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: "/map",
                  search: `?device=${item.Device?.id}`,
                });
              }}
            >
              <div aria-hidden={true}>
                <IonIcon
                  tw="mr-2 flex-none text-highlightblue"
                  icon={location}
                  ariaLabel="Current Location"
                />
              </div>

              <IonText tw="text-highlightblue" className="seeMore-desktop">
                {i18n.t("vehicle.currentLocation")}
              </IonText>
            </button>

            <button
              hidden={isAfterPolicy}
              style={{ height: "46px" }}
              css={actionRowStyle}
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/vehicle/${item.Device?.id}`,
                });
              }}
            >
              <div aria-hidden={true}>
                <IonIcon
                  tw="mr-2 flex-none text-highlightblue"
                  icon={map}
                  ariaLabel="See trips and alerts"
                />
              </div>
              <IonText
                tw="text-highlightblue break-words"
                className="seeMore-desktop"
              >
                {i18n.t("vehicle.seeTripsAndAlerts")}
              </IonText>
            </button>
          </div>
        );
      }}
    >
      <IonModal
        isOpen={showModal}
        cssClass="modal-class"
        backdropDismiss={false}
        showBackdrop={true}
        animated
      >
        <IonGrid>
          <IonItem style={{ marginTop: "10px", color: "#003087" }} lines="none">
            <IonText className="modal-text">
              {i18n.t("vehicle.edit.title")}
            </IonText>
          </IonItem>
          <IonItem className="vehicle-edit-input" lines="none">
            <IonGrid>
              <IonRow>
                <CustomInput
                  label=""
                  labelClassName="Label-class"
                  className="input"
                  id="vehicleName"
                  type="text"
                  placeholder={i18n.t("vehicle.edit.placeholder")}
                  value={newDeviceName}
                  onChange={(e) =>
                    setNewDeviceName((e.target as HTMLInputElement).value)
                  }
                />
              </IonRow>
              {error && (
                <IonRow style={{ marginTop: "5px" }}>
                  <IonText color="danger">{error}</IonText>
                </IonRow>
              )}
            </IonGrid>
          </IonItem>
          <IonRow className="buttons-row">
            <IonCol size="6">
              <IonButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(false);
                  setNewDeviceName("");
                  setError("");
                  if (item.Device) {
                    clickToTelemetry("CancelEditVehicle", item.Device.id);
                  }
                }}
                // disabled={isSavingDevice}
                className="custom-button-white"
              >
                {i18n.t("button.cancel")}
              </IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton
                className="custom-button"
                disabled={isSavingDevice}
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.Device) {
                    editVehicleName(item.Device.id);
                    clickToTelemetry("SaveEditVehicle", item.Device.id);
                  }
                }}
              >
                {i18n.t("button.submit")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>

      <div>
        <VehicleCardHeader
          item={item}
          editClicked={() => setShowModal(true)}
          onClick={() => {
            if (item?.Device) {
              history.push("/vehicle/" + item.Device.id);
              clickToTelemetry("Vehicle", item.Device.id);
            }
          }}
        />
        <BeforePolicy />
        {!isBeforePolicy && !isAfterPolicy && <WarningLight />}
        <div>
          <AfterPolicy vehicleRecordType={item.RequestType} />
        </div>
        {!isAfterPolicy && (
          <ThreeRowCard
            row1Title="Odometer"
            row1Value={odometerValue}
            row1Unit="miles"
            row2Title="Billing period distance"
            row2Unit="miles"
            row2Value={isBeforePolicy ? "--" : billingPeriodDriven || "--"}
            row3Title="LastTrip"
            row3Value={() => {
              return (
                <div>
                  <IonText
                    className="card-num-desktop"
                    style={{ color: "#585858" }}
                  >
                    {lastTrip ? dayjs(lastTrip).format("MM/DD/YY") : ""}
                  </IonText>
                  <IonText tw="text-xl" style={{ marginLeft: "10px" }}>
                    {lastTrip ? dayjs(lastTrip).format("hh:mm a") : ""}
                  </IonText>
                </div>
              );
            }}
          ></ThreeRowCard>
        )}
      </div>
    </Card>
  );
};

export default VehicleCard;
