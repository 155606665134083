import { DateUtil } from "../../../util/date";
import { CLEAR_STORE } from "../../login/actions/login";
import { LoadMileageType, LOAD_HISTORIC_MILEAGE, SET_CURRENT_MILEAGE, SET_HISTORIC_MILEAGE } from "../actions/mileage";
import IMileageState from "../state/mileageState";

const initalState: IMileageState = {
    currentMileage: {},
    historicMileage: {},
    loading: true,
    lastCurrentFetch: DateUtil.utc("1980-01-01")
};

export default function (
    defaultState = initalState,
    action: LoadMileageType
): IMileageState {
    switch (action.type) {
        case LOAD_HISTORIC_MILEAGE: {
            return { ...defaultState };
        }
        case CLEAR_STORE: {
            return initalState;
        }
        case SET_CURRENT_MILEAGE: {
            return {
                ...defaultState,
                historicMileage: defaultState.historicMileage,
                currentMileage: { ...defaultState.currentMileage, [action.deviceId]: action.mileageRecord },
                lastCurrentFetch: DateUtil.utc()
            };
        }
        case SET_HISTORIC_MILEAGE: {
            return {
                loading: false,
                currentMileage: defaultState.currentMileage,
                historicMileage: { ...defaultState.historicMileage, [action.historicMileageResponse.deviceId]: action.historicMileageResponse },
                lastCurrentFetch: defaultState.lastCurrentFetch
            };
        }
        default: {
            return defaultState;
        }
    }
}
