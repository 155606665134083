import { isPlatform } from "@ionic/react";
import AppConfig from "../config/appConfig";
import { Viewport } from "../hooks/useViewport";

export function getPlatform(): string {
    return isPlatform("android") ? "android" : isPlatform("ios") ? "ios" : "web";
}

export function checkPlatform(platform: "ios" | "android" | "desktop"): boolean {
    return isPlatform(platform);
}

export function isIOS(): boolean {
    return isPlatform("ios");
}

export function isAndroid(): boolean {
    return isPlatform("android");
}

export function isMobile(): boolean {
    return isPlatform("mobile");
}

export function isDesktop(): boolean {
    return isPlatform("desktop");
}

export function isMobileWidth(): boolean {
    return window.innerWidth <= AppConfig.DesktopBreakpoint;
}

export function isDesktopWidth(): boolean {
    return window.innerWidth > AppConfig.DesktopBreakpoint;
}

export function isMobileViewport(viewport: Viewport): boolean {
    return viewport.width <= AppConfig.DesktopBreakpoint;
}