import React, { useState, useRef, useEffect } from "react";
import { IonSlide, IonSlides } from "@ionic/react";
import "./Register.css";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { FullAppState } from "../../../store/state";
import { Redirect, useHistory } from "react-router";
import { MainRegister } from "./MainRegister";
import { ConfirmSignup } from "./ConfirmSignup";

export const Register: React.FC = () => {
  // const [email, setEmail] = useState("");

  useEffect(() => {
    document.title = "FairMile® | Register";
  }, []);

  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string | null>(null);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const register = async (email: string, password: string): Promise<void> => {
    // LoginEvent("ForgetPassword");
    const params = {
      username: email,
      password: password,
      attributes: {
        email: email,
      },
    };

    setPassword(password);
    let checked = false;

    setTimeout(async () => {
      if (!checked) {
        checked = true;
        try {
          await Auth.signUp(params);
          setEmail(params.username);
          swipeTo(1);
        } catch (err) {
          if (err.code == "UsernameExistsException") {
            setEmail(params.username);
            swipeTo(1);
            return;
          }

          setIsRegistering(false);
          if (typeof err == "object" && err.message) {
            setError(err.message);
          } else {
            setError(err as string);
          }
        }
      }
    }, 5000);

    try {
      setIsRegistering(true);
      const res = await Auth.signUp(params);
      checked = true;
      console.log(res);
      setEmail(params.username);
      swipeTo(1);
    } catch (err) {
      setIsRegistering(false);
      if (typeof err == "object" && err.message) {
        setError(err.message);
      } else {
        setError(err as string);
      }
    }
  };

  const history = useHistory();

  useEffect(() => {
    setError(null);
  }, [history]);

  const slideRef = useRef(null);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const isLoggedIn = useSelector(
    (state: FullAppState) => state.login.isLoggedIn
  );
  const swipeTo = async (index: number) => {
    if (slideRef && slideRef.current) {
      setError(null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const slides: any = slideRef.current;
      await slides.lockSwipeToNext(false);
      slides.slideTo(index);
      await slides.lockSwipeToNext(true);
      setSelectedPage(index);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  const slideOpts = {
    initialSlide: selectedPage,
    speed: 400,
    allowSlidePrev: false,
    allowSlideNext: false,
  };

  return (
    <>
      {/* <IonToast
        isOpen={error != null}
        message={error!}
        duration={7000}
        onDidDismiss={() => setError(null)}
      /> */}
      <IonSlides
        ref={slideRef}
        className="loginPage"
        pager={false}
        options={slideOpts}
      >
        <IonSlide>
          <MainRegister
            register={register}
            error={error}
            isRegistering={isRegistering}
          />
        </IonSlide>
        <IonSlide>
          <ConfirmSignup
            password={password}
            goBack={() => history.push("/login")}
            email={email}
            setError={setError}
            error={error || undefined}
          />
        </IonSlide>
      </IonSlides>
    </>
  );
};
