import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import "./More.css";
import { PageView } from "../telemetry/TelemetryManager";
import { AppVersion } from "@ionic-native/app-version";
import Page from "./Page";
import FAQ from "../components/FAQ/FAQ";

import { IonIcon } from "@ionic/react";
import { caretForward, chevronForward } from "ionicons/icons";
import i18n from "../i18n";

import tw from "twin.macro";

import { Plugins } from "@capacitor/core";
import { useIsMobile } from "../hooks/useIsMobile";
import { useIsIOS } from "../hooks/useIsIOS";
import { showFeedback } from "../refiner/refinerClient";

const { Browser } = Plugins;
const openUrl = async (url: string) => {
  await Browser.open({ url });
};

const More: React.FC<RouteComponentProps> = () => {
  const [pageViewthrown, setPageViewThrown] = useState(false);
  const history = useHistory();
  const [, setAppVersion] = useState<string>("");

  const isMobile = useIsMobile();

  if (!pageViewthrown) {
    setPageViewThrown(true);
    PageView("More");
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const search = useQuery();

  const faqSection = search.get("faq") || "-1";

  useEffect(() => {
    AppVersion.getVersionNumber()
      .then((d) => {
        setAppVersion(d);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [history]);

  useEffect(() => {
    document.title = "FairMile® | More";
  }, []);

  const rowStyle = tw`grid grid-cols-2 grid-rows-1 py-4 pl-8 bg-white border-t text-farmersblue`;

  const isIOS = useIsIOS();

  const debugLink = () => {
    if (process.env.NODE_ENV == "development") {
      return (
        <div
          onClick={(e) => {
            e.preventDefault();
            history.push("/debug-screen");
          }}
          css={rowStyle}
          className="h2"
        >
          <span>Debug</span>

          <div tw="flex justify-end">
            <IonIcon
              tw="mr-4"
              icon={isIOS ? chevronForward : caretForward}
              aria-label="Arrow icon"
            ></IonIcon>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Page title="Support" showBack={false}>
      <div tw="flex flex-col">
        <div
          css={isMobile ? tw`mt-8 ml-4` : tw`mt-4 ml-8`}
          tw="uppercase text-farmersblue mb-4"
          className="h2"
        >
          How can we help?
        </div>
        <div tw="mb-4">
          <FAQ selectedSection={faqSection} />
        </div>
      </div>
      <div
        hidden={!isMobile}
        tw="bg-white flex flex-col w-full text-farmersblue"
      >
        <div
          onClick={(e) => {
            e.preventDefault();
            showFeedback();
          }}
          css={rowStyle}
          className="h2"
        >
          <span>{i18n.t("more.givefeedback")}</span>
          <div tw="flex justify-end">
            <IonIcon
              tw="mr-4"
              icon={isIOS ? chevronForward : caretForward}
              aria-label="Arrow icon"
            ></IonIcon>
          </div>
        </div>

        <div
          onClick={(e) => {
            e.preventDefault();
            openUrl("https://www.farmers.com/disclaimer/fairmile/terms-of-use");
          }}
          css={rowStyle}
          className="h2"
        >
          <span>{i18n.t("more.tos")}</span>

          <div tw="flex justify-end">
            <IonIcon
              tw="mr-4"
              icon={isIOS ? chevronForward : caretForward}
              aria-label="Arrow icon"
            ></IonIcon>
          </div>
        </div>

        <div
          onClick={(e) => {
            e.preventDefault();
            openUrl(
              "https://www.farmers.com/disclaimer/fairmile/privacy-policy"
            );
          }}
          css={rowStyle}
          className="h2"
        >
          <span>{i18n.t("more.privpolicy")}</span>

          <div tw="flex justify-end">
            <IonIcon
              tw="mr-4"
              icon={isIOS ? chevronForward : caretForward}
              aria-label="Arrow icon"
            ></IonIcon>
          </div>
        </div>

        <div
          onClick={(e) => {
            e.preventDefault();
            history.push("/logout");
          }}
          css={rowStyle}
          className="h2"
        >
          <span>{i18n.t("more.logout")}</span>

          <div tw="flex justify-end">
            <IonIcon
              tw="mr-4"
              icon={isIOS ? chevronForward : caretForward}
              aria-label="Arrow icon"
            ></IonIcon>
          </div>
        </div>
      </div>
      {debugLink()}
    </Page>
  );
};

export default More;
