import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;

export function hide(): void {
	// Hide the splash (you should do this on app launch)
	SplashScreen.hide();
}

export function show(autoHide?: boolean, showDuration?: number): void {
	if (autoHide) {
		// Show the splash for two seconds and then auto hide:
		SplashScreen.show({
			showDuration: showDuration != null ? showDuration : 2000,
			autoHide: true
		});
		return;
	}

	// Show the splash for an indefinite amount of time:
	SplashScreen.show({
		autoHide: false
	});
}


