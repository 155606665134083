import React, { useEffect, useState } from "react";
import { IonText } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { LoginErrorType } from "../../store/login/reducers/loginReducer";
import i18n from "../../i18n";
import { clearError } from "../../store/login/actions/login";

interface Props {
  error?: string | null | undefined;
}

const LoginError: React.FC<Props> = ({ error }: Props) => {
  const loginError = useSelector(
    (state: FullAppState) => state.login.errorType
  );
  const [message, setMessage] = useState<string | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loginError) {
      dispatch(clearError());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!loginError && !error) {
      setMessage(null);
      return;
    }

    const err = loginError || error?.toUpperCase();

    switch (err) {
      case LoginErrorType.NoUser:
        setMessage(i18n.t("login.errors.noUser"));
        return;
      case LoginErrorType.InvalidPassword:
        setMessage(i18n.t("login.errors.noUser"));
        return;
      case LoginErrorType.NoPolicy:
        setMessage(i18n.t("login.errors.noPolicy"));
        return;
      case LoginErrorType.AccountExists:
        setMessage(i18n.t("login.errors.accountExists"));
        return;
      case LoginErrorType.PasswordNoMatch:
        setMessage(i18n.t("login.errors.passwordNoMatch"));
        return;
      case LoginErrorType.ForgotPasswordNoExist:
        setMessage(i18n.t("login.errors.forgotPasswordNoExist"));
        return;
      case LoginErrorType.TooManyAttempts:
        setMessage(i18n.t("login.errors.tooManyAttempts"));
        return;
      case LoginErrorType.InvalidVerificationCode:
        setMessage(i18n.t("login.errors.invalidVerificationCode"));
        return;
      case LoginErrorType.PasswordCannotBeEmpty:
        setMessage(i18n.t("login.errors.passwordCannotBeEmpty"));
        return;
      case LoginErrorType.UsernameCannotBeEmpty:
        setMessage(i18n.t("login.errors.usernameCannotBeEmpty"));
        return;
      default:
        setMessage(i18n.t("login.errors.unknownError"));
    }
  }, [loginError, error]);

  return (
    <section
      role="alert"
      style={{
        display: "flex",
        marginLeft: "17px",
        marginTop: "10px",
        color: "#E01933",
      }}
    >
      <IonText
        style={{ textAlign: "justify", marginRight: "20%" }}
        hidden={message == null}
        aria-label={"Login Error. " + message}
      >
        <span aria-hidden={true}>{message}</span>
      </IonText>
    </section>
  );
};

export default LoginError;
