import React from "react";
import { IonText, IonRow, IonCol } from "@ionic/react";
import "./SystemCard.css";
import { useIsIOS } from "../../hooks/useIsIOS";

interface props {
  miles: string[];
  startOdometer?: number | null;
  endOdometer?: number | null;
}

const MileageContainer: React.FC<props> = ({
  miles,
  startOdometer,
  endOdometer,
}: props) => {
  const isIOS = useIsIOS();
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <IonRow>
          <IonCol>
            <IonText>
              <p className="mil-header">ESTIMATED MILEAGE</p>
            </IonText>
          </IonCol>

          <IonCol>
            <IonText>
              <p className="mil-header">ACTUAL MILEAGE</p>
            </IonText>
          </IonCol>

          <IonCol>
            <IonText>
              <p className="mil-header third">DIFFERENCE</p>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="dashedRow">
          <IonCol>
            <IonText class="card-list">
              <p className="mil-text">
                {(miles && miles[0]) || "--"} <br></br>
                <span className="sys-Card-miles">miles</span>
              </p>
            </IonText>
          </IonCol>

          <IonCol>
            <IonText class="card-list">
              <p className="mil-text">
                {(miles && miles[1]) || "--"} <br></br>
                <span className="sys-Card-miles">miles</span>
              </p>
            </IonText>
          </IonCol>

          <IonCol>
            <IonText class="card-list">
              <p
              // className={
              //   "mil-text " +
              //   (miles && parseInt(miles[0]) < parseInt(miles[1])
              //     ? "red-text"
              //     : "")
              // }
              >
                {miles[2]} <br></br>
                <span className="sys-Card-miles">miles</span>
              </p>
            </IonText>
          </IonCol>
        </IonRow>
      </div>
      <IonRow className={isIOS ? "ml-30" : ""}>
        <IonCol>
          <p className="mil-header">ODOMETER START</p>
        </IonCol>
        <IonCol>
          <p className="mil-header">ODOMETER END</p>
        </IonCol>
      </IonRow>
      <IonRow style={{ marginBottom: "10px" }} className={isIOS ? "ml-50" : ""}>
        <IonCol className="no-padding">
          <p className="mil-header" style={{ fontSize: "13px" }}>
            {startOdometer?.toFixed(0) || "--"} miles
          </p>
        </IonCol>
        <IonCol className="no-padding">
          <p style={{ fontSize: "13px" }} className="mil-header">
            {endOdometer?.toFixed(0) || "--"} miles
          </p>
        </IonCol>
      </IonRow>
    </>
  );
};

export default MileageContainer;
