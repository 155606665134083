import React from "react";
import { IonApp } from "@ionic/react";
import "leaflet/dist/leaflet.css";
import Leaflet from "leaflet";
import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";
import "leaflet.awesome-markers";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/main.css";
import "./App.css";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IonReactRouter } from "@ionic/react-router";
//With this we can use history even in App.tsx

import defaultMarkerIcon from "leaflet/dist/images/marker-icon.png";
import defaultIconRetina from "leaflet/dist/images/marker-icon-2x.png";
import defaultShadow from "leaflet/dist/images/marker-shadow.png";
import AppController from "./AppController";
import { useIsMobile } from "./hooks/useIsMobile";

const leafletPrototype = Leaflet.Icon.Default.prototype as unknown;
delete (leafletPrototype as Record<string, unknown>)._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: defaultIconRetina,
  iconUrl: defaultMarkerIcon,
  shadowUrl: defaultShadow,
});

const App: React.FC = () => {
  const isMobile = useIsMobile();
  return (
    <IonApp className={isMobile ? "mobile" : "desktop"}>
      <IonReactRouter>
        <AppController />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
