import { Point } from "@seven-fields-software-ltd/geotab-map-js";

export const getCenterOfPoints = (points: Point[]): Point => {
    if (points.length == 0) {
        return { x: 0, y: 0 };
    } else if (points.length == 1) {
        return { x: points[0].x, y: points[0].y };
    }

    let sumx = 0;
    let sumy = 0;

    for (const p of points) {
        sumx += p.x;
        sumy += p.y;
    }

    return { x: sumx / points.length, y: sumy / points.length };
};