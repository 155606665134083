/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import Analytics from "@aws-amplify/analytics";
import { Analytics } from "aws-amplify";
import { getUuid } from "../system/StorageManager";

const Getattributes = (pageName: string, data?: any, uuid?: string | null) => {
    const attributes: any = {
        pageName: pageName,
        uuid: uuid,
    };
    if (data !== undefined && data !== null) {
        Object.keys(data).forEach((item) => {
            attributes[item] = data[item];
        });
    }
    return attributes;
};

const recordAnalytics = (eventType: string, attributes: any) => {
    Analytics.record({
        name: eventType,
        attributes: attributes
    });
};

export function PageView(pageName: string, data?: any): void {
    getUuid().then((uuid) => {
        const attr = Getattributes(pageName, data, uuid);
        recordAnalytics("app_page_view", attr);
    });
}

export function LoginEvent(status: string, data?: any): void {
    getUuid().then((uuid) => {
        const attr = { status: status, uuid: uuid };
        recordAnalytics("app_login", attr);
    });
}

export function ClickEvent(pageName: string, data?: any): void {
    getUuid().then((uuid) => {
        const attr = Getattributes(pageName, data, uuid);
        recordAnalytics("app_user_click", attr);
    });
}

export function UserAction(action: string, data?: any): void {
    getUuid().then((uuid) => {
        const attr = { action: action, uuid: uuid };
        recordAnalytics("app_user_action", attr);
    });
}

export function ScrollEvent(data?: any) {
    getUuid().then((uuid) => {
        const attr = { PageFrom: data.PageFrom, uuid: uuid };
        recordAnalytics("app_scroll", attr);
    });
}
