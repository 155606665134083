import React, { useEffect, useState } from "react";
import { IonText } from "@ionic/react";
import { FullAppState } from "../../store/state";
import { INewsFeedItem } from "../../store/newsfeed/state/newsfeedState";
import "./NewsFeed.css";
import { useSelector, useDispatch } from "react-redux";
import { loadNewsFeed } from "../../store/newsfeed/actions/newsfeed";
import NewsfeedItem from "./newsfeedItem";

import "twin.macro";

const NewsFeed: React.FC = () => {
  const [intervalKey, setIntervalKey] = useState<NodeJS.Timeout | null>(null);
  const newsFeedList = useSelector((state: FullAppState) => state.newsfeed) as {
    items: INewsFeedItem[];
  };

  const policyNumber = useSelector(
    (state: FullAppState) => state?.policy?.policy?.PolicyNumber
  );

  const isLoggedIn = useSelector((state: FullAppState) => {
    return state.login.isLoggedIn;
  });

  if (newsFeedList && newsFeedList.items && newsFeedList.items.length > 0) {
    newsFeedList.items = newsFeedList.items.sort(
      (a: INewsFeedItem, b: INewsFeedItem) => {
        if (a.time > b.time) {
          return -1;
        } else if (a.time < b.time) {
          return 1;
        }

        return 0;
      }
    );
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (intervalKey || !policyNumber) {
      return;
    }

    const interval = setInterval(() => {
      if (isLoggedIn) {
        dispatch(loadNewsFeed());
      }
    }, 17000);

    if (isLoggedIn && newsFeedList.items.length === 0) {
      dispatch(loadNewsFeed());
    }

    setIntervalKey(interval);

    return () => clearInterval(interval);
  }, [policyNumber, dispatch]);

  return (
    <div tw="flex flex-col mt-8 border-b">
      <div>
        <IonText tw="ml-8 mb-4" className="newsfeed-mobile">
          NEWS FEED
        </IonText>
      </div>
      <section role="list" aria-label="News Feed">
        {newsFeedList.items.map((item, key) => {
          return (
            <div key={key} tw="w-full">
              <NewsfeedItem item={item} mobile={true} key={key} />
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default NewsFeed;
