import React from "react";
import { IonIcon, IonItem, IonItemGroup, IonText } from "@ionic/react";
import "./Notifications.css";
import { closeCircle } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import {
  INotification,
  removeNotification,
} from "../../store/notifications/actions/notificationsActions";
const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector<FullAppState, INotification[]>(
    (state) => state.notifications.notifications
  );
  return (
    <IonItemGroup>
      {notifications.map((notification, index) => {
        return (
          <IonItem key={index} className="alerts" color={notification.type}>
            <IonIcon
              icon={closeCircle}
              slot="end"
              className="icon-btn"
              onClick={() => dispatch(removeNotification(index))}
              aria-label="Close Icon"
            ></IonIcon>
            <IonText>{notification.message}</IonText>
          </IonItem>
        );
      })}
    </IonItemGroup>
  );
};

export default Notifications;
