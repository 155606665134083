import authConfigure from "./settings/auth_configure";
import apiConfigure from "./settings/api_configure";
// import AnalyticsConf from "./alpha/analytics_configure";
import Amplify from "aws-amplify";
import analyticsConfigure from "./settings/analytics_configure";

export interface AnalyticsConfig {
    AWSPinpoint: {
        appId: string;
        region: string;
    }
}

export interface EndpointDefinition {
    endpoints: {
        name: string;
        endpoint: string;
        custom_header: () => Promise<{ Authorization: string }>;
    }[];
}

export interface AuthConfig {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    mandatorySignIn: boolean;
    identityPoolId: string;
}

export interface BaseConfig extends EndpointDefinition {
    Auth: AuthConfig;
    Analytics?: AnalyticsConfig;
}


export default (): void => {
    const apiConfig: EndpointDefinition = apiConfigure();
    const baseConfig = { ...apiConfig, Auth: authConfigure(), Analytics: analyticsConfigure() } as BaseConfig;
    Amplify.configure(baseConfig);
};

