import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadRules } from "../store/exceptions/actions/exceptions";
import { FullAppState } from "../store/state";
import { loadDeviceLocation, loadDevices } from "../store/vehicles/actions/vehicles";
import { loadHistoricMileage } from "../store/mileage/actions/mileage";
import { DateUtil } from "../util/date";
import { authenticateGeotab } from "../store/geotabAuthentication/actions/actions";
import { LoadingStatus } from "../types/Loading";

export default function (): boolean {
    const [devicesLoaded, setDevicesLoaded] = useState(false);
    const [rulesLoaded, setRulesLoaded] = useState(false);
    const [loadingTimeout, setLoadingTimeout] = useState(false);
    const [calledMileageLoad, setCalledMileageLoad] = useState(false);
    const dispatch = useDispatch();

    const geotabConnected = useSelector((state: FullAppState) => {
        return state.geotabAuth.isAuthenticated;
    });

    const devices = useSelector((state: FullAppState) => state.vehicles.items);
    const devicesCallFinished = useSelector((state: FullAppState) => state.vehicles.loaded);
    const userName = useSelector((state: FullAppState) => state.login.userName);
    const policyNumber = useSelector((state: FullAppState) => state.policy.policy?.PolicyNumber);

    const isGeotabAuthLoading = useSelector((state: FullAppState) => state.geotabAuth.LoadingStatus == LoadingStatus.LOADING);

    const isLoggedIn = useSelector(
        (state: FullAppState) => state.login.isLoggedIn
    );

    const rules = useSelector((state: FullAppState) => {
        return state.exceptions.rules;
    });

    useEffect(() => {
        setTimeout(() => {
            if (isLoggedIn) {
                setLoadingTimeout(true);

                if (!devices || devices.length == 0) {
                    console.log("Load Devices");
                    dispatch(loadDevices());
                }

                if (!rules || Object.keys(rules).length == 0) {
                    console.log("Load Rules");
                    dispatch(loadRules());
                }
            }
        }, 5000);
    }, [dispatch]);

    useEffect(() => {
        if (!isLoggedIn) {
            setDevicesLoaded(false);
            setRulesLoaded(false);
            setCalledMileageLoad(false);
            setLoadingTimeout(false);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn && !geotabConnected && userName && !isGeotabAuthLoading) {
            dispatch(authenticateGeotab(userName));
        }
    }, [userName, policyNumber, isLoggedIn, userName, geotabConnected, isGeotabAuthLoading]);

    //Get geotab data
    useEffect(() => {
        if (geotabConnected) {
            console.log("Load Devices");
            dispatch(loadDevices());

            console.log("Load Rules");
            dispatch(loadRules());
        }
    }, [geotabConnected]);

    useEffect(() => {
        if (rules && Object.keys(rules).length > 0) {
            setRulesLoaded(true);
        }
    }, [rules]);

    //Load mileage and location
    useEffect(() => {
        if (!devicesLoaded && geotabConnected && policyNumber && policyNumber.length > 0 && ((devices && devices.length > 0) || devicesCallFinished)) {
            console.log("Loading Mileage");
            setDevicesLoaded(true);
            let makeCall = false;
            for (const d of devices) {
                if (!d.position || (d.position.x == 0 && d.position.y == 0)) {
                    makeCall = true;
                }
            }
            if (makeCall) {
                dispatch(loadDeviceLocation());
            }

            if (!calledMileageLoad) {
                setCalledMileageLoad(true);
                for (const d of devices) {
                    dispatch(
                        loadHistoricMileage(
                            d.id,
                            policyNumber,
                            DateUtil.utc().add(-3, "month"),
                            DateUtil.utc()
                        )
                    );
                }
            }
        }
    }, [devices, policyNumber, geotabConnected]);

    return (isLoggedIn && devicesLoaded && rulesLoaded) || loadingTimeout;
}