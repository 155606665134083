import React from "react";
import "./SystemCard.css";
import { DateFormat } from "../../constants";
import { DateUtil } from "../../util/date";

import tw from "twin.macro";

import "./SystemCard.css";

interface Props {
  start: string;
  end: string;
  description?: string;
  descriptionLinks?: Record<string, { text: string; path: string }>;
  title?: string;
  isFault?: boolean;
}

import { useHistory } from "react-router";
import { renderDescription } from "../LinkRenderer/LinkRenderer";

const FaultExceptionContainer: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const goto = (link: string) => {
    history.push(link);
  };

  return (
    <div tw="flex flex-col pb-4 flex-grow" css={props.isFault ? tw`pt-4` : ""}>
      {props.isFault && (
        <div>
          <div tw="font-bold pb-2 border-b">
            <div tw="ml-4">{props.title}</div>
          </div>
          <div tw="mr-8 ml-4 mt-2">
            <div tw="font-bold">Description</div>
            {renderDescription(
              history,
              props.description,
              props.descriptionLinks
            )}
          </div>
        </div>
      )}
      <div tw="ml-4">
        <div tw="mt-4 font-bold">Alert Started:</div>
        <div>{DateUtil.utc(props.start).local().format(DateFormat)}</div>
        <div tw="font-bold mt-4">Alert Ended:</div>
        <div>{DateUtil.utc(props.end).local().format(DateFormat)}</div>
        {props.isFault && <div></div>}
      </div>
    </div>
  );
};

export default FaultExceptionContainer;
