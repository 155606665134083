import React, { useState } from "react";
import { IonContent as div, IonText, IonIcon, IonImg } from "@ionic/react";
import { ClickEvent } from "../../telemetry/TelemetryManager";
import "./Drawer.css";
import { useSelector } from "react-redux";
import { FullAppState } from "../../store/state";
import { helpCircleOutline, statsChart } from "ionicons/icons";

import tw from "twin.macro";

import { Plugins } from "@capacitor/core";
import { useHistory } from "react-router";
import { showFeedback } from "../../refiner/refinerClient";
const { Browser } = Plugins;

const openUrl = async (url: string) => {
  await Browser.open({ url });
};

interface IProps {
  isLoggedIn: boolean;
}

export const Drawer: React.FC<IProps> = ({ isLoggedIn }: IProps) => {
  const companyName = useSelector(
    (state: FullAppState) => state.policy.policy?.InsuredName
  );
  const policyNumber = useSelector(
    (state: FullAppState) => state.policy.policy?.PolicyNumber
  );
  const clickToTelemetry = (pageTo: string) => {
    ClickEvent("Drawer", { pageTo: pageTo });
  };
  const [selected, setSelected] = useState("dashboard");

  const history = useHistory();

  const menuItem = tw`flex items-center my-4 ml-4 cursor-pointer`;
  const menuIcon = tw`w-8 h-8 mr-4 text-headinggray`;
  const menuImage = tw`w-8 h-8 ml-2 mr-6`;

  if (!isLoggedIn) {
    return <div></div>;
  }

  return (
    <div tw="bg-white h-screen" style={{ width: "300px" }}>
      <div tw="ml-4" className="desktop-fairmile">
        FairMile<sup>®</sup>
      </div>
      <div tw="bg-farmersred" style={{ height: "4px" }}></div>
      <div tw="pl-4 mt-4 pb-4 border-b">
        <div className="drawer-companyName">{companyName}</div>
        <div className="drawer-policyNum">
          Business Insurance {`Policy #${policyNumber}`}{" "}
        </div>
      </div>
      <div>
        <button
          css={menuItem}
          onClick={() => {
            clickToTelemetry("Dashboard");
            setSelected("dashboard");
            history.push("/dashboard");
          }}
          className={selected === "dashboard" ? "link-selected" : ""}
        >
          <div aria-hidden={true}>
            <IonIcon
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
              css={menuIcon}
              src="./assets/appicons/business-icon.svg"
            ></IonIcon>
          </div>
          <IonText className="drawer-option"> Company overview </IonText>
        </button>

        <button
          css={menuItem}
          onClick={() => {
            clickToTelemetry("Insights");
            setSelected("insights");
            history.push("/insights");
          }}
          className={selected === "insights" ? "link-selected" : ""}
        >
          <div aria-hidden={true}>
            <IonIcon 

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={menuIcon} icon={statsChart}></IonIcon>
          </div>
          <IonText className="drawer-option">Insights</IonText>
        </button>

        <button
          css={menuItem}
          onClick={() => {
            clickToTelemetry("Vehicles");
            setSelected("vehicles");
            history.push("/vehicles");
          }}
          className={selected === "vehicles" ? "link-selected" : ""}
        >
          <div aria-hidden={true}>
            <IonIcon

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={menuIcon}
              src="/assets/truck.svg"
              // aria-label="Vehicle and devices"
            ></IonIcon>
          </div>
          <IonText className="drawer-option">Vehicles &amp; devices</IonText>
        </button>

        <button
          css={menuItem}
          onClick={() => {
            clickToTelemetry("More");
            setSelected("more");
            history.push("/more");
          }}
          className={selected === "more" ? "link-selected" : ""}
        >
          <div aria-hidden={true}>
            <IonIcon

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
              css={menuIcon}
              icon={helpCircleOutline}
              // aria-label="Support"
            ></IonIcon>
          </div>
          <IonText className="drawer-option">Support</IonText>
        </button>
        <section tw="border-t">
          <button
            css={menuItem}
            onClick={(e) => {
              e.preventDefault();
              showFeedback();
            }}
          >
            <div aria-hidden={true}>
              <IonImg

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
                css={menuImage}
                // aria-label="Terms and conditions"
                src="/assets/drawerarrow.png"
                tw="w-4"
              ></IonImg>
            </div>
            <IonText className="drawer-option">Give feedback</IonText>
          </button>

          <button
            css={menuItem}
            onClick={(e) => {
              e.preventDefault();
              openUrl(
                "https://www.farmers.com/disclaimer/fairmile/terms-of-use"
              );
            }}
          >
            <div aria-hidden={true}>
              <IonImg
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
                css={menuImage}
                // aria-label="Terms and conditions"
                src="/assets/drawerarrow.png"
                tw="w-4"
              ></IonImg>
            </div>
            <IonText className="drawer-option">Terms &amp; conditions</IonText>
          </button>

          <button
            css={menuItem}
            onClick={(e) => {
              e.preventDefault();
              openUrl(
                "https://www.farmers.com/disclaimer/fairmile/privacy-policy"
              );
            }}
          >
            <div aria-hidden={true}>
              <IonImg
              
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
                css={menuImage}
                // aria-label="Terms and conditions"
                src="/assets/drawerarrow.png"
                tw="w-4"
              ></IonImg>
            </div>
            <IonText className="drawer-option">Privacy policy</IonText>
          </button>

          <button
            css={menuItem}
            onClick={(e) => {
              e.preventDefault();
              setSelected("logout");
              window.location.replace("/logout");
            }}
          >
            <div aria-hidden={true}>
              <IonImg

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
                css={menuImage}
                src="/assets/drawerarrow.png"
                tw="w-4"
              ></IonImg>
            </div>
            <IonText className="drawer-option">Logout</IonText>
          </button>
        </section>
      </div>
    </div>
  );
};
