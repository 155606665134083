import { IonIcon } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import React from "react";

import tw from "twin.macro";
import { useAfterPolicy } from "../../hooks/useAfterPolicy";

interface Props {
  vehicleRecordType: string;
}

const AfterPolicy: React.FC<Props> = (props: Props) => {
  const isAfterPolicy = useAfterPolicy() || props.vehicleRecordType == "D";

  return (
    <div css={isAfterPolicy ? tw`pb-2 pr-8` : ""}>
      {isAfterPolicy && (
        <div tw="flex m-1">
          <IonIcon
            tw="ml-4 mr-2 text-farmersred"
            className="alert-circle"
            aria-label="Lapsed Coverage"
            icon={informationCircle}
          ></IonIcon>
          <p tw="text-sm text-headinggray">
            <p>
              This vehicle has been removed from your policy by request or
              because of a policy cancellation.
            </p>
            <p tw="pt-2">
              Please contact your agent if you wish to reinstate coverage for
              this vehicle under the FairMile program
            </p>
          </p>
        </div>
      )}
    </div>
  );
};

export default AfterPolicy;
