export const useCheckPassword = (password?: string, confirmation?: string): {
    length: boolean, match: boolean, uppercase: boolean, lowercase: boolean, number: boolean, all: boolean
} => {
    const length = (password && password.length >= 8) || false;
    const match = (password && confirmation && password == confirmation) || false;
    const uppercase = (password && password.match(/[A-Z]+/) != null) || false;
    const lowercase = (password && password.match(/[a-z]+/) != null) || false;
    const number = (password && password.match(/[0-9]+/) != null) || false;
    const all = length && match && uppercase && number;

    return { length, match, uppercase, lowercase, number, all };
};