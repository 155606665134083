import { combineReducers } from "redux";
import login from "../store/login/reducers/loginReducer";
import company from "../store/company/reducers/companyReducer";
import snapshot from "../store/snapshot/reducers/snapshotReducer";
import newsfeed from "../store/newsfeed/reducers/newsfeedReducer";
import vehicles from "../store/vehicles/reducers/vehiclesReducer";
import trips from "../store/trips/reducers/tripsReducer";
import cardmocks from "../store/cardmock/reducers/cardReducer";
import faultData from "../store/faultData/reducers/faultDataReducer";
import exceptions from "../store/exceptions/reducers/exceptionsReducer";
import mileage from "../store/mileage/reducers/mileageReducer";
import notifications from "../store/notifications/reducers/notificationsReducer";
import policy from "./policy/reducers/reducer";
import geotabAuth from "./geotabAuthentication/reducers/geotabAuthReducer";

const rootReducer = combineReducers({
    login,
    company,
    snapshot,
    newsfeed,
    vehicles,
    trips,
    cardmocks,
    faultData,
    exceptions,
    mileage,
    notifications,
    policy,
    geotabAuth
});

export type FullAppState = ReturnType<typeof rootReducer>;
export default rootReducer;
