import React, { useState } from "react";
import {
  IonButton,
  IonGrid,
  IonImg,
  IonItem,
  IonRow,
  IonText,
  IonRouterLink,
  IonCard,
  IonCol,
} from "@ionic/react";
import "./ForgotPassword.css";
import { useHistory } from "react-router";
import i18n from "../../i18n";
import CustomInput from "../CustomInput";
import LoginError from "./Error";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useIsIOS } from "../../hooks/useIsIOS";

interface Props {
  forgetPassword: (email: string) => void;
  error?: string | null | undefined;
}

// eslint-disable-next-line react/prop-types
export const MainForgotPassword: React.FC<Props> = ({
  forgetPassword,
  error,
}: Props) => {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const isMobile = useIsMobile();
  const isIOS = useIsIOS();

  return (
    <IonGrid className="login-grid" role="main">
      {isMobile && (
        <IonRow>
          <IonCol className="p-0 login-bg">
            <div className="img-container">
              <IonImg
                src="assets/loginRect.svg"
                className="login-rectangle"
                alt="Background gradient"
              ></IonImg>
              <IonImg
                src="assets/loginbranding.svg"
                className="top-img"
                alt="Logo branding"
              ></IonImg>
              <IonText className="welcome-text" role="heading">
                Welcome to
              </IonText>
              <IonText className="FairMile-text" role="heading">
                FairMile
                <span className="header-span">®</span>
              </IonText>
            </div>
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        <IonCol style={{ display: "flex" }}>
          <IonCard className={isMobile ? "login-card" : "login-card-desktop"}>
            <IonItem lines="none">
              <IonText style={{ color: "#003087", paddingTop: "10px" }}>
                {i18n.t("forgetpassword.message")}
              </IonText>
            </IonItem>
            <IonItem
              style={{ paddingTop: "10px" }}
              className="mt-9 custom-input-item mb-ios"
              lines="none"
            >
              <CustomInput
                label="Email address"
                value={email}
                autoCapitalize={false}
                disabled={false}
                type="text"
                id="forgot_password_email"
                onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
              />
            </IonItem>
            <LoginError error={error} />
            <IonItem lines="none" className="custom-input-item">
              <IonButton
                style={{ width: "180px" }}
                className={
                  isIOS ? "custom-button" : "custom-button signin-btn-color"
                }
                onClick={() => forgetPassword(email)}
              >
                {i18n.t("button.submit")}
              </IonButton>
            </IonItem>
            <IonItem lines="none" className="link-item">
              <IonRouterLink
                href="#"
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  history.replace("/login");
                }}
              >
                {i18n.t("button.backtosign")}
              </IonRouterLink>
            </IonItem>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
