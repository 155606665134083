import IAction from "../../actions";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { PolicyRecord, PolicyTableItem, VehicleRecord } from "../../../entities/policy";
import { API } from "aws-amplify";
import { CLEAR_STORE } from "../../login/actions/login";

export const LOAD_POLICY = "LOAD_POLICY";

export interface ILoadPolicyAction extends IAction {
    type: typeof LOAD_POLICY | typeof CLEAR_STORE
    policy?: PolicyRecord | null
    vehicles?: Record<string, VehicleRecord> | null
    fullPolicy?: PolicyTableItem
}

export type CompanyActionTypes = ILoadPolicyAction | IAction;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPolicy = (policyNumber: string): ThunkAction<
    void,
    unknown,
    unknown,
    AnyAction
> => async (dispatch: ThunkDispatch<unknown, unknown, ILoadPolicyAction>) => {
    try {
        console.log("Fetching Policy");

        const res = (await API.get(
            "AppEndpoint",
            `/policy/info/${policyNumber}`,
            {}
        )) as PolicyTableItem;

        console.log(res);

        if (!res || !res.PolicyVersions) {
            const action = {
                type: LOAD_POLICY,
                policy: {},
                vehicles: {} as Record<string, VehicleRecord>
            } as ILoadPolicyAction;
            dispatch(action);
            return;
        }

        const action = {
            type: LOAD_POLICY,
            policy: res.PolicyVersions && res.PolicyVersions.length > 0 ? res.PolicyVersions[res.PolicyVersions?.length - 1] : null,
            vehicles: {} as Record<string, VehicleRecord>,
            fullPolicy: res
        } as ILoadPolicyAction;

        if (res.Vehicles) {
            Object.keys(res.Vehicles).forEach(v => {
                if (res.Vehicles) {
                    const vehList = res.Vehicles[v].Versions;
                    if (action.vehicles && vehList && vehList.length > 0) {
                        action.vehicles[v] = vehList[vehList.length - 1];
                        if (action.vehicles[v].RequestType === "D") {
                            delete action.vehicles[v];
                        }
                    }
                }
            });
        }

        dispatch(action);
    } catch (err) {
        console.log(err);
    }
};