/* eslint-disable react/prop-types */
import * as React from "react";
import {
  Redirect,
  Route,
  RouteProps,
  useLocation,
  RouteComponentProps,
} from "react-router";

export interface ProtectedRouteProps extends RouteProps {
  isLoggedIn: boolean;
  authenticationPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const currentLocation = useLocation();
  if (!Component) {
    return null;
  }

  if (
    !rest.isLoggedIn &&
    currentLocation.pathname !== rest.authenticationPath
  ) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      // eslint-disable-next-line @typescript-eslint/ban-types
      render={(props: RouteComponentProps<{}>) => <Component {...props} />}
    />
  );
};

export default ProtectedRoute;
