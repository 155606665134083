import React from "react";
import { VehicleRecordWithGeotab } from "../../entities/policy";
import { useViewport } from "../../hooks/useViewport";
import { isMobileViewport } from "../../util/platform";
import Card from "../Card/Card";
import VehicleCardHeader from "./VehicleCardHeader";

import "twin.macro";
import AfterPolicy from "../AfterPolicy/AfterPolicy";

interface Props {
  item: VehicleRecordWithGeotab;
}
const PolicyVehicleCard: React.FC<Props> = ({ item }: Props) => {
  const viewport = useViewport();

  return (
    <Card
      leftColumnColor={isMobileViewport(viewport) ? "#0073cf" : undefined}
      hideHeader={true}
      ariaLabel="Insured Vehicle"
    >
      <VehicleCardHeader item={item} />
      <AfterPolicy vehicleRecordType={item.RequestType} />
      <div tw="w-full" tabIndex={0}>
        <p tw="p-4 mt-1 max-w-2xl text-sm text-headinggray">
          No Device Installed
        </p>
      </div>
    </Card>
  );
};

export default PolicyVehicleCard;
