import { IonButton, IonIcon } from "@ionic/react";
import React, { useState } from "react";
import "./index.css";
import { eye, eyeOff, helpCircleOutline } from "ionicons/icons";
import { Plugins } from "@capacitor/core";
import { useIsIOS } from "../../hooks/useIsIOS";
import tw from "twin.macro";
import { useIsMobile } from "../../hooks/useIsMobile";

const { Keyboard } = Plugins;

interface IProps {
  value?: string | number | readonly string[];
  name?: string;
  id?: string;
  ref?:
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  autoCapitalize?: boolean;
  type: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  ariaLabel?: string;
}

const CustomInput: React.FC<IProps> = ({
  value,
  name,
  id,
  ref,
  onChange,
  label,
  placeholder,
  disabled,
  className,
  inputClassName,
  labelClassName,
  autoCapitalize,
  type,
  onKeyPress,
  ariaLabel,
}: IProps) => {
  const [hidePassword, setHidePassword] = useState(true);
  const isIOS = useIsIOS();

  const toggleHidePassword = () => {
    setHidePassword((hide) => hide !== true);
  };

  const isMobile = useIsMobile();
  return (
    <div tw="flex flex-col" className={"custom-input " + (className || "")}>
      {label && (
        <label
          style={{ marginTop: "3px" }}
          htmlFor={id}
          className={
            "custom-input-label " +
            (isIOS ? "ios" : "") +
            (labelClassName || "")
          }
          onClick={() => {
            if (isMobile) Keyboard.hide();
          }}
        >
          {label}
        </label>
      )}
      <div
        tw="relative"
        id={"custom-input-box" + (ariaLabel || label || placeholder)}
      >
        <input
          aria-label={ariaLabel || placeholder}
          tw="left-0"
          type={!hidePassword ? "text" : type}
          value={value}
          id={id}
          className={
            !isMobile
              ? "desktop-input"
              : "custom-input-input " + (inputClassName || "")
          }
          name={name || "custom-input"}
          ref={ref || undefined}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          onKeyPress={onKeyPress}
          autoCapitalize={autoCapitalize == false ? "none" : "sentence"}
        />

        <div tw="absolute right-0 top-0" css={!isMobile ? tw`mt-3` : ""}>
          {type === "password" && (
            <IonButton
              id={"show-password" + (ariaLabel || label || placeholder)}
              className={
                !isMobile
                  ? "custom-input-showPassword-desktop"
                  : "custom-input-showPassword"
              }
              onClick={toggleHidePassword}
              fill="clear"
              aria-label="show password"
            >
              {isIOS || !isMobile ? (
                <IonIcon
                  //   className={isDesktop() ? "desktop-show-icon" : ""}
                  icon={hidePassword ? eyeOff : eye}
                  aria-label="Show Password Icon"
                />
              ) : (
                <IonIcon
                  tw="absolute right-0"
                  //   className="android-showPassword"
                  icon={helpCircleOutline}
                  aria-label="Show Password Icon"
                ></IonIcon>
              )}
            </IonButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomInput;
