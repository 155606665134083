import INewsFeedState from "../state/newsfeedState";
import {
    LOAD_NEWS_FEED,
    ADD_NEWSFEED_MESSAGE,
    NewsFeedActionTypes,
    SET_NEWSFEED_LOADING,
} from "../actions/newsfeed";
import { CLEAR_STORE } from "../../login/actions/login";

const initialState: INewsFeedState = {
    items: [],
    loading: false,
    loaded: false
};

export default function (
    state: INewsFeedState = initialState,
    action: NewsFeedActionTypes
): INewsFeedState {
    switch (action.type) {
        case SET_NEWSFEED_LOADING:
            return { ...state, loading: true };
        case LOAD_NEWS_FEED: {
            const newItems = state.items;
            action.feed?.forEach((action) => {
                if (newItems.findIndex((item) => item.id === action.id) === -1) {
                    newItems.push(action);
                }
            });
            return {
                ...state,
                items: newItems,
                loading: false,
                loaded: true
            };

            return state;
        }
        case ADD_NEWSFEED_MESSAGE:
            return { ...state, items: [...state.items, action.data] };
        case CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
}
