import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { ChartData, ChartOptions } from "chart.js";
import dayjs from "dayjs";
import { bgColors } from "../../report/data/insights";
import { FullAppState } from "../../store/state";
import {
  getEstimatedMileageForMonthForDevice,
  getMonthKey,
} from "../../util/mileage";
import { isMobileViewport } from "../../util/platform";
import { useViewport } from "../../hooks/useViewport";

import "./MileageBarGraph.css";

import tw from "twin.macro";

interface Props {
  selectedDevice: string;
}

let defaultOptions: ChartOptions;

type ChartDataType = {
  labels?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datasets?: any[];
};

const MileageBarGraph: React.FC<Props> = (props: Props) => {
  const viewport = useViewport();

  const historicMileageStore = useSelector(
    (state: FullAppState) => state.mileage.historicMileage
  );

  const policyVehicles = useSelector((state: FullAppState) => {
    return state.policy.vehicles;
  });

  const vehicles = useSelector((state: FullAppState) => {
    return state.vehicles.items;
  });

  const policy = useSelector((state: FullAppState) => state.policy.policy);

  const [loaded, setLoaded] = useState(false);

  const [chartData, setChartData] = useState<ChartDataType | null>(null);

  useEffect(() => {
    if (loaded || chartData) {
      return;
    }

    setLoaded(true);
    const deviceId = props.selectedDevice;
    defaultOptions = {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
        responsive: true,
        maintainAspectRatio: false,
      },
    } as ChartOptions;

    //Build the months we have data for
    const headerMap: Record<string, boolean> = {};
    for (const d in historicMileageStore) {
      for (const m of Object.keys(historicMileageStore[d].historicMileage)) {
        headerMap[m] = true;
      }
    }

    //Only care about dates after the policy effective date
    const earliestKey = getMonthKey(dayjs(policy?.PolicyEffectiveDate));
    const headers = Object.keys(headerMap)
      .sort((a, b) => {
        if (a < b) {
          return -1;
        } else if (a === b) {
          return 0;
        }

        return 1;
      })
      .filter((t) => {
        return t >= earliestKey;
      });

    // If we don't have 6 months then fill in the rest
    if (headers.length > 0 && headers.length < 6) {
      while (headers.length < 6) {
        headers.push(
          getMonthKey(dayjs(headers[headers.length - 1]).add(1, "month"))
        );
      }
    }

    const sums: number[] = [];

    //Convert the month keys into human readable
    const labels = headers.map((item: string) => {
      //We need the year incase it goes over to the next year or previous year
      return dayjs(item).format("MMM YYYY");
    });

    //Build the graph dataset object
    const data = {
      labels: labels,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      datasets: [] as any[],
    };

    let index = 0;
    const estimated: Record<string, number> = {};
    Object.keys(historicMileageStore).forEach((deviceIdKey: string) => {
      //Filter out device if it is not all vehicles or not selected
      if (deviceId && deviceIdKey != deviceId) {
        return;
      }

      //Get estimated for the estimated line
      const est = getEstimatedMileageForMonthForDevice(
        policyVehicles,
        vehicles,
        deviceIdKey
      );

      // No estmiate means no policy
      if (!est) {
        return;
      }

      // For each month get the sum of the appriopriate vehicles
      headers.forEach((monthKey) => {
        const mileage =
          historicMileageStore[deviceIdKey].historicMileage[monthKey]
            ?.mileage || 0;
        sums.push(Math.round(mileage));
        estimated[monthKey] = estimated[monthKey]
          ? estimated[monthKey] + est
          : est;
      });

      const device = vehicles.find((vehicle) => {
        return vehicle.id === deviceIdKey;
      });

      const dataSet = {
        type: "bar",
        label: `${device?.name} Driven (Miles)`,
        backgroundColor: bgColors[index],
        data: sums,
        maxBarThickness: 35,
        pointStyle: "rect",
      };

      data.datasets.push(dataSet);

      index++;
      if (index > bgColors.length) {
        index = 0;
      }
    });

    const estimatedList = Object.keys(estimated).map((t) => {
      return estimated[t];
    });

    data.datasets.push({
      pointStyle: "rectRounded",
      type: "line",
      steppedLine: true,
      label: "Estimated Mileage",
      borderWidth: 2,
      fill: false,
      data: estimatedList,
      borderColor: "#E01933",
    });

    setChartData(data);
  }, [historicMileageStore]);

  const height = !isMobileViewport(viewport)
    ? // ? window.innerWidth > 1700
      //   ? 140
      //   : 180
      232
    : 250;

  if (
    !chartData?.datasets ||
    (chartData?.datasets.length < 2 && chartData == "200")
  ) {
    return (
      <div
        id="container"
        tw="flex-none"
        style={
          !isMobileViewport(viewport)
            ? { height: "342px" }
            : { height: "342px", minWidth: "300px" }
        }
      >
        <div id="navi" tw="px-4 pt-2 h-full flex-none">
          <Bar
            data={{ datasets: [], labels: [] }}
            height={height}
            plugins={[ChartAnnotation]}
            options={defaultOptions}
          />
        </div>
        <div id="infoi">
          <div tw="h-full flex justify-center items-center">
            <div>No Data</div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div css={isMobileViewport(viewport) ? tw`flex-shrink-0` : ""} tw="m-4">
        <Bar
          data={chartData as ChartData}
          plugins={[ChartAnnotation]}
          height={height - 13}
          options={defaultOptions}
          redraw
          key={Math.random()}
        />

        {/* <div tw="h-full flex justify-center items-center"> */}
        {/* </div> */}

        {!chartData?.datasets ||
          (chartData?.datasets.length < 2 && (
            <div tw="w-full text-center">No data</div>
          ))}
      </div>
    );
  }
};

export default MileageBarGraph;
