import { IonSelect, IonSelectOption } from "@ionic/react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Device } from "../../geotab/helpers";
import { FullAppState } from "../../store/state";
import "./VehicleSelector.css";

import tw from "twin.macro";
import { isMobileViewport } from "../../util/platform";
import { useViewport } from "../../hooks/useViewport";

interface SelectorProps {
  setDevice: (device: Device | null) => void;
  defaultDevice?: string;
  title?: string;
  allTitle?: string;
  style?: React.CSSProperties;
  hideAll?: boolean;
}

const VehicleSelector: React.FC<SelectorProps> = (props: SelectorProps) => {
  const vehicles = useSelector(
    (state: FullAppState) => state.vehicles.items
  ) as Device[];
  const viewport = useViewport();

  const renderAccessibility = () => {
    // //Accessibility Adding Title for Buttons manually
    // setTimeout(() => {
    //   const selectors = document.getElementsByClassName(
    //     "select-interface-option"
    //   ) as HTMLCollectionOf<Element>;
    //   for (let i = 0; i < selectors.length; i++) {
    //     const text = selectors
    //       .item(i)
    //       ?.lastElementChild?.lastElementChild?.innerHTML.toString();
    //     if (text !== undefined) {
    //       selectors.item(i)?.setAttribute("title", text);
    //     }
    //   }
    // }, 200);
  };

  const convertVehicle = (id: string) => {
    const x = vehicles.find((d: Device) => d.id === id);
    if (x !== undefined && x !== null) {
      props.setDevice(x);
    } else {
      props.setDevice(null);
    }
  };

  let key = 0;

  const selector = useRef<HTMLIonSelectElement>(null);
  const selectedDefault = vehicles.find((v) => v.id == props.defaultDevice);
  return (
    <div
      css={
        !isMobileViewport(viewport)
          ? tw`flex flex-col mx-8 rounded shadow justify-items-center`
          : tw`flex flex-col mx-4 rounded shadow justify-items-center`
      }
      style={{ height: "88px", backgroundColor: "white" }}
      aria-label={`Vehicle Selector ${
        (selectedDefault && selectedDefault.name) || "All Vehicles"
      } Selected`}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key == "Enter") {
          selector.current?.open(e.nativeEvent);
        }
      }}
    >
      <div className="screen-reader-only">
        Showing data for
        {(selectedDefault && selectedDefault.name) || "All Vehicles"}
      </div>
      <div tw="m-4 flex flex-col flex-grow rounded border" aria-hidden={true}>
        <IonSelect
          aria-disabled={true}
          ref={selector || undefined}
          tw="flex-grow p-0 mx-4"
          value={props.defaultDevice || ""}
          okText="Okay"
          cancelText="Dismiss"
          multiple={false}
          tabIndex={-1}
          onIonChange={(e) => {
            convertVehicle(e.detail.value);
          }}
          onClick={() => {
            renderAccessibility();
          }}
        >
          {!props.hideAll && (
            <IonSelectOption value="">
              {props.allTitle || "ALL VEHICLES"}
            </IonSelectOption>
          )}
          {vehicles.map((item) => {
            return (
              <IonSelectOption key={key++} value={item.id}>
                {item.name}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </div>
    </div>
  );
};

export default VehicleSelector;
