import React from "react";
import { Trip } from "../../geotab/helpers";
import { DateUtil } from "../../util/date";

import "twin.macro";

interface Props {
  trip?: Trip | null;
}

const TripInfo: React.FC<Props> = (props: Props) => {
  return (
    <section id="map-info" tw="mt-4">
      <div tw="ml-4">
        <div
          //   css={isMobile ? tw`mt-8 ml-4` : tw`mt-8`}
          tw="uppercase text-farmersblue"
          className="h2"
        >
          Trip Information
        </div>
        <div tw="flex flex-col text-headinggray mb-4 text-sm">
          <div
            tw="grid mt-2"
            style={{
              gridTemplateColumns: "minmax(0, 100px) minmax(0, 200px)",
            }}
          >
            <span>Trip Started:</span>
            <span tw="ml-2">
              {DateUtil.WithSecondsFormat(
                DateUtil.utc(props.trip?.startTime).local()
              )}
            </span>
            <span>Trip Ended:</span>
            <span tw="ml-2">
              {DateUtil.WithSecondsFormat(
                DateUtil.utc(props.trip?.endTime).local()
              )}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TripInfo;
