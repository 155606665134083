import {
  IonTitle,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/react";
import React from "react";
import "./PageHeader.css";
import ActionList from "./actionList";
import { useIsMobile } from "../../hooks/useIsMobile";
import 'twin.macro';

interface Props {
  title: string;
  subtitle?: string;
  leftActions?: IActionItem[];
  rightActions?: IActionItem[];
  showBack?: boolean;
}

export interface IActionItem {
  name: string;
  icon?: string;
  onClicked: () => void;
}

const Header: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();

  if (!isMobile) {
    return <div></div>;
  }

  return (
    <IonHeader>
      <IonToolbar className="toolbar-bg" role="navigation">
        <IonButtons slot="start" className="p-0">
          <IonMenuButton
            menu="first-menu"
            className="p-0 ion-hide-md-down menu-button"
          ></IonMenuButton>
          {props.showBack && (
            <IonBackButton
              className="header-icon"
              defaultHref="/dashboard"
              text={""}
            />
          )}
        </IonButtons>
        <IonTitle className="header-title">
          <div className="header-div" role="heading" aria-level={1}>
            FairMile<IonText className="header-sm">®</IonText>
          </div>
          {props.subtitle && (
            <span className="header-subtitle" tw="pl-2" role="heading" aria-level={2}>
              {" "}
              | {props.subtitle}
            </span>
          )}
        </IonTitle>
      </IonToolbar>
      {(props.rightActions || props.leftActions) && (
        <IonGrid style={{ background: "white" }} className="p-0">
          <IonRow>
            <IonCol size="6" className="p-0">
              {props.leftActions && (
                <ActionList items={props.leftActions} iconPosition="left" />
              )}
            </IonCol>
            <IonCol size="6" dir="rtl">
              {props.rightActions && (
                <ActionList items={props.rightActions} iconPosition="left" />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      <IonImg
        src="assets/loginbranding.svg"
        className="header-branding"
        alt="Logo branding"
      />
    </IonHeader>
  );
};

export default Header;
