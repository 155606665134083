import { FaultData } from "../../../geotab/helpers";
import { DateUtil } from "../../../util/date";
import { CLEAR_STORE } from "../../login/actions/login";
import {
    LOAD_FAULT_DATA,
    LoadFaultDataType,
    LOAD_FAULT_DATA_SINGLE,
    LoadSingleFaultData,
    SET_WARNING_LIGHT_LOADING_TIME,
    SetWarningLightLoadingTime,
} from "../actions/faultData";
import IFaultDataState from "../state/faultDataState";

const initalState: IFaultDataState = {
    items: [],
    lastLoadedWarningLight: DateUtil.utc("1980-01-01")
};

export default function (
    defaultState = initalState,
    action: LoadFaultDataType | LoadSingleFaultData | SetWarningLightLoadingTime
): IFaultDataState {
    switch (action.type) {
        case SET_WARNING_LIGHT_LOADING_TIME: {
            return { ...initalState, lastLoadedWarningLight: DateUtil.utc() };
        }
        case LOAD_FAULT_DATA: {
            if (defaultState.items && defaultState.items.length > 0) {
                const map: Record<string, FaultData> = {};
                for (const f of action.faultData) {
                    map[f.id] = f;
                }

                for (const i in defaultState.items) {
                    const f = defaultState.items[i];
                    if (map[f.id]) {
                        defaultState.items[i] = f;
                    }
                }
            }

            return { ...defaultState, items: action.faultData };
        }
        case LOAD_FAULT_DATA_SINGLE: {
            const faultData = defaultState.items.filter(
                (data) => data.id !== action.faultData.id
            );
            faultData.push(action.faultData);
            return { ...defaultState, items: faultData };
        }
        case CLEAR_STORE: {
            return initalState;
        }
        default: {
            return defaultState;
        }
    }
}
